// React
import React from 'react';

// Components
import TournamentStatisticsCardsComponent from 'app/leagues/components/tournament-statistics-cards/TournamentStatisticsCardsComponent';
import TournamentStatisticsPlayersComponent from 'app/leagues/components/tournament-statistics-players/TournamentStatisticsPlayersComponent';
import TournamentStatisticsTeamsComponent from 'app/leagues/components/tournament-statistics-teams/TournamentStatisticsTeamsComponent';

// Styles
import './TournamentStatisticsComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';
import TournamentStatisticsSummaryComponent from '../tournament-statistics-summary/TournamentStatisticsSummaryComponent';

function TournamentStatisticsComponent(props){ 

    return(        
        <div className='tournament-statistics__container'>
            {/** Scorers */}
            <TournamentStatisticsPlayersComponent
                title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.scorersLabel') }
                column_title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.columns.labelGoals') }
                loading_players={ props.loading_tournament_scorers }
                tournament_players={ props.tournament_scorers }
                next_tournament_players={ props.next_tournament_scorers }
                empty_players_lbl={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.noScorersText') }
                stat_type={'scorer'}
                handleOnViewMoreStatistics={ props.handleOnViewMoreStatistics }
            />

            {/** Yellow cards */}
            <TournamentStatisticsCardsComponent
                title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardYellow') }
                loading_cards={ props.loading_tournament_yellow_cards }
                tournament_cards={ props.tournament_yellow_cards }
                next_tournament_cards={ props.next_tournament_yellow_cards }
                empty_cards_lbl={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.noYellowCardsText') }
                card_color={'yellow'}
                handleOnViewMoreStatistics={ props.handleOnViewMoreStatistics }
            />

            {/** Red cards */}
            <TournamentStatisticsCardsComponent
                title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardRed') }
                loading_cards={ props.loading_tournament_red_cards }
                tournament_cards={ props.tournament_red_cards }
                next_tournament_cards={ props.next_tournament_red_cards }
                empty_cards_lbl={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.noRedCardsText') }
                card_color={'red'}
                handleOnViewMoreStatistics={ props.handleOnViewMoreStatistics }
            />

            {/** Blue cards */}
            <TournamentStatisticsCardsComponent
                title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.booksTable.labelCardBlue') }
                loading_cards={ props.loading_tournament_blue_cards }
                tournament_cards={ props.tournament_blue_cards }
                next_tournament_cards={ props.next_tournament_blue_cards }
                empty_cards_lbl={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.scorersTable.noBlueCardsText') }
                card_color={'blue'}
                handleOnViewMoreStatistics={ props.handleOnViewMoreStatistics }
            />

            {/** Assists */}
            <TournamentStatisticsPlayersComponent
                title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.assistsTable.title') }
                //column_title={  }
                loading_players={ props.loading_tournament_assists }
                tournament_players={ props.tournament_assists }
                next_tournament_players={ props.next_tournament_assists }
                empty_players_lbl={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.assistsTable.noAssistsText') }
                stat_type={'assist'}
                handleOnViewMoreStatistics={ props.handleOnViewMoreStatistics }
            />

            {/** Valla menos vencida */}
            <TournamentStatisticsTeamsComponent
                title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.leastDefeatedFenceTable.title') }
                column_title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.leastDefeatedFenceTable.columnTitle') }
                loading_teams={ props.loading_tournament_least_defeated_fence }
                tournament_teams={ props.tournament_least_defeated_fence }
                next_tournament_teams={ props.next_tournament_least_defeated_fence }
                empty_teams_lbl={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.leastDefeatedFenceTable.noLDFText') }
                stat_type={'ldf'}
                handleOnViewMoreStatistics={ props.handleOnViewMoreStatistics }
            />

            {/** Equipos con mas goles */}
            <TournamentStatisticsTeamsComponent
                title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.teamsMostGoalsTable.title') }
                column_title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.teamsMostGoalsTable.columnTitle') }
                loading_teams={ props.loading_tournament_teams_most_goals }
                tournament_teams={ props.tournament_teams_most_goals }
                next_tournament_teams={ props.next_tournament_teams_most_goals }
                empty_teams_lbl={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.teamsMostGoalsTable.noTMGText') }
                stat_type={'tmg'}
                handleOnViewMoreStatistics={ props.handleOnViewMoreStatistics }
            />

            {/** Resumen */}
            <TournamentStatisticsSummaryComponent
                title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.summaryTable.title') }
                column_title={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.summaryTable.columnTitle') }
                loading_summary={ props.loading_tournament_summary }
                tournament_summary={ props.tournament_summary }
                empty_summary_lbl={ props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.summaryTable.noTMGText') }
            />
                 
        </div>
    )
}

export default withTranslation('league')(TournamentStatisticsComponent);