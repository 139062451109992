// React
import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import './TournamentStatisticsPlayersComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components
import TournamentPlayerInfoComponent from 'app/leagues/components/tournament-player-info/TournamentPlayerInfoComponent';
import TournamentStatisticsSkeletonComponent from 'app/leagues/components/tournament-statistics-skeleton/TournamentStatisticsSkeletonComponent';

//isMobile
import { isMobile } from 'react-device-detect';


function TournamentStatisticsPlayersComponent(props){ 
    return(
        <div className='tournament-statistics-players__container'>
                <div className='tournament-statistics-players__title'>
                    <div className='baloa-subtitle-2'>{ props.title }</div>
                    {props.column_title &&
                        <div className='baloa-subtitle-2'>{ props.column_title }</div> 
                    }
                </div>
                {props.loading_players && 
                    [...Array(Number(5))].map((e, i) => 
                        <TournamentStatisticsSkeletonComponent/>
                    )
                }
                { (!props.loading_players && props.tournament_players?.length > 0)
                    ?
                        props.tournament_players?.slice(0, 5).map( (sccorer, scoIndex) => 
                            <div className='tournament-statistics-players__row' key={scoIndex}>
                                <div className='tournament-statistics-players__row__counter baloa-normal-medium'>{scoIndex + 1}</div>
                                <div><Link to={`/profile/${sccorer.name}/`}><TournamentPlayerInfoComponent full_name={`${sccorer.first_name} ${sccorer.last_name}`} photo={sccorer.photo} team_name={sccorer.team} /></Link></div>
                                <div className='tournament-statistics-players__row__goals baloa-normal-medium'>{props.stat_type === 'scorer'? sccorer.goals : sccorer.assists}</div>
                            </div>
                        )                        
                    :
                        <div className='tournament-statistics-players__no-data'>
                            <div className='baloa-normal'>{ props.empty_players_lbl }</div>
                        </div>
                }
                {(props.next_tournament_players || props.tournament_players?.length > 5) &&
                    <div className='tournament-statistics-players__next-button baloa-names' onClick={()=>{ props.handleOnViewMoreStatistics( props.stat_type ) } }>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.viewMore')}</div>
                }
            </div>
    )
}
    
export default withTranslation('league')(TournamentStatisticsPlayersComponent);
        