// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-no-pro-add-external-stats .modal__container {
    padding: 0;
    padding-bottom: 0;
    width: 440px;
}

.profile-no-pro-add-external-stats .modal__container__close {
    top: 10px;
    right: 24px;
}

.profile-no-pro-add-external-stats__container {
    text-align: center;
    padding: 24px;
}

.profile-no-pro-add-external-stats__container .hubicon-award_star{
    font-size: 54px;
}

.profile-no-pro-add-external-stats__container .baloa-hyperlink {
    font-size: 26px;
    margin-top: 24px;
}

.profile-no-pro-add-external-stats__container .baloa-names {
    font-weight: var(--font-regular);
    margin-top: 9px;
}

.profile-no-pro-add-external-stats__container .button {
    width: 100%;
    margin-top: 24px;
}

@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */

    .profile-no-pro-add-external-stats__container .hubicon-award_star{
        font-size: 40px;
    }

    .profile-no-pro-add-external-stats__container .baloa-hyperlink {
        font-size: 18px;
        margin-top: 16px;
    }

    .profile-no-pro-add-external-stats__container .baloa-names {
        margin-top: 12px;
    }

    .profile-no-pro-add-external-stats__container .button {
        margin-top: 16px;
    }

}`, "",{"version":3,"sources":["webpack://./src/app/profiles/components/profile-no-pro-add-external-stats-modal/ProfileNoProAddExternalStatsModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA,yCAAyC,4BAA4B;;IAEjE;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;;AAEJ","sourcesContent":[".profile-no-pro-add-external-stats .modal__container {\n    padding: 0;\n    padding-bottom: 0;\n    width: 440px;\n}\n\n.profile-no-pro-add-external-stats .modal__container__close {\n    top: 10px;\n    right: 24px;\n}\n\n.profile-no-pro-add-external-stats__container {\n    text-align: center;\n    padding: 24px;\n}\n\n.profile-no-pro-add-external-stats__container .hubicon-award_star{\n    font-size: 54px;\n}\n\n.profile-no-pro-add-external-stats__container .baloa-hyperlink {\n    font-size: 26px;\n    margin-top: 24px;\n}\n\n.profile-no-pro-add-external-stats__container .baloa-names {\n    font-weight: var(--font-regular);\n    margin-top: 9px;\n}\n\n.profile-no-pro-add-external-stats__container .button {\n    width: 100%;\n    margin-top: 24px;\n}\n\n@media screen and ( max-width: 430px ) { /* Cambio de 428px a 430px */\n\n    .profile-no-pro-add-external-stats__container .hubicon-award_star{\n        font-size: 40px;\n    }\n\n    .profile-no-pro-add-external-stats__container .baloa-hyperlink {\n        font-size: 18px;\n        margin-top: 16px;\n    }\n\n    .profile-no-pro-add-external-stats__container .baloa-names {\n        margin-top: 12px;\n    }\n\n    .profile-no-pro-add-external-stats__container .button {\n        margin-top: 16px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
