// React
import React, { useState } from 'react';

// Styles
import './ProfileNoProAddExternalStatsModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';


function ProfileNoProAddExternalStatsModalComponent(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className={'profile-no-pro-add-external-stats'}
        >
            <div className='profile-no-pro-add-external-stats__container'>
                <span className='hubicon-award_star'/>
                <div className='baloa-hyperlink'>{props.t('externalStats.noProAddExternalStatsModal.labelAddExternalStats')}</div>
                <div className='baloa-names'>{props.t('externalStats.noProAddExternalStatsModal.labelAddExternalStatsMessage')}</div>
                <PrimaryButtonComponent
                    input_type={'button'}
                    onClick={()=>{ props.handleOnBaloaProSubscription()}}
                >
                    <span>{props.t('baloapro:baloaProSubscriptionBtnLbl')}</span>
                </PrimaryButtonComponent>
            </div>
        </ModalComponent>
    )
}

export default withTranslation(['profile','baloapro'])(ProfileNoProAddExternalStatsModalComponent);