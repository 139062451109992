// React
import React, { useState } from 'react';

// Styles
import './ProfileVerifiedExternalStatsInsigniaModalComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';


function ProfileVerifiedExternalStatsInsigniaModalComponent(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className={'verified-external-stats-insignia'}
        >
            <div className='verified-external-stats-insignia__container'>
                <span className='hubicon-verified'/>
                <div className='baloa-names'>{props.t('externalStats.verifiedExternalStatsModal.message')}</div>
            </div>
        </ModalComponent>
    )
}

export default withTranslation(['profile','baloapro'])(ProfileVerifiedExternalStatsInsigniaModalComponent);