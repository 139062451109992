// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Redux
import { compose } from 'redux';

//Actions
import {
    loadStripePrices,
    createStripePaymentIntent,
    searchCountries,
    createStripeSubscription,
    loadStripeUserAccount,
    createStripeSetupIntent,
    setPredefinedSubscriptionPaymentMethod,
    cancelBaloaProSubscription,
} from 'app/baloa-pro/actions/BaloaProActions';

//Actions Creators
import {
    changeBaloaProStripePaymentModalStateAction,
    changeBaloaProStripeResumeModalStateAction,
    resetStripeSubscriptionAction,
    changeBaloaProPaymentMethodsModalStateAction,
    changeBaloaProCancelSubscriptionModalStateAction,
    changeBaloaProAddPaymentMethodModalStateAction,
} from 'app/baloa-pro/actions/BaloaProActionsCreators';

// Styles
import './BaloaProView.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import BaloaProHeaderComponent from '../components/baloa-pro-header/BaloaProHeaderComponent';
import BaloaProAdvantagesComponent from '../components/baloa-pro-advantages/BaloaProAdvantagesComponent';
import BaloaProFunctionalitiesComponent from '../components/baloa-pro-functionalities/BaloaProFunctionalitiesComponent';
import BaloaProSubscriptionComponent from '../components/baloa-pro-subscription/BaloaProSubscriptionComponent';
import BaloaProManageSubscriptionWidgetComponent from '../components/baloa-pro-manage-subscription-widget/BaloaProManageSubscriptionWidgetComponent';

//Modals
import BaloaProStripePaymentModalComponent from '../components/baloa-pro-stripe-payment-modal/BaloaProStripePaymentModalComponent';
import BaloaProStripePaymentResumeModalComponent from '../components/baloa-pro-stripe-payment-resume-modal/BaloaProStripePaymentResumeModalComponent';
import BaloaProCancelSubscriptionModalComponent from '../components/baloa-pro-cancel-subscription-modal/BaloaProCancelSubscriptionModalComponent';
import BaloaProPaymentMethodsModalComponent from '../components/baloa-pro-payment-methods-modal/BaloaProPaymentMethodsModalComponent';
import BaloaProAddPaymentMethodModalComponent from '../components/baloa-pro-add-payment-method-modal/BaloaProAddPaymentMethodModalComponent';

class BaloaProView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscription_type: null,
            subscription_price: null,
            subscription_currency: null,
            subscription_name: null,
            subscription_email: null,
            subscription_country_code: null,
            payment_intent: null,
            redirect_match_id: null,
        }
    }

    componentDidMount() {
        if(this.props.state.profile.loggedin?.user?.username){
            this.props.loadStripePrices( this.props.state.profile.loggedin?.user?.username );
            if(this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE'){
                this.props.loadStripeUserAccount( this.props.state.profile.loggedin?.user?.username );
            }
        }

        //Load from BaloPlay
        let matchsearch = this.props.location.search.split('?match_id=');
        if(matchsearch.length > 1 && this.props.state.profile.loggedin?.user?.subscription_plan === 'FREE' ){
            this.props.history.replace({
                search: ''
            });
            this.setState({redirect_match_id: matchsearch[1]})
        }
        //Configura medio de pago predeterminado si no esta definido
        if(this.props.state.baloapro.stripe_user_account && this.props.state.baloapro.stripe_user_account?.payment_methods?.length > 0 && (!this.props.state.baloapro.stripe_user_account?.payment_methods?.find(method => method.id === this.props.state.baloapro.stripe_user_account?.default_payment_method))){
            this.handleChangePrincipalMethod(this.props.state.baloapro.stripe_user_account?.payment_methods[0].id);
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.state.profile.loggedin?.user?.username && (this.props.state.profile.loggedin?.user?.username !== prevProps.state.profile.loggedin?.user?.username)){
            this.props.loadStripePrices( this.props.state.profile.loggedin?.user?.username );
            if(this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE'){
                this.props.loadStripeUserAccount( this.props.state.profile.loggedin?.user?.username );
            }
        }
        if(this.props.state.baloapro.stripe_user_account && this.props.state.baloapro.stripe_user_account?.payment_methods?.length > 0 && (!this.props.state.baloapro.stripe_user_account?.payment_methods?.find(method => method.id === this.props.state.baloapro.stripe_user_account?.default_payment_method))){
            this.handleChangePrincipalMethod(this.props.state.baloapro.stripe_user_account?.payment_methods[0].id);
        }
    }

    handleOnPayment(subscription_type, subscription_price, subscription_currency ){
        this.setState({subscription_type: subscription_type, subscription_price: subscription_price, subscription_currency: subscription_currency})
        //this.props.createStripePaymentIntent( this.props.state.profile.loggedin?.user?.username, payment_type );
        this.props.changeBaloaProStripePaymentModalStateAction(true);
    }

    selectNewSubscriberPayment(subscription_type, subscription_price, subscription_currency ){
        this.setState({subscription_type: subscription_type, subscription_price: subscription_price, subscription_currency: subscription_currency})
    }

    handleOnSubscription(){
        this.props.changeBaloaProStripePaymentModalStateAction(true);
    }

    handleCreateStripeSubscription( name, email, country_code){
        this.setState({subscription_name: name, subscription_email: email, subscription_country_code: country_code })
        this.props.createStripeSubscription( this.props.state.profile.loggedin?.user?.username, this.state.subscription_type, name, email, country_code );
    }

    handleOnPaymentResumeModal(payment_intent){
        this.setState({payment_intent: payment_intent});
        this.props.changeBaloaProStripeResumeModalStateAction(true);
    }

    handleClosePaymentModal(){
        this.setState({subscription_type: null, subscription_price: null, subscription_currency: null, subscription_name: null, subscription_email: null, subscription_country_code: null });
        this.props.resetStripeSubscriptionAction();
        this.props.changeBaloaProStripePaymentModalStateAction(false);
    }

    handleCloseResumeModal(){
        this.handleClosePaymentModal();
        this.setState({payment_intent: null});
        this.props.changeBaloaProStripeResumeModalStateAction(false);
        if(this.state.redirect_match_id){
            window.location.replace(`/BaloaPlay/match/${this.state.redirect_match_id}/?pro=1`);
        }else{
            window.location.replace('/?pro=1');
        }
    }

    handleOnEditSuscription(){
        this.props.changeBaloaProPaymentMethodsModalStateAction(true);
    }

    handleOnCancelSuscription(){
        this.props.changeBaloaProCancelSubscriptionModalStateAction(true);
    }

    async handleAddPaymentMethod(){
        await this.props.createStripeSetupIntent(this.props.state.profile.loggedin?.user?.username);
        this.props.changeBaloaProAddPaymentMethodModalStateAction(true);
    }

    handleCloseAddPaymentMethod(){
        this.props.loadStripeUserAccount( this.props.state.profile.loggedin?.user?.username );
        this.props.changeBaloaProAddPaymentMethodModalStateAction(false);
    }

    async handleChangePrincipalMethod(payment_id){
        await this.props.setPredefinedSubscriptionPaymentMethod(this.props.state.profile.loggedin?.user?.username, payment_id);
        this.props.changeBaloaProPaymentMethodsModalStateAction(false);
    }

    cancelSubscription(){
        this.props.cancelBaloaProSubscription(this.props.state.profile.loggedin?.user?.username);
        this.props.changeBaloaProCancelSubscriptionModalStateAction(false);
    }

    handleCloseSubcribeNewUser(){
        this.handleClosePaymentModal();
        if(this.state.redirect_match_id){
            window.location.replace(`/BaloaPlay/match/${this.state.redirect_match_id}/?pro=1`);
        }else{
            window.location.replace('/?pro=1');
        }
    }

    render() {
        return (
            <div className='baloa-pro__container'>
                <BaloaProHeaderComponent
                    handleOnClickYearly={( price, currency )=>{ this.handleOnPayment('year', price, currency) }}
                    handleOnClickMonthly={( price, currency )=>{ this.handleOnPayment('month', price, currency) }}
                    is_baloa_pro={ this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' }
                    prices={ this.props.state.baloapro.stripe_prices }
                    is_new_baloa_pro_user={this.props.state.user?.me?.is_new_subscriber}
                    handleOnClickSubscription={()=>{ this.handleOnSubscription() }}
                />                
                <div className='baloa-pro__content'>
                    {this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' &&
                        <BaloaProManageSubscriptionWidgetComponent
                            handleOnEditSuscription = {()=>{ this.handleOnEditSuscription() }}
                            handleOnCancelSuscription = {()=>{ this.handleOnCancelSuscription() }}
                            payment={this.props.state.baloapro.stripe_user_account?.payment}
                            subscription_type={this.props.state.baloapro.stripe_user_account?.subscription_type}
                            selected_payment_method={this.props.state.baloapro.stripe_user_account?.payment_methods?.find(method => method.id === this.props.state.baloapro.stripe_user_account?.default_payment_method)}
                            renewal_date={this.props.state.baloapro.stripe_user_account?.renewal_date}
                            cancel_at_period_end={ this.props.state.baloapro.stripe_user_account?.cancel_at_period_end }
                            subscription_plan_channel={ this.props.state.baloapro.stripe_user_account?.user?.subscription_plan_channel}
                            customer={this.props.state.baloapro.stripe_user_account?.customer}
                            expiration_date={this.props.state.baloapro.stripe_user_account?.expiration_date}
                            payment_status={this.props.state.baloapro.stripe_user_account?.payment?.payment_status}
                        />
                    }
                    <BaloaProAdvantagesComponent/>
                    <BaloaProFunctionalitiesComponent/>
                    {this.props.state.profile.loggedin?.user?.subscription_plan === 'FREE' &&
                        <BaloaProSubscriptionComponent
                            handleOnClickYearly={( price )=>{ this.handleOnPayment('year', price) }}
                            handleOnClickMonthly={( price )=>{ this.handleOnPayment('month', price) }}
                            prices={ this.props.state.baloapro.stripe_prices }
                            is_new_baloa_pro_user={this.props.state.user?.me?.is_new_subscriber}
                            handleOnClickSubscription={()=>{ this.handleOnSubscription() }}
                        />  
                    }
                                      
                </div>

                {/** Modals */}
                {/** Payment modal */}
                <BaloaProStripePaymentModalComponent
                    isOpen={ this.props.state.baloapro.modals?.stripe_payment_modal }
                    onClose={ () => { this.handleClosePaymentModal() } }
                    user_name={ `${this.props.state.profile.loggedin?.user?.first_name} ${this.props.state.profile.loggedin?.user?.last_name}` }
                    subscription={this.props.state.baloapro.stripe_subscription}
                    countries =  { this.props.state.baloapro.countries_search}
                    handleSearchCountry={(country_search) => { this.props.searchCountries( country_search ) }}
                    createStripeSubscription={ (name, email, country_code)=>{ this.handleCreateStripeSubscription( name, email, country_code ) }}
                    subscription_type={this.state.subscription_type}
                    subscription_price={this.state.subscription_price}
                    subscription_currency={this.state.subscription_currency}
                    handleOnPaymentResumeModal={(payment_intent)=>{ this.handleOnPaymentResumeModal(payment_intent)}}

                    is_new_baloa_pro_user={this.props.state.user?.me?.is_new_subscriber}
                    prices={ this.props.state.baloapro.stripe_prices }
                    selectNewSubscriberPayment={ ( subscription_type, subscription_price, subscription_currency )=>{ this.selectNewSubscriberPayment( subscription_type, subscription_price, subscription_currency ) }}
                    createPaymentSetUpIntent={ async ( customer_id, stripe_account_id )=>{ await this.props.createStripeSetupIntent(this.props.state.profile.loggedin?.user?.username, customer_id, stripe_account_id); }}
                    stripe_setup_intent={ this.props.state.baloapro.stripe_setup_intent }
                    onCloseNewUser={ ()=>{ this.handleCloseSubcribeNewUser()}}
                />

                {/** Payment resume modal */}
                <BaloaProStripePaymentResumeModalComponent
                    isOpen={ this.props.state.baloapro.modals?.stripe_resume_modal }
                    onClose={ () => { this.handleCloseResumeModal() } }
                    subscription_state={this.state}
                />

                {/** Payment methods modal */}
                <BaloaProPaymentMethodsModalComponent
                    isOpen={ this.props.state.baloapro.modals?.payment_methods_modal }
                    onClose={ () => { this.props.changeBaloaProPaymentMethodsModalStateAction(false) } }
                    payment_methods={ this.props.state.baloapro.stripe_user_account?.payment_methods }
                    default_payment_method={ this.props.state.baloapro.stripe_user_account?.default_payment_method }
                    handleOnAddPaymentMethod={ () => { this.handleAddPaymentMethod()} }
                    handleOnChangePrincipalMethod={ (payment_id)=>{ this.handleChangePrincipalMethod(payment_id) } }
                />

                {/** Add payment method modal  */}
                <BaloaProAddPaymentMethodModalComponent
                    isOpen={ this.props.state.baloapro.modals?.add_payment_method_modal }
                    onClose={ () => { this.handleCloseAddPaymentMethod() } }
                    countries =  { this.props.state.baloapro.countries_search}
                    handleSearchCountry={(country_search) => { this.props.searchCountries( country_search ) }}
                    stripe_setup_intent={ this.props.state.baloapro.stripe_setup_intent }
                />
                {/** Cancel subscription modal */}
                <BaloaProCancelSubscriptionModalComponent
                    isOpen={ this.props.state.baloapro.modals?.cancel_subscription_modal }
                    onClose={ () => { this.props.changeBaloaProCancelSubscriptionModalStateAction(false) } }
                    renewal_date={this.props.state.baloapro.stripe_user_account?.renewal_date}
                    handleCancel={()=>{ this.cancelSubscription() }}
                />
                
            </div>
        )
    }
  
}
  
const mapStateToProps = state => {
    return {
      state
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        changeBaloaProStripePaymentModalStateAction: (new_modal_state) =>{
            dispatch (changeBaloaProStripePaymentModalStateAction(new_modal_state));
        },
        loadStripePrices: ( profile ) =>{
            dispatch (loadStripePrices( profile ));
        },
        createStripePaymentIntent: ( profile, interval ) =>{
            dispatch (createStripePaymentIntent( profile, interval ));
        },
        searchCountries: async (country) => {
            await dispatch(searchCountries(country));
        },
        createStripeSubscription: async ( subscription_type, name, email, country_code ) => {
            await dispatch(createStripeSubscription( subscription_type, name, email, country_code ));
        },
        changeBaloaProStripeResumeModalStateAction: (new_modal_state) =>{
            dispatch (changeBaloaProStripeResumeModalStateAction(new_modal_state));
        },
        resetStripeSubscriptionAction: () => {
            dispatch ( resetStripeSubscriptionAction({}));
        },
        changeBaloaProPaymentMethodsModalStateAction: (new_modal_state) =>{
            dispatch (changeBaloaProPaymentMethodsModalStateAction(new_modal_state));
        },
        changeBaloaProCancelSubscriptionModalStateAction: (new_modal_state) =>{
            dispatch (changeBaloaProCancelSubscriptionModalStateAction(new_modal_state));
        },
        changeBaloaProAddPaymentMethodModalStateAction: (new_modal_state) =>{
            dispatch (changeBaloaProAddPaymentMethodModalStateAction(new_modal_state));
        },
        loadStripeUserAccount: ( profile ) =>{
            dispatch (loadStripeUserAccount( profile ));
        },
        createStripeSetupIntent: async( profile, customer_id, stripe_account_id ) =>{
            await dispatch (createStripeSetupIntent( profile, customer_id, stripe_account_id ));
        },
        setPredefinedSubscriptionPaymentMethod: async( profile, payment_id ) =>{
            await dispatch (setPredefinedSubscriptionPaymentMethod( profile, payment_id ));
        },
        cancelBaloaProSubscription: ( profile ) =>{
            dispatch (cancelBaloaProSubscription( profile ));
        },
    }
}

export default compose(
        withTranslation("baloapro"),
        connect(mapStateToProps, mapDispatchToProps)
    )(BaloaProView);