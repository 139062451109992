export const loadTournamentDatesAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_DATES", data }
}

export const loadTournamentPhaseGroupDatesAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_GROUP_DATES", data }
}

export const isloadedTournamentPhaseGroupDatesAction = ( data ) => {
    return { type: "TOURNAMENT:IS_LOADED_GROUP_DATES", data }
}

export const loadLeagueTournamentsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_LEAGUE_TOURNAMENTES", data }
}

export const changeTournamentBasicDataError = ( data ) => {
    return { type: "TOURNAMENT:CHANGE_BASIC_DATA_ERROR", data }
}

export const changeNewMatchModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_NEW_MATCH_MODAL_STATE", new_state }
}

export const changeNewMatchError = ( data ) => {
    return { type: "TOURNAMENT:CHANGE_NEW_MATCH_ERROR", data }
}

/*export const addDateToTournamentAction = ( data ) => {
    return { type: "TOURNAMENT:ADD_DATE", data }
}*/

export const addDateToTournamentGroupAction = ( data ) => {
    return { type: "TOURNAMENT:ADD_DATE", data }
}

export const loadDateMatchesAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_DATE_MATCHES", data }
}

export const setSelectedTournamentDateAction = ( selected_date ) => {
    return { type: "TOURNAMENT:SET_SELECTED_DATE", selected_date }
}

export const addMemberToTournamentAction = ( data ) => {
    return { type: "TOURNAMENT:ADD_MEMBER", data }
}

export const changeEndMatchModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_END_MATCH_MODAL_STATE", new_state }
}

export const changeWarningLeagueWithNoTeamsModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_WARNING_LEAGUE_WITH_NO_TEAMS_MODAL_STATE", new_state }
}

export const changeEndMatchError = ( data ) => {
    return { type: "TOURNAMENT:CHANGE_END_MATCH_ERROR", data }
}

export const changeEndTournamentModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:END_TOURNAMENT_MODAL_STATE", new_state }
}

export const loadTournamentAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT", data }
}

export const addTournamentTeamAction = ( data ) => {
    return { type: "TOURNAMENT:ADD_TOURNAMENT_TEAM", data }
}

export const addGroupsPhasesAction = ( data ) => {
    return { type: "TOURNAMENT:SET_PHASE_TYPE_GROUPS", data  }
}

export const loadTournamentTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_TEAMS", data }
}

export const loadTournamentMembersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_MEMBERS", data }
}
export const loadTournamentInfoAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_INFO", data }
}

export const deleteTeamFromTournamentAction = ( data ) => {
    return { type: "TOURNAMENT:DELETE_TEAM_FROM_TOURNAMENT", data }
}

export const disableTeamFromTournamentAction= ( data ) => {
    return { type: "TOURNAMENT:DISABLE_TEAM_FROM_TOURNAMENT", data }
}

export const deleteMemberFromTournamentAction = ( data ) => {
    return { type: "TOURNAMENT:DELETE_MEMBER_FROM_TOURNAMENT", data }
}

export const loadTournamentResultsTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_RESULTS_TABLE", data }
}

export const loadTournamentPhasesAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_PHASES", data }
}

export const isLoadedTournamentPhasesAction = ( data ) => {
    return { type: "TOURNAMENT:IS_LOADED_TOURNAMENT_PHASES", data }
}

export const loadedTournamentPhasesAction = ( data ) => {
    return { type: "TOURNAMENT:LOADEDED_TOURNAMENT_PHASES", data }
}

export const loadPhaseGroupResultsTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PHASE_GROUP_RESULTS_TABLE", data }
}

export const loadPhaseGroupPositionsTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PHASE_GROUP_POSITION_TABLE", data }
}

export const resetTournamentAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_TOURNAMENT", data }
}

export const resetTournamentMembersAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_MEMBERS_TOURNAMENT", data }
}

export const resetTournamentTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_TEAMS_TOURNAMENT", data }
}

export const resetTournamentMatchsAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_MATCHS_TOURNAMENT", data }
}

export const resetTournamentActivePhaseAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_ACTIVE_PHASE_TOURNAMENT", data }
}

export const resetTournamentPhaseTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_PHASE_TEAMS_TOURNAMENT", data }
}

export const resetTournamentDatesAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_DATES_TOURNAMENT", data }
}

export const resetTournamentMatchesAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_MATCHES_TOURNAMENT", data }
}

export const resetTournamentPhasesAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_PHASES_TOURNAMENT", data }
}
export const resetTournamentSelectedDateAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_SELECTED_DATE", data }
}

export const changeEndTournamnetError = ( data ) => {
    return { type: "TOURNAMENT:CHANGE_END_TOURNAMENT_ERROR", data }
}

export const searchWorldCitiesAction = ( data ) => {
    return { type: "TOURNAMENT:SEARCH_WORLD_CITIES", data }
}

export const getCountriesAction = ( data ) => {
   return { type: "TOURNAMENT:GET_COUNTRIES", data }
}

export const searchCityByCountryAction =(data) => {
   return {type: "TOURNAMENT:GET_CITIES_BY_COUNTRY", data}
}

export const loadTournamentPlayersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_PLAYERS", data }
}

export const loadAddPhaseAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_ADD_PHASE", data }
}
export const addTeamToGroupAction = ( data ) => {
    return { type: "TOURNAMENT:ADD_TEAM_TO_GROUP", data }
}

export const deleteTeamFromGroupAction = ( data ) => {
    return { type: "TOURNAMENT:DELETE_TEAM_FROM_GROUP", data }
}

export const changeActivePhaseGroupsCreateStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:ACTIVE_PHASE_GROUPS_CREATE_STATE", new_state }
}

export const setTournamentActivePhaseAction = ( data ) => {
    return { type: "TOURNAMENT:SET_TOURNAMENT_ACTIVE_PHASE", data }
}

export const setTournamentActivePhaseEditAction = ( data ) => {
    return { type: "TOURNAMENT:SET_TOURNAMENT_ACTIVE_PHASE_EDIT", data }
}

export const changeMatchsToCloseModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_MATCHS_TO_CLOSE_MODAL_STATE", new_state }
}

export const changeWarningLeavingPhaseStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_WARNING_LEAVE_PHASE_MODAL_STATE", new_state }
}

export const changeTournamentSaveTournamentModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TOURNAMENT_SAVE_TOURNAMENT_MODAL_STATE", new_state }
}

export const changeTeamsDisabledDialogModalStateAction= ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TEAMS_DISABLED_DIALOG_MODAL_STATE", new_state }
}

export const changeFinishFreePhaseModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FINISH_FREE_PHASE_MODAL_STATE", new_state }
}

export const changeFinishGroupsPhaseModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FINISH_GROUPS_PHASE_MODAL_STATE", new_state }
}

export const deleteTournamentPhaseAction = ( data ) => {
    return { type: "TOURNAMENT:DELETE_PHASE", data }
}

export const teamsClassifiedByPhaseAction = ( data ) => {
    return { type: "TOURNAMENT:TEAMS_CLASSIFIED_BY_PHASE", data }
}

export const unfinishedMatchesAction = ( data ) => {
    return { type: "TOURNAMENT: UNFINISHED_MATCHES", data }
}

// export const finishTournamentPhaseAction = ( data ) => {
//     return { type: "TOURNAMENT: FINISH_TOURNAMENT_PHASE", data }
// }

export const loadTournamentPhaseTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_PHASE_TEAMS", data }
}

export const loadTournamentPhaseFilterTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_PHASE_FILTER_TEAMS", data }
}

export const changeLeagueTournamentLoadingAction = ( data ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_LEAGUE_TOURNAMENTS", data }
}

export const changeDateMatchesLoadingAction = ( data ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_DATE_MATCHES", data }
}

export const changeAddTeamToGroupModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_ADD_TEAM_TO_GROUP_MODAL_STATE", new_state }
}

export const changeRemoveMultiplePhasesModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_REMOVE_MULTIPLE_PHASES_MODAL_STATE", new_state }
}

export const changeUnfinishedTournamentMatchesStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_UNFINISHED_TOURNAMENT_MATCHES_STATE", new_state }
}

export const loadScorerTeamPageMembersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_SCORER_TEAM_MEMBERS", data }
}

export const loadBeatenFenceTeamPageMembersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_BEATEN_FENCE_TEAM_MEMBERS", data }
}

export const loadChampionTeamPageMembersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_CHAMPION_TEAM_MEMBERS", data }
}

export const deleteDateAction = ( data ) => {
    return { type: "TOURNAMENT:DELETE_DATE", data }
}

export const changeDeleteDateModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_DELETE_DATE_MODAL_STATE", new_state }
}

export const changeCloseMatchModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_CLOSE_MATCH_MODAL_STATE", new_state }
}

export const loadTournamentPostsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_POSTS", data }
}

export const changeTournamentFinalizedSuggestionModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TOURNAMENT_FINALIZED_SUGGESTION_MODAL_STATE", new_state }
}

export const searchBaloaTeamAction = ( data ) => {
    return { type: "TOURNAMENT:SEARCHED_BALOA_TEAMS", data }
}

export const cleanBaloaTeamsSearchAction = ( data ) => {
    return { type: "TOURNAMENT:CLEAN_BALOA_TEAMS", data }
}

export const setActivePhaseFixtureAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_ACTIVE_PHASE_FIXTURE_RESUME", data }
}

export const changeRoundtripFixtureModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_ROUNDTRIP_FIXTURE_MODAL_STATE", new_state }
}

export const searchBaloaMemberAction = ( data ) => {
    return { type: "TOURNAMENT:SEARCHED_BALOA_MEMBERS", data }
}

export const changeAddTeamAdviceModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_ADD_TEAM_ADVICE_MODAL_STATE", new_state }
}

export const loadPhaseMatchesByDateAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PHASE_MATCHES_BY_DATE", data }
}

export const loadPhaseDatesAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PHASE_DATES", data }
}

export const changeTeamDetailModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TEAM_DETAIL_MODAL_STATE", new_state }
}

export const addTournamentInvitedTeamAction = (data) =>{
    return {type : "TOURNAMENT:ADD_TOURNAMENT_INVITED_TEAM", data}
}

export const changeStartTournamentModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_START_TOURNAMENT_MODAL_STATE", new_state }
} 

export const changeInviteTeamToTournamentModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_INVITE_TEAM_TO_TOURNAMENT_MODAL_STATE", new_state }
}

export const changeTeamInscriptionModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TEAM_INSCRIPTION_MODAL_STATE", new_state }
}

export const changeTeamTournamentStateAction = ( data ) => {
    return { type: "TOURNAMENT:CHANGE_TEAM_TOURNAMENT_STATE", data }
}

export const changeInvitationInscriptionActionsModalStateAction = ( new_state ) => { 
    return { type: "TOURNAMENT:CHANGE_INVITATION_INSCRIPTION_ACTIONS_MODAL_STATE", new_state }
}

export const loadTournamentTeamPlayersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_TEAM_PLAYERS", data }
}

export const loadTournamentMembersTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_MEMBERS_TEAM_PLAYERS", data }
}

export const loadTournamentSetupTeamsAction = ( data ) => {
    return { type:  "TOURNAMENT:LOAD_TOURNAMENT_SETUP_TEAMS", data }
}

export const addTournamentSetupTeamAction = ( data ) => {
    return { type: "TOURNAMENT:ADD_TOURNAMENT_SETUP_TEAM", data }
}

export const deleteTeamSetupFromTournamentAction = ( data ) => {
    return { type: "TOURNAMENT:DELETE_SETUP_TEAM_FROM_TOURNAMENT", data }
}

export const loadFormDataAction= ( data ) => {
    return { type: "TOURNAMENT:LOAD_FORM_DATA", data}
}

export const changeInscriptionViewFormDataModalStateAction = ( new_state ) => { 
    return { type: "TOURNAMENT:CHANGE_INCRIPTION_VIEW_FORM_DATA_MODAL_STATE", new_state }
}

export const changePaymentInformationModalStateAction = ( new_state ) =>{
    return { type: "TOURNAMENT:CHANGE_PAYMENT_INFORMATION_MODAL_STATE", new_state }
}

export const removerPlayerFromTeamAction = ( data ) => {
    return { type: "TOURNAMENT:REMOVE_PLAYER_FROM_TEAM", data }
}

export const changeStartTournamentStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_START_TOURNAMENT_STATE", new_state }
}

export const updateTournamentTeamAction = ( data ) => {
    return { type: "TOURNAMENT:UPDATE_TOURNAMENT_TEAM", data }
}

export const loadCriteriaTournamentPhaseAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_CRITERIA_TOURNAMENT_PHASE", data }
}

export const changeTournamentDynamicFormsModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TOURNAMENT_DYNAMIC_FORMS_MODAL_STATE", new_state }
} 

export const updateTournamentTeamPlayersAction = ( data ) => {
    return { type: "TOURNAMENT:UPDATE_TOURNAMENT_TEAM_PLAYERS", data }
}

export const loadFieldsStatusEditingAction = (data) => { 
    return {type: "TOURNAMENT:LOAD_FIELDS_STATUS_EDITING", data}
}

export const disableNewTournamentSubmitButtonAction = ( new_state ) => {
    return { type: "TOURNAMENT:DISABLE_NEW_TOURNAMENT_SUBMIT_BUTTON", new_state }
}

export const changeTournamentStartInfoModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TOURNAMENT_START_INFO_MODAL_STATE", new_state }
}

export const changeTournamentTranferPlayerModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TOURNAMENT_TRANSFER_PLAYER_MODAL_STATE", new_state }
}

export const codeFormIDTemplateAction = ( data ) => {
    return { type: "TOURNAMENT:CODE_FORM_ID_TEMPLATE_STATE", data }
}

export const stateSuccessFulPhaseEditionAction = (new_state) => {
    return {type: "TOURNAMENT:STATE_SUCCESSFUL_PHASE_EDITION", new_state}
}

export const loadTournamentStaffAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_STAFF", data }
}

export const changeAddStaffModalStateAction = (new_state) => {
    return {type: "TOURNAMENT:CHANGE_ADD_STAFF_MODAL_STATE", new_state}
}

export const searchBaloaUserAction= (data) => { 
    return {type: "TOURNAMENT:SEARCH_BALOA_USERS", data}
}

export const cleanBaloaUserAction= (data) => { 
    return {type: "TOURNAMENT:CLEAN_BALOA_USERS", data}
}

export const addUserToStaffAction= (data) => { 
    return {type: "TOURNAMENT:ADD_USER_TO_STAFF", data}
}

export const updateStaffStateAction= (data) => { 
    return {type: "TOURNAMENT:UPDATE_STAFF_STATE", data}
}

export const deleteStaffAction= (data) => { 
    return {type: "TOURNAMENT:DELETE_STAFF", data}
}

export const loadRefereeingPaymentUrlAction = (data) => {
    return {type: "TOURNAMENT:LOAD_REFEREEING_PAYMENT_URL", data}
}

export const calculateRefereeFeeAction = (data) => {
    return { type: "TOURNAMENT:CALCULATE_REFEREE_FEE", data }
}

export const resetRefereeFeeAction = (data) => {
    return { type: "TOURNAMENT:RESET_REFEREE_FEE", data }
}

export const loadTournamentPhaseInterGroupsTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_PHASE_INTERGROUPS_TEAMS", data }
}

export const loadPhaseTeamsNotAssignedAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PHASE_TEAMS_NOT_ASSIGNED", data }
}

export const changeEditPhaseModalStateAction = (new_state) => {
    return {type: "TOURNAMENT:CHANGE_EDIT_PHASE_MODAL_STATE", new_state}
}

export const changeEditPhaseValidationModalStateAction = (new_state) => {
    return {type: "TOURNAMENT:CHANGE_EDIT_PHASE_VALIDATION_MODAL_STATE", new_state}
}

export const setTournamentActivePhaseOfEndPointAction = ( data ) => {
    return { type: "TOURNAMENT:SET_TOURNAMENT_ACTIVE_PHASE_END_POINT", data }
}

export const changePhaseFinalizedStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_PHASE_FINALIZED_STATE", new_state }
} 

export const loadTournamentPhaseTeamsModalAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_PHASE_TEAMS_MODAL", data }
}

export const loadFullResponseTeamMemberAction =(state)=>{
    return { type: "TOURNAMENT:LOAD_FULL_RESPONSE_TEAM_MEMBERS", state}
}

export const setTeamsToMatchAssistanceAligment = (data)=>{
    return {type: "TOURNAMENT:SET_TEAMS_MATCH_ASSISTANCE_ALIGMENT", data}
}

export const resetTeamsToMatchAssistanceAligment = (data)=>{
    return {type: "TOURNAMENT:RESET_TEAMS_MATCH_ASSISTANCE_ALIGMENT", data}
}

export const loadRevelationPlayerTeamPlayersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_REVELATION_PLAYERS_TEAM_MEMBERS", data }
}
export const loadTournamentPlayerTeamPlayersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_PLAYER_TEAM_MEMBERS", data }
}
export const loadThirdPlaceTeamPlayersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_THIRD_PLACE_TEAM_MEMBERS", data }
}
export const loadRunnerUpTeamPlayersAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_RUNNER_UP_TEAM_MEMBERS", data }
}

export const setItemsForTournamentTypeAction = (data) =>{
    return {type: "TOURNAMENT:SET_ITEMS_FOR_TOURNAMENT_TYPE", data}
}

export const changeRejectMultiplePlayerModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_REJECT_MULTIPLE_PLAYER_MODAL_STATE", new_state }
}

export const resetPhaseDatesMatchesAction = (data) => {
    return { type: "TOURNAMENT:RESET_PHASE_DATES_MATCHES", data}
}

export const loadFormsAction = (data) => {
    return { type: "TOURNAMENT:LOAD_FORMS_ACTION", data}
}

export const loadListExistingFormAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_LIST_EXISTING_FORM", data }
}

export const loadPageFormTemplateAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PAGE_FORM_TEMPLATE", data }
}

export const cleanPageFormTemplateAction = ( data ) => {
    return { type: "TOURNAMENT:CLEAN_PAGE_FORM_TEMPLATE", data }
}

export const createCarnetFormTemplateAction = ( data, templateImage, orientation ) => {
    return { type: "TOURNAMENT:CREATE_CARNET_FORM_TEMPLATE", data, templateImage, orientation }
}

export const saveCardIdFormInfoAction = ( data ) => {
    return { type: "TOURNAMENT:SAVE_CARD_ID_FORM_INFO", data}
}

export const changeCardIdComponentStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_CARD_ID_COMPONENT_STATE", new_state }
}

export const changeCardIdValidationModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_CARD_ID_VALIDATION_MODAL_STATE", new_state }
}

export const cleanCardIdInfoAction = ( data ) => {
    return { type: "TOURNAMENT:CLEAN_CARD_ID_INFO", data}
}

export const loadCardIdTemplateAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_CARD_ID_TEMPLATE", data}
}

export const changeCardIdDeleteValidationModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_CARD_ID_DELETE_VALIDATION_MODAL_STATE", new_state }
}

export const deleteCardIdTemplateAction = ( data ) => {
    return { type: "TOURNAMENT:DELETE_CARD_ID_TEMPLATE", data}
}

export const changeCardIdTeamsSelectModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_CARD_ID_TEAMS_SELECT_MODAL_STATE", new_state }
}

export const loadCardIdsDataAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_CARD_IDS_DATA", data}
}

export const changeCardIdPdfGeneratorModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_CARD_ID_PDF_GENERATOR_MODAL_STATE", new_state }
}

export const changeCardIdExitValidationModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_CARD_ID_EXIT_VALIDATION_MODAL_STATE", new_state }
}

export const loadTeamManagerAndStaffAction = ( data ) => {
    return { type: "TOURNAMENT:TEAM_MANAGER_AND_STAFF", data }
}

export const changeCreateFieldDataError = ( data ) => {
    return { type: "TOURNAMENT:CHANGE_CREATE_FIELD_DATA_ERROR", data }
}

export const changeFieldsAdminModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FIELDS_ADMIN_MODAL_STATE", new_state }
}

export const changeFieldsCreateModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FIELDS_CREATE_MODAL_STATE", new_state }
}

export const loadPageFieldsDataAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_PAGE_FIELDS_DATA", data }
}

export const changeFieldsWarningModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FIELDS_WARNING_MODAL_STATE", new_state }
}

export const changeTournamentFieldsWarningModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TOURNAMENT_FIELDS_WARNING_MODAL_STATE", new_state }
}

export const changeTournamentFieldsAdminModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TOURNAMENT_FIELDS_ADMIN_MODAL_STATE", new_state }
}

export const pageFieldData = ( data ) => {
    return { type: "TOURNAMENT:PAGE_FIELD_DATA", data }
}

export const loadTournamentFieldsDataAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_FIELDS_DATA", data }
}

export const changeTournamentAddExistingFieldsStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_TOURNAMENT_ADD_EXISTING_FIELDS_MODAL_STATE", new_state }
}

export const loadTournamentAvailableFieldsDataAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_AVAILABLE_FIELDS_DATA", data }
}

export const changeFixtureMatchesAvailableDatesModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FIXTURE_MATCHES_AVAILABLE_DATES_MODAL_STATE", new_state }
}

export const changeFixtureMatchesBlockDatesModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FIXTURE_MATCHES_BLOCK_DATES_MODAL_STATE", new_state }
}

export const setFixtureAvailableDaysAction = ( data ) => {
    return { type: "TOURNAMENT:SET_FIXTURE_AVAILABLE_DAYS", data }
}

export const setGroupedFixtureAvailableDaysAction = ( data ) => {
    return { type: "TOURNAMENT:SET_GROUPED_FIXTURE_AVAILABLE_DAYS", data }
}

export const clearTournamentAvailableFixtureDatesAction = ( data ) => {
    return { type: "TOURNAMENT:CLEAR_TOURNAMENT_AVAILABLE_FIXTURE_DATES", data}
}

export const setFixtureLockedDaysAction = ( data ) => {
    return { type: "TOURNAMENT:SET_FIXTURE_LOCKED_DAYS", data }
}

export const setFixtureFieldTeamsAction = ( data ) => {
    return { type: "TOURNAMENT:SET_FIXTURE_FIELD_TEAMS", data }
}

export const changeFixtureAddTeamToFieldModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FIXTURE_ADD_TEAM_TO_FIELD_MODAL_STATE", new_state }
}

export const loadFullFixtureConfigAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_FULL_FIXTURE_CONFIG", data }
}

/* export const setFixtureFieldAvailableDaysAction = ( data ) => {
    return { type: "TOURNAMENT:SET_FIXTURE_FIELD_AVAILABLE_DAYS", data }
} */
export const setGroupedFixtureFieldAvailableDaysAction = ( data ) => {
    return { type: "TOURNAMENT:SET_GROUPED_FIXTURE_FIELDS_AVAILABLE_DAYS", data }
}

export const changeFixtureFieldsMatchesAvailableDatesModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FIXTURE_FIELDS_MATCHES_AVAILABLE_DATES_MODAL_STATE", new_state }
}

export const changeFixtureFieldsMatchesBlockDatesModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FIXTURE_FIELDS_MATCHES_BLOCK_DATES_MODAL_STATE", new_state }
}

export const changeAcceptAutomaticFixtureModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_ACCEPT_AUTOMATIC_FIXTURE_MODAL_STATE", new_state }
}

export const loadTournamentPhaseGroupDatesFilterAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_DATES_FILTER", data }
}

export const deleteFilterDateAction = ( data ) => {
    return { type: "TOURNAMENT:DELETE_FILTER_DATE", data }
}

export const cleanTournamentPhaseGroupDatesFilterAction = ( data ) => {
    return { type: "TOURNAMENT:CLEAN_TOURNAMENT_DATES_FILTER", data }
}

export const cleanNewPageFieldAction = ( data ) => {
    return { type: "TOURNAMENT:CLEAN_PAGE_FIELD_DATA", data }
}

export const changeMatchReportPdfModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_MATCH_REPORT_PDF_MODAL_STATE", new_state }
}

export const resetTournamentCountryCitiesAction =  ( data ) => {
    return { type: "TOURNAMENT:RESET_COUNTRY_CITIES", data}
}

export const changeLoadingTournamentTeamsStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_TEAMS_STATE", new_state }
}

export const resetTournmentTeamPlayersAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_TOURNAMENT_TEAM_PLAYERS", data }
}

export const loadingPhaseGroupPositionsTableStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_PHASE_GROUP_POSITIONS_TABLE_STATE", new_state }
}

export const resetTournamentGroupResultTableAction = ( data ) => {
    return { type: "TOURNAMENT:RESET_GROUP_POSITION_TABLE" , data }
}

export const loadingTournamentScorersTableStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_SCORERS_TABLE_STATE", new_state }
}

export const loadTournamentScorersTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_SCORERS_TABLE", data }
}

export const loadingYellowCardsTableStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_TOURNAMEN_YELLOW_CARDS_TABLE_STATE", new_state }
}

export const loadYellowCardsTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_YELLOW_CARDS_TABLE", data }
}

export const loadingRedCardsTableStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_RED_CARDS_TABLE_STATE", new_state }
}

export const loadRedCardsTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_RED_CARDS_TABLE", data }
}

export const loadingBlueCardsTableStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_BLUE_CARDS_TABLE_STATE", new_state }
}

export const loadBlueCardsTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_BLUE_CARDS_TABLE", data }
}

export const changeTournamentViewMoreStatisticsModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_VIEW_MORE_STATISTICS_MODAL_STATE", new_state }
}

export const changeTournamentEditMatchInfoModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_EDIT_MATCH_INFO_MODAL_STATE", new_state }
}

export const loadingAssistsTableStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_ASSISTS_TABLE_STATE", new_state }
}
    
export const loadAssistsTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_ASSISTS_TABLE", data }
}
   
export const loadingLeastDefeatedFenceTableStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_LEAST_DEFEATED_FENCE_TABLE_STATE", new_state }
}
    
export const loadLeastDefeatedFenceTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_LEAST_DEFEATED_FENCE_TABLE", data }
}
    
export const loadingTeamsMostGoalsTableStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_TEAM_MOST_GOALS_TABLE_STATE", new_state }
}
    
export const loadTeamsMostGoalsTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_TEAM_MOST_GOALS_TABLE", data }
}

export const loadingSummaryTableStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_TOURNAMENT_SUMMARY_TABLE_STATE", new_state }
}

export const loadSummaryTableAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_TOURNAMENT_SUMMARY_TABLE", data }
}

export const changeCreateEditFairPlaySettingsModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_CREATE_EDIT_FAIR_PLAY_SETTINGS_MODAL_STATE", new_state }
}

export const changeFairPlaySelectSettingModalStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_FAIR_PLAY_SELECT_SETTING_MODAL_STATE", new_state }
}

export const loadingCreateFairPlaySettingsStateAction = ( new_state ) => {
    return { type: "TOURNAMENT:CHANGE_LOADING_CREATE_FAIR_PLAY_SETTINGS_STATE", new_state }
}

export const loadFairPlaySettingsAction = ( data ) => {
    return { type: "TOURNAMENT:LOAD_FAIR_PLAY_SETTINGS", data }
}

export const setSelectedFairPlaySettingAction = ( data ) => {
    return { type: "TOURNAMENT:SET_SELECTED_FAIR_PLAY_SETTING", data }
}

export const setDefaultFairPlaySettingAction = ( data ) => {
    return { type: "TOURNAMENT:SET_DEFAULT_FAIR_PLAY_SETTING", data }
}