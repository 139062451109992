/*eslint eqeqeq: "off"*/
// React
import React from 'react';

// Components
import ModalComponent from 'shared/components/modal/ModalComponent';
import ExternalStatComponent from 'app/profiles/components/external-stat/ExternalStatComponent';
import InfiniteScroll from "react-infinite-scroll-component";
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

// Styles
import './ProfileMoreExternalStatsModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Assets
import loading_ball from 'assets/images/loading_ball.gif';

// React device detect
import { isMobile } from 'react-device-detect';


function ProfileMoreExternalStatsModalComponent( props ) {
    
    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        if(props.external_stats_next_page){
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    }, [props.external_stats_next_page]); // eslint-disable-line


    return (
        
        <ModalComponent 
            isOpen={props.isOpen} 
            //onClose={() => { props.onClose() }}
            //title={props.t('externalStats.externalStatsTitle')}
            className='profile-more-external-stats-modal'
        >
            <React.Fragment>
                {isMobile?
                        <div>
                            <div className='profile-more-external-stats-modal__mobile-header'>
                                <div className="modal2__header__close" onClick={() => { props.onClose() }}><span className="modal2__header__close_icon icon-x "></span></div>
                            </div>
                            <div className="modal2__header">
                                <div className="modal2__header__title baloa-names">{props.t('externalStats.externalStatsTitle')}</div>
                                {props.is_owner && !props.is_baloa_pro &&
                                    <span className='hubicon-award_star' />
                                }                                
                                {props.is_owner &&
                                    <span className='hubicon-add_option' onClick={ props.is_baloa_pro? () => { props.handleOnAddExternalStats() } : () => { props.handleOnNoProAddExternalStats() } } />
                                }
                            </div>
                        </div>
                    :
                    <div className="modal2__header">
                        <div className="modal2__header__title baloa-names">{props.t('externalStats.externalStatsTitle')}</div>
                        {props.is_owner && !props.is_baloa_pro &&
                            <span className='hubicon-award_star' />
                        }
                        {props.is_owner &&
                            <span className='hubicon-add_option' onClick={ props.is_baloa_pro? () => {props.handleOnAddExternalStats()} : () => { props.handleOnNoProAddExternalStats() } } />
                        }                        
                        <div className="modal2__header__close" onClick={() => { props.onClose() }}><span className="modal2__header__close_icon icon-x "></span></div>
                    </div>
                }
                
                <div className='profile-more-external-stats-modal__container '>
                    <InfiniteScroll
                        dataLength={props.external_stats?.length}
                        next={ props.fetchMoreData }
                        hasMore={loadMore}
                        loader={<div className='profile-tournaments-teams-summary-modal__loader'><img src={loading_ball} alt="loader" /></div>}
                        height={isMobile? '75vh': '90vh'}
                        endMessage={''}
                    >
                        {props.external_stats?.map(stat => (
                            <ExternalStatComponent
                                stat={stat}
                                is_owner={props.is_owner}
                                handleOnEditExternalStats = { props.handleOnEditExternalStats }
                                is_baloa_pro={ props.is_baloa_pro }
                                handleOnVerifiedExternalStat={ props.handleOnVerifiedExternalStat }
                            />
                        ))
                        }
                    </InfiniteScroll>
                    {!props.is_baloa_pro && props.is_owner &&
                        <div className='profile-more-external-stats-modal__info'>
                            <div>
                                <div className='profile-more-external-stats-modal__info-title'>
                                    <span className='hubicon-award_star' />
                                    <div className='baloa-normal-medium'>{props.t('baloapro:baloaProHeader.title')}</div>
                                </div>
                                <div className='baloa-names'>{props.t('externalStats.notBaloaProOwnerMsg')}</div>
                            </div>
                            <PrimaryButtonComponent
                                input_type={'button'}
                                onClick={ ()=>{ props.handleOnBaloaProSubscription()} }
                            >
                                <span>{props.t('baloapro:baloaProSubscriptionBtnLbl')}</span>
                            </PrimaryButtonComponent>
                        </div>
                    }
                    {!props.is_baloa_pro && !props.is_owner &&
                        <div className='profile-more-external-stats-modal__info'>
                            <span className='hubicon-info'/>
                            <div className='baloa-normal-medium'>{props.t('externalStats.notBaloaProVisitorMsg',{_username: props.username})}</div>
                        </div>
                    }
                </div>
            </React.Fragment>
        </ModalComponent>
    )
}

export default withTranslation(['profile', 'baloapro'])(ProfileMoreExternalStatsModalComponent);