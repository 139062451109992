// React
import React, { useState } from 'react';

//import CurrencyFormat from 'react-currency-format';

// Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Components
//import LeagueAddTeamToTournamentComponent from 'app/leagues/components/league-add-team-to-tournament/LeagueAddTeamToTournamentComponent';
//import LeagueAddMemberToTournamentComponent from 'app/leagues/components/league-add-member-to-tournament/LeagueAddMemberToTournamentComponent';
import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
//import LeagueTournamentMatchesByDateComponent from 'app/leagues/components/league-tournament-matches-by-date/LeagueTournamentMatchesByDateComponent';
import TournamentBasicDataInfoComponent from 'app/leagues/components/tournament-basic-data-info/TournamentBasicDataInfoComponent';
import TournamentPositionGoalScorerTableComponent from 'app/leagues/components/tournament-position-goal-scorer-table/TournamentPositionGoalScorerTableComponent';
import ShowMessageComponent from 'shared/components/show-message/ShowMessageComponent';
import TournamentNewPodiumComponent from 'app/leagues/components/tournament-new-podium/TournamentNewPodiumComponent';
// import TournamentEditBasicDataComponent from 'app/leagues/components/tournament-edit-basic-data/TournamentEditBasicDataComponent';
// import TournamentTeamsListComponent from 'app/leagues/components/tournament-teams-list/TournamentTeamsListComponent';
import TournamentInvitationInscriptionSetupComponent from "app/leagues/components/tournament-invitation-inscription-setup/TournamentInvitationInscriptionSetupComponent";
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';
import TournamentPhasesCreatedComponent from '../tournament-phases-created/TournamentPhasesCreatedComponent';
//import TournamentPhasesComponent from '../tournament-phases/TournamentPhasesComponent';
import TournamentPhasesDropDownComponent from '../tournament-phases-dropDown/TournamentPhasesDropDownComponent';
import LeagueStaffComponent from '../league-staff/LeagueStaffComponent';
import TournamentStatisticsComponent from 'app/leagues/components/tournament-statistics/TournamentStatisticsComponent';
import TournamentMatchesAdminComponent from '../tournament-matches-admin/TournamentMatchesAdminComponent';

// Styles
import './TournamentEditTournamentComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Functions
import { getRandonIconBallColor } from 'shared/functions/GetRandomIconColor';
import { permissionsController } from 'shared/functions/PemissionsController';
import TournamentPhasesComponentV2 from '../tournament-phases/TournamentPhasesComponentV2';


function TabPanel( props ) {
    const { children, value, index, ...other } = props;

    function validatePhase () {
        if(props.tournament_phases.length > 0) {
            let phase_index = props.tournament_phases.findIndex(phase => phase.id === props.tournament_active_phase?.id) 
            if(phase_index > 0 && props.tournament_phases[phase_index - 1].state !== 'Finalized'){
                return true;
            }
        }
    }

    return(
        <div
            role="tabpanel"
            hidden={ value !== index }
            id={ `simple-tabpanel-${index}` }
            aria-labelledby={ `simple-tab-${index}` }
            { ...other }
        >
            <div className={`tabpanel-line ${(index === 0 && props.selected_tournament.state === 'Finalized')? 'podium' : (index === 2 && props.selected_tournament.state !== 'Finalized')? 'stats' :  (index === 3 && props.selected_tournament.state === 'Finalized')? 'stats' : ''}`}>
                
                {/* tab podium data (0) ended tournament */}
                { (value === index) && (index === 0) && (props.selected_tournament.state === 'Finalized') && (
                    <React.Fragment>
                        <TournamentNewPodiumComponent
                            selected_tournament = { props.selected_tournament }
                            handleOnProfileClick = { props.handleOnProfileClick }
                            handleOnPageClick = { props.handleOnPageClick }
                        />
                    </React.Fragment>
                )
                }

                {/* tab calendar data (0) active tournament */}
                { (value === index) && ((index === 0 && props.selected_tournament.state !== 'Finalized') || (index === 1 && props.selected_tournament.state === 'Finalized')) && (
                    <React.Fragment>  
                        {!(props.tournament_phases?.length === 1 && props.tournament_phases[props.tournament_phases?.length - 1]?.phase_type === null) &&                                                
                        <div className="tournament-edit-tournament-calendar-actions">
                            {/* Component to Add Team in Phase*/}
                            {props.tournament_active_phase?.groups[0]?.teams.length === 0 && props.tournament_phases[props.tournament_phases?.findIndex(phase  => phase.id === props.tournament_active_phase?.id) - 1]?.state === "Finalized" && permissionsController( props.pageRoles, ['TOURNAMENTAD']) && props.selected_tournament?.state !== 'Finalized' &&
                                <div className="tournament-edit-tab-calendar-add-teams_Phase">
                                    <div className='tournament-edit-tab-calendar-add-team_text baloa-names'>
                                        <span className='hubicon-info'/>
                                        <div>{props.t('options.tournaments.pages.tournamentInfo.labelBeginPhaseText')}</div>                                        
                                    </div>
                                    <PrimaryButtonComponent
                                        className='tournament-edit-tab-calendar-add-teams_phase_button'
                                        onClick={() => {  props.AddTeamToGroupModal() }}
                                    >
                                        <span className='hubicon-low_priority' />
                                        <div>{props.t('options.tournaments.pages.tournamentInfo.labelAddTeamsToPhase')}</div>
                                    </PrimaryButtonComponent>
                                </div>
                            }
                            { props.tournament_phases?.length === 0 && props.selected_tournament.state !== 'Finalized' &&
                                <div className="tournament-edit-tournament__no-phases__container">
                                    <div className='tournament-edit-tournament__no-phases'>
                                        <span className='hubicon-info'/>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.labelNoTournamentPhases')}</div>                                        
                                    </div>
                                    <PrimaryButtonComponent
                                        className=''
                                        onClick={ () => {props.handleOnClickNewPhase(true, 'onClickNewPhase');} }
                                    >
                                        {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelCreatePhase')}
                                    </PrimaryButtonComponent>
                                </div>
                            }
                            {/* The previous component is TournamentPhasesCreatedComponent */}
                            {props.tournament_phases.length != 0 &&
                            <TournamentPhasesDropDownComponent
                                edit_tournament={props.edit_tournament}
                                tournament_phases={ props.tournament_phases }
                                handleOnClickPhaseTab = { props.handleOnClickPhaseTab } // 'tabCalendar'
                                //handleOnClickPhaseTabInPositionsTable = { props.handleOnClickPhaseTabInPositionsTable }
                                tournament_active_phase={ props.tournament_active_phase }
                                is_loaded_tournament_phases={props.is_loaded_tournament_phases}
                                is_loaded_tournament_dates = {props.is_loaded_tournament_dates}
                                onClickNewPhase={ props.handleAddPhaseFromOtherTabThanPhases }
                                selected_tournament = { props.selected_tournament }
                                date_matches = { props.date_matches }
                                unfinished_matches = {props?.unfinished_matches}
                                tournament_dates={ props.tournament_dates }
                                handleTournamentFinalizedSuggestion={ props.handleTournamentFinalizedSuggestion }  
                                is_tournament_finalized = { props.is_tournament_finalized }
                                pageRoles = { props.pageRoles }
                                deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}}
                                handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}}
                                AddTeamToGroupModal = {() => props.AddTeamToGroupModal()}
                                finishedMatchesByPhase = { (phase) => {props.finishedMatchesByPhase(phase)} }
                                showAddButton = { 
                                    props.edit_tournament && 
                                    props.tournament_phases.findIndex(phase  => phase.id === props.tournament_active_phase?.id) === props.tournament_phases.length - 1 && 
                                    permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                        ? true
                                        : false
                                }
                                showOptionMenu = { 
                                    props.edit_tournament && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                    ? props.tournament_active_phase?.state === "Active"
                                    : props.tournament_active_phase?.state === "Active" && props.tournament_phases.findIndex(phase  => phase.id === props.tournament_active_phase?.id) === props.tournament_phases.length - 1 && permissionsController( props.pageRoles, ['TOURNAMENTAD'])                                    
                                }
                                showDeletePhaseMenu = { 
                                    props.edit_tournament && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                    ? props.tournament_active_phase?.state === "Active" && props.tournament_phases.findIndex(phase  => phase.id === props.tournament_active_phase?.id) >= 1
                                    : props.tournament_active_phase?.state === "Active" && props.tournament_phases.findIndex(phase  => phase.id === props.tournament_active_phase?.id) === props.tournament_phases.length - 1 && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                }
                                showEndPhaseMenu = {
                                    props.tournament_phases.findIndex(phase  => phase.id === props.tournament_active_phase?.id) === 0
                                    ? props.tournament_phases.length === 1 && props.tournament_active_phase?.state === "Active" && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                        ? true
                                        : props.tournament_active_phase?.state === "Active" && permissionsController( props.pageRoles, ['TOURNAMENTAD'])   
                                    : props.tournament_active_phase?.state === "Active" && props.tournament_phases[props.tournament_phases.findIndex(phase  => phase.id === props.tournament_active_phase?.id) - 1]?.state === "Finalized" && props.tournament_active_phase?.phase_type && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                }
                                showAddTeams = {
                                    props.tournament_active_phase?.groups[0]?.teams?.length === 0 && props.tournament_phases[props.tournament_phases.findIndex(phase  => phase.id === props.tournament_active_phase?.id) - 1]?.state === "Finalized" && permissionsController( props.pageRoles, ['TOURNAMENTAD'])
                                    ? true
                                    : false
                                }
                                nameActivePhase={props.nameActivePhase}
                                referees_payment_params={props.referees_payment_params}
                                handleOnClickNewPhase={props.handleOnClickNewPhase}
                            />
                            }
                        </div>
                        }                                            
                        { props.tournament_phases?.length > 0 && props.phase_teams?.length > 0 && props.tournament_phases[0].phase_type &&
                            <TournamentMatchesAdminComponent
                                tournament_dates={ props.tournament_dates }                                
                                handleNewDateTournamentClick={ props.handleNewDateTournamentClick }
                                handleDateClick={ props.handleDateClick }
                                date_matches={ props.date_matches }
                                handleEditMatchTournament = { props.handleEditMatchTournament }  
                                handleNewMatchTournament = { props.handleNewMatchTournament }   
                                handleDeleteMatchTournament = { props.handleDeleteMatchTournament }                                                    
                                handleOnFinishMatchActionClick = { props.handleOnFinishMatchActionClick } 
                                handleGroupClick={ props.handleGroupClick }   
                                groups = {props.groups}     
                                showGroupNavBar = { props.tournament_active_phase?.phase_type !== "Free" }
                                deleteDate={ props.deleteDate }
                                handleOnAutomaticFixture = { props.handleOnAutomaticFixture }                                                    
                                tournament_active_phase={ props.tournament_active_phase }
                                staff={ props.staff }     
                                changeTab={ props.changeTab }     
                                handleTournamentFieldsAdmin={ props.handleTournamentFieldsAdmin }  
                                phase_teams={ props.phase_teams }
                                list_phase_teams={props.list_phase_teams}  
                                tournament_fields={ props.tournament_fields } 
                                handleTournamentPhaseMatchFilters={ props.handleTournamentPhaseMatchFilters }               
                                handleSubmitEditInfoMatch={ props.handleSubmitEditInfoMatch }
                                cities = { props.cities }
                                new_page_field={ props.new_page_field }
                                handleFieldsAdminActions={ props.handleFieldsAdminActions }
                                handleTournamentFieldActions={ props.handleTournamentFieldActions }
                                tournament_selected_date={ props.tournament_selected_date }
                                handleTournamentPhaseDaysFilters={ props.handleTournamentPhaseDaysFilters }
                                tournament_dates_filter={ props.tournament_dates_filter }
                                handleAddStaf= { props.handleAddStaf }
                                language_code =  {props.language_code }
                                handleMatchReport ={ props.viewMatchReport }
                                date_matches_is_loading={ props.date_matches_is_loading }
                                handleDownloadMatchReport={ props.handleDownloadMatchReport }
                                tournament_staff={props.tournament_staff}

                                next_date_matches={props.next_date_matches}
                                handleViewMoreMatches={props.handleViewMoreMatches}


                                editMatchMainInfo={ props.editMatchMainInfo }
                            />
                            // <LeagueTournamentMatchesByDateComponent
                            //     tournament_dates={ props.tournament_dates }                                
                            //     handleNewDateTournamentClick={ ( ) => { props.handleNewDateTournamentClick() } }
                            //     selectedDate={ props.selectedDate }
                            //     handleDateClick={ ( v ) => { props.handleDateClick( v ) } }
                            //     date_matches={ props.date_matches }                              
                            //     disabled_edit_tournament_data = { 
                            //         props.disabled_edit_tournament_data 
                            //         || 
                            //         props.tournament_active_phase?.state ? props.tournament_active_phase?.state === "Finalized" : false
                            //     } 
                            //     //phaseState = { props.tournament_active_phase?.state ? props.tournament_active_phase?.state === "Finalized" : false }                             
                            //     viewMatchReport ={ ( v ) => { props.viewMatchReport( v ) } }
                            //     handleEndMatchTournament = { ( v ) => { props.handleEndMatchTournament( v ) } }   
                            //     handleEditMatchTournament = { ( v ) => { props.handleEditMatchTournament( v ) } }  
                            //     handleNewMatchTournament = { ( v ) => { props.handleNewMatchTournament( v ) } }   
                            //     enable_add_match_button = { props.enable_add_match_button }
                            //     handleDeleteMatchTournament = { ( v ) => { props.handleDeleteMatchTournament( v ) } }                                                    
                            //     handleOnFinishMatchActionClick = { ( v ) => { props.handleOnFinishMatchActionClick( v ) } } 
                            //     handleGroupClick={ ( v ) => { props.handleGroupClick( v ) } }   
                            //     groups = {props.groups}     
                            //     showGroupNavBar = { props.tournament_active_phase?.phase_type !== "Free" }
                            //     edit_tournament = { props.edit_tournament }  
                            //     is_loaded_tournament_phases={props.is_loaded_tournament_phases} 
                            //     is_loaded_tournament_dates = {props.is_loaded_tournament_dates }  
                            //     date_matches_is_loading = { props.date_matches_is_loading }  
                            //     deleteDate={(date,index) => props.deleteDate(date,index)}
                            //     handleOnAutomaticFixture = { props.handleOnAutomaticFixture }                                                    
                            //     fixture_button_active = { props.fixture_button_active }
                            //     pageRoles = { props.pageRoles }
                            //     tournament_active_phase={props.tournament_active_phase}
                            //     selected_tournament_state = { props.selected_tournament.state }
                            // />
                        }
                        { props.tournament_phases?.length === 0 &&
                            <ShowMessageComponent
                                lock={true}
                                message={props.t('options.tournaments.pages.tournamentInfo.labelTournamentWithoutPhases')}
                            />
                        }

                        { props.tournament_phases?.length > 0 && props.tournament_active_phase?.is_last_active_phase && props.tournament_active_phase.groups.some(group => group.teams.length === 0) &&
                            <ShowMessageComponent
                                lock={true}
                                message={props.t('options.tournaments.pages.tournamentInfo.labelMessageUninitializedPhase')}
                            />
                        }

                        { validatePhase() &&
                            <ShowMessageComponent
                                lock={true}
                                message={props.t('options.tournaments.pages.tournamentInfo.labelMessageBlockedPhase')}
                            />
                        }
                    </React.Fragment>                   
                )}                

                {/* tab position table (1)*/}
                { (value === index) && ((index === 1 && props.selected_tournament.state !== 'Finalized') || (index === 2 && props.selected_tournament.state === 'Finalized')) && ( 
                    <React.Fragment>
                        <div className='tournament-edit-tournament_iframe_options'>
                            <TournamentPhasesCreatedComponent
                                tournament_phases={ props.tournament_phases }
                                edit_tournament={props.edit_tournament}                            
                                handleOnClickPhaseTab = { props.handleOnClickPhaseTabInPositionsTable }  // Show results or Podium tab  
                                tournament_active_phase={ props.tournament_active_phase }                                                                                                
                                tournament = { props.selected_tournament.id }                              
                                deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}}
                                handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}}  
                                is_loaded_tournament_phases={props.is_loaded_tournament_phases}  
                                onClickNewPhase={ props.handleAddPhaseFromOtherTabThanPhases } 
                                selected_tournament = { props.selected_tournament }
                                AddTeamToGroupModal = {() => props.AddTeamToGroupModal()}
                                finishedMatchesByPhase = { (phase) => {props.finishedMatchesByPhase(phase)} }
                                unfinished_matches = {props.unfinished_matches}
                                date_matches = { props.date_matches }
                                handleTournamentFinalizedSuggestion={ props.handleTournamentFinalizedSuggestion }
                                pageRoles = { props.pageRoles }
                            />
                            { props.tournament_phases?.length > 0 &&
                                <SecondaryButtonComponent
                                    className='tournament-edit-tournament_iframe_share-btn small'
                                    input_type={'button'}
                                    onClick={ ()=>{ props.handleOpenShareIframeLinkModal(props.selected_tournament.id)}}
                                >
                                    <span className='hubicon-code'/>
                                    <span className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.labelCopyLinkIframe')}</span>
                                </SecondaryButtonComponent>
                            }
                        </div>
                        { props.tournament_phases?.length === 0 &&
                                <div className="tournament-edit-tournament__no-phases__container">
                                    <div className='tournament-edit-tournament__no-phases'>
                                        <span className='hubicon-info'/>
                                        <div className='baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.labelNoTournamentPhases')}</div>                                        
                                    </div>
                                    <PrimaryButtonComponent
                                        className=''
                                        onClick={ () => {props.handleOnClickNewPhase(true, 'onClickNewPhase');} }
                                        >
                                        {props.t('options.tournaments.pages.newTournament.tabs.tabTournamentPhases.labelCreatePhase')}
                                    </PrimaryButtonComponent>
                                </div>
                            }
                        { props.showPhasePositionTable && !props.showLockedPhaseInPositionsTable && props.is_loaded_tournament_phases &&
                            <div>
                                <div className="tournament-edit-tournament__classified">
                                    <div className="tournament-edit-tournament__classified-square" />
                                    <div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelTeamClasified')}</div>                                                      
                                </div>
                                { props.is_best_third &&
                                    <div className="tournament-edit-tournament__classified">
                                        <div className="tournament-edit-tournament__best-third-square" />
                                        <div className="baloa-table">{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.positionsTable.labelTeamBestThird')}</div>
                                    </div>
                                }
                                <TournamentPositionGoalScorerTableComponent
                                    phase_groups_results_table={ props.phase_groups_results_table }
                                    tournament_results_table = { props.tournament_results_table }                        
                                    phase_type = { props.tournament_active_phase?.phase_type }
                                    phase_name = { props.tournament_active_phase?.phase_name }
                                    handleOnPhaseGroupPositionTable={ props.handleOnPhaseGroupPositionTable }
                                    loading_phase_group_positions_table={ props.loading_phase_group_positions_table }
                                />
                            </div>
                        }
                        { props.showLockedPhaseInPositionsTable &&
                            <ShowMessageComponent
                                lock={true}                                
                                message={props.t('options.tournaments.pages.tournamentInfo.labelMessageBlockedPhase')}
                            />
                        }
                        { !props.is_loaded_tournament_phases &&
                            <ShowMessageComponent
                                lock={true}
                                message={props.t('options.tournaments.pages.tournamentInfo.labelTournamentWithoutPhases')}
                            />
                        }
                    </React.Fragment>
                )}

                {/* tab statistics (2) all tournament */}
                { (value === index) && ((index === 2 && props.selected_tournament.state !== 'Finalized') || (index === 3 && props.selected_tournament.state === 'Finalized')) && (
                    <React.Fragment>
                        <div className='tournament-edit-tournament_stats'>
                            <TournamentStatisticsComponent 
                                tournament_results_table = { props.tournament_results_table ? props.tournament_results_table : [] }
                                tournament_scorers = { props.tournament_scorers }
                                tournament_yellow_cards = { props.tournament_yellow_cards }
                                tournament_red_cards = { props.tournament_red_cards }
                                tournament_blue_cards = { props.tournament_blue_cards }
                                tournament_assists = { props.tournament_assists }
                                tournament_least_defeated_fence = { props.tournament_least_defeated_fence }
                                tournament_teams_most_goals = { props.tournament_teams_most_goals }
                                tournament_summary = { props.tournament_summary }
                                loading_tournament_scorers = { props.loading_tournament_scorers }
                                loading_tournament_yellow_cards = { props.loading_tournament_yellow_cards }
                                loading_tournament_red_cards = { props.loading_tournament_red_cards }
                                loading_tournament_blue_cards = { props.loading_tournament_blue_cards }
                                loading_tournament_assists = { props.loading_tournament_assists }
                                loading_tournament_least_defeated_fence = { props.loading_tournament_least_defeated_fence }
                                loading_tournament_teams_most_goals = { props.loading_tournament_teams_most_goals }
                                loading_tournament_summary = { props.loading_tournament_summary }
                                next_tournament_scorers = { props.next_tournament_scorers }
                                next_tournament_yellow_cards = { props.next_tournament_yellow_cards }
                                next_tournament_red_cards = { props.next_tournament_red_cards }
                                next_tournament_blue_cards = { props.next_tournament_blue_cards }
                                next_tournament_assists = { props.next_tournament_assists }
                                next_tournament_least_defeated_fence = { props.next_tournament_least_defeated_fence }
                                next_tournament_teams_most_goals = { props.next_tournament_teams_most_goals }
                                handleOnViewMoreStatistics = { props.handleOnViewMoreStatistics }
                            />
                        </div>
                    </React.Fragment>
                )
                }

                {/* tab basic info ended tournament */}
                { /* (value === index) && (index === 2) && (props.selected_tournament.state === 'Finalized') && (
                    <React.Fragment>
                        <TournamentBasicDataInfoComponent
                            selected_tournament = { props.selected_tournament }
                        ></TournamentBasicDataInfoComponent>
                    </React.Fragment>
                ) */}
                
                {/* tab teams */}
                { (value === index)  && ((index === 3 && props.selected_tournament.state !== 'Finalized') || (index === 4 && props.selected_tournament.state === 'Finalized')) && (                    
                    <React.Fragment>
                        {/* <TournamentTeamsListComponent 
                            tournament_setup_teams={props.tournament_setup_teams}
                            handleTeamInfo={ props.handleTeamInfo }
                            handleDisableTeam={props.handleDisableTeam}
                            // handleOnDeleteTeamFromTournament={ props.handleOnDeleteTeamFromTournament }
                            // handleOnDisableTeamFromTournament = { props.handleOnDisableTeamFromTournament }
                        /> */}

                        {/*
                        { props.pageRoles.tournament_admin &&
                            <div className='tournament-edit-tournament__player-resume-stats' >
                                <div className='tournament-edit-tournament__player-resume-stats__title'>
                                    <div className='baloa-names'>{props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.resumeStatsTitle')}</div>
                                    <div className='tournament-edit-tournament__player-resume-stats__title__tooltip'>
                                        <div className='baloa-table-column'></div>
                                        <span className='hubicon-help_fill'/>
                                    </div>
                                </div>
                                <div className='tournament-edit-tournament__player-resume-stats__stats'>
                                    <div className='tournament-edit-tournament__player-resume-stats__stats-col'>
                                        <div className='baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.resumeStatsActiveLabel')}</div>
                                        <div className='baloa-names'>{props.tournament.active_players ?? 0}</div>
                                    </div>
                                    <div className='tournament-edit-tournament__player-resume-stats__stats-col'>
                                        <div className='baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.resumeStatsPendingLabel')}</div>
                                        <div className='baloa-names'>{props.tournament.inactive_players ?? 0}</div>
                                    </div>
                                    <div className='tournament-edit-tournament__player-resume-stats__stats-col'>
                                        <div className='baloa-table'>{props.t('options.tournaments.pages.tournamentInfo.playerResumeStats.resumeStatsAmountLabel')}</div>
                                        <CurrencyFormat
                                            value={props.tournament.team_total_values_stats} 
                                            displayType={'text'} 
                                            thousandSeparator={','} 
                                            decimalSeparator={"."} 
                                            prefix={'$ '} 
                                            suffix={` ${props.tournament.currency_payment}`}
                                            onValueChange ={()=>{}}
                                            renderText={value => <div className='baloa-names'>{value}</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        */}
                        
                        <TournamentInvitationInscriptionSetupComponent 
                            tournament={props.tournament}
                            tournament_teams={ props.tournament_setup_teams}
                            baloaTeams={ props.baloaTeams }
                            pageRoles={ props.pageRoles }
                            registration_only={true}
                            handleOnAddTeamToTournament={ props.handleOnAddTeamToTournament}
                            handleSearchBaloaTeam={ props.handleSearchBaloaTeam}
                            handleCleanBaloaTeamsSearch={props.handleCleanBaloaTeamsSearch}
                            // sendInvitationToTeamTournament={props.sendInvitationToTeamTournament} // Prop Send-invitations 
                            handleOnInviteTeamModal={ props.handleOnInviteTeamModal }
                            handleOnValidateTeamInscriptionModal={ props.handleOnValidateTeamInscriptionModal } 
                            handleInscriptionInvitationModalActions={ props.handleInscriptionInvitationModalActions }
                            tournament_id={ props.selected_tournament.id }
                            handleOnDownloadForms= { props.handleOnDownloadForms }
                            handleOnDownloadPayments={ props.handleOnDownloadPayments }
                            
                            handleLoadformPlayer = {props.handleLoadformPlayer}
                            sendCarnet = {props.sendCarnet}
                            GetCodeFormTemplate = {props.GetCodeFormTemplate}
                            card_id_template={props.card_id_template}
                            deleteCardIdTemplate={props.deleteCardIdTemplate}
                            handleOnDeleteCardIdTemplate={props.handleOnDeleteCardIdTemplate}
                            handleOnDownloadCardIds={props.handleOnDownloadCardIds}
                            next_tournament_teams={props.next_tournament_teams}
                            loadMoreTeams={props.loadMoreTeams}
                            tournament_teams_loading={props.tournament_teams_loading}
                            tournament_teams_count={props.tournament_teams_count}
                            loadInfoFormModal={props.loadInfoFormModal}
                            handleTeamPlayerPaymentInfo={ props.handleTeamPlayerPaymentInfo }

                            handleOnSearchTeams={props.handleOnSearchTeams}
                        />

                        {/* <LeagueAddTeamToTournamentComponent
                            league_teams={ props.league_teams }
                            tournament_teams={ props.tournament_teams }
                            handleOnAddTeamToTournament={ props.handleOnAddTeamToTournament }
                            handleOnDeleteTeamFromTournament={ props.handleOnDeleteTeamFromTournament }
                            handleOnDisableTeamFromTournament = { props.handleOnDisableTeamFromTournament }
                            createTeamsTournament = { props.createTeamsTournament }
                            has_continue_button = { false }
                            tabval={1}
                            disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                            enable_add_match_button = { props.enable_add_match_button }
                            //disbledTeams={ props.tournament_phases.length > 0 }
                            disbledTeams={ checkActivePhases() }
                            phase_name_values = { props.phase_name_values }
                            handleSearchBaloaTeam = { props.handleSearchBaloaTeam }
                            baloaTeams = { props.baloaTeams }
                            handleCleanBaloaTeamsSearch = { props.handleCleanBaloaTeamsSearch }
                            eraseTeams={ props.tournament_phases.length === 0 }
                            pageRoles = { props.pageRoles }
                        /> */}


                    </React.Fragment>                    
                )}

                {/* tab phases */}
                { (value === index) && ((index === 4 && props.selected_tournament.state !== 'Finalized') || (index === 5 && props.selected_tournament.state === 'Finalized')) && (
                    <React.Fragment>
                        <TournamentPhasesComponentV2
                            onResetError={ (data) => {props.onResetError(data)} }
                            errors={ props.errors }
                            errorsNewGroupTournament={props.errorsNewGroupTournament}
                            league_page = { props.league_page }
                            basic_data_info = { props.basic_data_info }
                            cities = { props.cities }
                            tournament_teams={ props.phase_teams }
                            tournament_active_phase={ props.tournament_active_phase }
                            handleAddTeamToGroup = { props.handleAddTeamToGroup }
                            handleDeleteTeamFromGroup = { props.handleDeleteTeamFromGroup }
                            active_phase_groups_create = { props.active_phase_groups_create }
                            handleActivePhaseCreateState = { props.handleActivePhaseCreateState }
                            tabval={-1}
                            selectNextTab = { props.selectNextTab }
                            tournament = { props.selected_tournament.id }
                            addTournamentPhase = { props.addTournamentPhase }
                            tournament_phases={ props.tournament_phases }
                            edit_tournament={props.edit_tournament}
                            createNewGroupPhases = {  props.createNewGroupPhases }                             
                            handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}}                           
                            deleteTournamentPhase= {(phase,index) => {props.deleteTournamentPhase(phase,index)}}   
                            handleOnClickPhaseTab = { props.handleOnClickPhaseTab } // tabPhase
                            teams_classified_by_phases={props.teams_classified_by_phases}
                            show_add_teams_in_second_phase = { props.tournament_phases.length >= 1 }     
                            is_loaded_tournament_phases={props.is_loaded_tournament_phases}  
                            finished_last_phase_with_one_clasified = { props.finished_last_phase_with_one_clasified }
                            selected_tournament = { props.selected_tournament }
                            showLockedAddPhase = { props.showLockedAddPhase }
                            disabledTabs= { props.disabledTabs }
                            handleLeavingPhaseTab = {() => {props.handleLeavingPhaseTab()}}
                            AddTeamToGroupModal = {() => props.AddTeamToGroupModal()}
                            date_matches = { props.date_matches }
                            
                            finishedMatchesByPhase = { (phase) => {props.finishedMatchesByPhase(phase)} }
                            unfinished_matches = {props?.unfinished_matches}
                            phase_name_values = { props.phase_name_values }
                            handleTournamentFinalizedSuggestion={ props.handleTournamentFinalizedSuggestion }                            
                            is_tournament_finalized = { props.is_tournament_finalized }
                            allTournamentTeams = { props.tournament_teams }
                            deleteTournamentPhaseWihtOutType = { props.deleteTournamentPhaseWihtOutType }
                            pageRoles = { props.pageRoles }
                            handleCalendarStep={ props.handleCalendarStep }
                            tournament_criteria_tie_breaking= {props.tournament_criteria_tie_breaking}
                            tournament_new_order_criteria_tie_breaking={props.tournament_new_order_criteria_tie_breaking}
                            handle_team_filter= {props.handle_team_filter}
                            filter_phase_teams={props.filter_phase_teams}
                            handleBestThirdByGroup={props.handleBestThirdByGroup}
                            loadTieBreakerByPhase={props.loadTieBreakerByPhase}
                            handleEditPhase={props.handleEditPhase}
                            handleMoveToGroup={props.handleMoveToGroup}
                            handleTeamGroupPhaseknockout={props.handleTeamGroupPhaseknockout}
                            nameActivePhase={props.nameActivePhase}
                            not_assigned_teams={props.not_assigned_teams}

                            handleOnEditPhaseModal={props.handleOnEditPhaseModal}
                            handleOnClickNewPhase={props.handleOnClickNewPhase}
                            handleDeleteGroupPhase={props.handleDeleteGroupPhase}
                            handleNumberClassified={props.handleNumberClassified}
                            deleteTeamFromCreatePhase={props.deleteTeamFromCreatePhase}
                            teams_filter_type={props.teams_filter_type}
                        />

                    </React.Fragment>                    
                )}

                {/* tab members */}
                { (value === index) && ((index === 5 && props.selected_tournament.state !== 'Finalized') || (index === 6 && props.selected_tournament.state === 'Finalized')) && (
                    <React.Fragment>
                        {/* <LeagueAddMemberToTournamentComponent
                            league_members={ props.league_members }
                            tournament_members={ props.tournament_members }
                            handleOnAddMemberToTournament={ props.handleOnAddMemberToTournament }
                            handleOnDeleteMemberFromTournament={ props.handleOnDeleteMemberFromTournament }     
                            createTeamsTournament = { props.createTeamsTournament }
                            has_continue_button = { false }    
                            tabval={2}   
                            disabled_edit_tournament_data = { props.disabled_edit_tournament_data }    
                            enable_add_match_button = { props.enable_add_match_button }
                            phase_name_values = { props.phase_name_values } 
                            pageRoles = { props.pageRoles }                   
                        /> */}
                        <LeagueStaffComponent 
                            handleAddStaf={ props.handleAddStaf }
                            staff={props.staff}
                            handleOnAcceptStaffClick = { props.handleOnAcceptStaffClick }
                            handleOnRejectStaffClick = { props.handleOnRejectStaffClick }
                            handleOnEditStaffClick = { props.handleOnEditStaffClick }
                            handleOnDeleteStaffClick = { props.handleOnDeleteStaffClick }
                            selected_tournament = { props.selected_tournament }
                            
                        />
                    </React.Fragment>
                )}                        

                {/* tab basic info */}
                { (value === index) && ((index === 6 && props.selected_tournament.state !== 'Finalized') || (index === 7 && props.selected_tournament.state === 'Finalized')) && (
                    <React.Fragment>
                        {!props.editBasicInfo &&
                            <TournamentBasicDataInfoComponent
                                selected_tournament = { props.selected_tournament }
                                handleOnEditTournamentInfo = { props.handleOnEditTournamentInfo }
                                pageRoles = { props.pageRoles }
                            ></TournamentBasicDataInfoComponent>
                        }
                        {/* {props.editBasicInfo &&
                            <TournamentEditBasicDataComponentV2
                                onResetError={ (data) => {props.onResetError(data)} }
                                errors={ props.errors }
                                inscription_errors={props.inscription_errors}
                                league_page = { props.league_page }
                                basic_data_info = { props.basic_data_info }
                                selected_tournament = { props.selected_tournament }
                                cities = { props.cities }
                                countries = {props.countries}
                                disableCities = {props.disableCities}
                                handleSearchNationality={ props.handleSearchNationality }
                                handleSearchWorldCities = { props.handleSearchWorldCities }
                                tournament_tag = { props.tournament_tag }
                                tournament_football_type={props.tournament_football_type}
                                editTournamentInfo = { props.editTournamentInfo }
                                stopEditTournamentInfo = { props.stopEditTournamentInfo }
                                selected_edit_tournament={props.selected_edit_tournament}
                                handleOpenEditFormModal={props.handleOpenEditFormModal}
                                handlebackToSummaryTournament={props.handlebackToSummaryTournament}
                                handleOpenConfirmationModal={props.handleOpenConfirmationModal}
                                fieldStatusEditing={props.fieldStatusEditing}
                            />
                            // The previous component was TournamentEditBasicDataComponent
                        } */}
                    </React.Fragment>
                )}

                

            </div>

        </div>

    )
    
}

function TournamentEditTournamentComponent ( props ) {

    const [tab_selected, setTabSelected] = useState(props.preSelectedTab? props.preSelectedTab : 0 );
    //const [tab_1_checked, setTab1Checked] = useState(false);
    //const [tab_2_checked, setTab2Checked] = useState(false);
    //const [tab_3_checked, setTab3Checked] = useState(false);
    //const [tab_4_checked, setTab4Checked] = useState(false);
    //const [enable_save_button, setEnableSaveButton] = useState(false);
    const [showPhasePositionTable, setShowPhasePositionTable] = useState(false);
    const [showLockedPhase, setShowLockedPhase] = useState(false);
    const [showLockedAddPhase, setShowLockedAddPhase] = useState(false);
    const [showLockedPhaseInPositionsTable, setShowLockedPhaseInPositionsTable] = useState(false);
    const [stateActiveTab, setStateActiveTab] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [nameActivePhase, setNameActivePhase] = useState('');

    /* eslint-disable */
    React.useEffect( () => {

        if(props.tournament_active_phase ){
            //console.log("use effect ejecuto el click de la fase");
            //handleOnClickPhaseTabInPositionsTable(props.tournament_active_phase); //optimizacion de apis
            //props.handleGroupClick(props.tournament_active_phase.groups[0]);
            //props.finishedMatchesByPhase(props.tournament_active_phase); //optimizacion de apis
        }

    }, [ JSON.stringify(props.tournament_active_phase)] );
    /* eslint-enable */ 
    
    /*const createNewTournament = (tournament_data) => {        
        props.createNewTournament(tournament_data);         
        setEnableSaveButton(true); 
        selectNextTab(0);
        setTab1Checked(true);     
    }*/
    React.useEffect( () => {
        if(props.tournament_active_phase?.is_last_active_phase){
            setNameActivePhase(props.tournament_active_phase?.id);
        }
    }, [props.tournament_active_phase, tab_selected] );

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if(props.date_matches?.length > 0 && !props.unfinished_matches && props.tournament_active_phase?.state === 'Active' && tab_selected === 0 && !props.is_phase_finalizing) {    
                props.handleVerifyMatches('end_phase');
            }
        }, 1000);
        return () => clearTimeout(timeout);        
    }, [props.date_matches, props.unfinished_matches]); // eslint-disable-line

    const createTeamsTournament = (tab_index) => {        
        selectNextTab(tab_index);
        //setTab2Checked(true);
    }

    const createMembersTournament = (tab_index) => {        
        selectNextTab(tab_index);
        //setTab3Checked(true);
    }

    /*const createFirstDateTournament = (tab_index) => {                
        setTab4Checked(true);
    }*/

    const selectNextTab = (tab_index) => {
        setTabSelected(tab_index + 1);
    }

    const handleBackButton = () => {                
        props.handleBackButton();
    }

    const handleEndTournamentModal = () => {                
        props.handleEndTournamentModal();
    }

    /*const handleEndTournament = () => {                
        props.handleEndTournamentModal();
    }*/

    const handleOnClickPhaseTab = async (phase, typeAction) => { 

        var index = props.tournament_phases.map(function(e) { return e.id; }).indexOf(phase.id);
        var phase_before_is_finalized = true;

        if(index > 0){
            phase_before_is_finalized = props.tournament_phases[index - 1].state === "Finalized";
        }
        
        setShowLockedPhase(!phase_before_is_finalized);

        setShowLockedAddPhase(props.tournament_phases != null && props.tournament_phases.length > 0 && props.tournament_phases[props.tournament_phases.length - 1].state === "Active");
        await props.handleOnClickPhaseTab(phase,  typeAction);     
        /* if(phase.groups[0]?.teams.length === 0 && props.tournament_phases[props.tournament_phases.length - 2]?.state === 'Finalized' && tab_selected === 0 && !props.teams_to_group) {
            props.handleVerifyMatches('start_phase');
        } */
       //Change new api phases
        if(props.tournament_active_phase?.groups[0]?.teams.length === 0 && props.tournament_phases[props.tournament_phases.length - 2]?.state === 'Finalized' && tab_selected === 0 && !props.teams_to_group) {
            props.handleVerifyMatches('start_phase');
        }
    }

    const handleOnClickPhaseTabInPositionsTable = (phase, typeAction) => {
        
        var index = props.tournament_phases.map(function(e) { return e.id; }).indexOf(phase.id);
        var phase_before_is_finalized = true;

        if(index > 0){
            phase_before_is_finalized = props.tournament_phases[index - 1].state === "Finalized";
        }
        
        setShowLockedPhaseInPositionsTable(!phase_before_is_finalized);
        props.handleOnClickPhaseTab(phase,typeAction);       
        props.handleOnClickPhaseTabInPositionsTable(phase);
        setShowPhasePositionTable(true);
    }

    const handleAddPhaseFromOtherTabThanPhases = () => {        
        setTabSelected(4);
    }

    const disabledTabs = (newState) => {
        setStateActiveTab(newState)
    }

    const changeTab = (new_value) => {
        
        if(props.tournament_phases.length > 0){
            
            if(props.tournament_phases[props.tournament_phases.length - 1].phase_type === null){
                props.deleteTournamentPhaseWihtOutType(props.selected_tournament.id, props.tournament_phases[props.tournament_phases.length - 1])
            }

            // if(props.tournament_phases[props.tournament_phases.length - 1]?.groups[0]?.teams.length === 0){
            //     props.deleteTournamentPhaseWihtOutType(props.selected_tournament.id, props.tournament_phases[props.tournament_phases.length - 1]);
            // }

        }

        if((new_value === 2 && props.selected_tournament.state !== 'Finalized') || (new_value === 3 && props.selected_tournament.state === 'Finalized') ){
            //se llama el api de positions_table para llenar la info de la pestaña de estadísticas
            if(new_value === 2){
                props.loadPhaseResultsTable(props.tournament_active_phase?.id);
            }else{
                props.loadPhaseResultsTable(props.tournament_phases[props.tournament_phases.length - 1].id);
            }
            
        }



        setTabSelected( new_value );
    }

    const getIndexPhaseActive = (activePhase) =>{
        return props.tournament_phases.findIndex(phase  => phase.id === activePhase.id) ?? 0
    }

    
    
    

    return(
        
        <div className='new-tournament__container'>
            <div className="tournament-edit-tournament__label"
                onClick={() => { handleBackButton() } }
            >   
                <span className="icon-arrow-left" />
                <div className="baloa-headline-2">{props.t('options.tournaments.pages.tournamentInfo.labelBack')}</div>
            </div> 
            {props.tournament?.tournament_photo &&
                <div className="new-tournament-img_container">
                    <img className="new-tournament__center-container-img" src={props.tournament?.tournament_photo} alt={props.tournament?.name} />
                </div>
            }
            <div className='new-tournament__image'>
                {props.tournament.tournament_photo?
                    <img src={props.tournament?.tournament_photo} alt={props.tournament?.name}/>// eslint-disable-line
                :
                    <span className={"icon-trophy"} color={getRandonIconBallColor()} ></span>
                }

                <div className="tournament-edit-tournament__tournament-info">
                    <div className="tournament-edit-tournament__tournament-info__labels">
                        <div className="tournament-edit-tournament__heder-label">{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${props.tournament.football_type}`)}</div>
                        <div className="tournament-edit-tournament__heder-label">{props.t(`options.tournaments.pages.newTournament.tabs.tabBasicData.${props.tournament.tournament_gender}`)}</div>
                        <div className="tournament-edit-tournament__heder-label">{props.tournament.category}</div>
                        <div className='tournament-edit-tournament__heder-label'>{props.tournament?.tournament_type? props.t('options.tournaments.pages.newTournament.tabs.tabBasicData.'+ props.tournament?.tournament_type):''}</div>
                    </div>
                    <SecondaryButtonComponent
                        className='tournament-edit-tournament__tournament-info__share-btn small'
                        input_type={'button'}
                        onClick={ ()=>{ props.handleOnShareTournamentLinkClick(props.tournament) } }
                    >
                        <span className='hubicon-share_v2'></span>
                        <span className="baloa-table">{props.t('options.share')} </span>
                    </SecondaryButtonComponent>
                </div>
            </div>
            {/* <div className="new-tournament"> */}
            <div className="new-tournament__center">
                <div className="tournament-edit-tournament__header">
                    <div className="left-col">
                        { !props.tournament.tournament_logo
                            ?
                            <span className="icon-ball" color={ getRandonIconBallColor() } />
                            :
                            <img alt="" src={props.tournament.tournament_logo} />
                        }
                        <div className="new-tournament__center__header__content">
                            <div className="new-tournament__center__header__content__title">
                                <div className="new-tournament__center__header__content__title__text">
                                    <div className="baloa-headline-2">{ props.selected_tournament.name}</div>
                                    {/* <div className="baloa-normal-text">{ `${props.selected_tournament.main_city}, ${props.selected_tournament.main_country} `} &nbsp;&bull;&nbsp; {` ${props.selected_tournament.start_date} - ${props.selected_tournament.end_date}` }</div>
                                    <div className="baloa-normal-text">{ props.selected_tournament.category}</div>                                     */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-col">
                        <div className="new-tournament__center__header__button">                        
                            <SecondaryButtonComponent 
                                type="button"
                                disabled={ 
                                        props.disabled_edit_tournament_data 
                                    ||                                    
                                        props.tournament_phases.length === 0
                                    ||
                                        (props.tournament_phases.find(phase => phase.state === "Active") !== undefined)
                                    ||
                                        props.endingTournament
                                }
                                onClick={() => { handleEndTournamentModal() } }                              
                            >
                                {props.endingTournament?
                                    <div className='new-tournament__center__header__spinner-container'>                                                
                                        <div className="span-newtournament__disabled" />
                                    </div>                                    
                                    :
                                    <span>{ props.disabled_edit_tournament_data 
                                        ? props.t('options.tournaments.pages.tournamentInfo.labelEndedTournament') 
                                        : props.t('options.tournaments.pages.tournamentInfo.labelEndTournament')
                                    }</span>
                                }
                                
                            </SecondaryButtonComponent>
                        </div>
                    </div>
                </div>

                <div className="edit-tournament__center__content">
                    <Tabs
                        variant="fullWidth"
                        className="new-tournament__center__content__tabs"
                        value={ tab_selected }
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={ ( ev, new_value ) => { changeTab( new_value ) } }
                        aria-label="Selection between "
                    >                           
                        {/* Tab podium */}
                        { props.selected_tournament.state === 'Finalized' &&
                            <Tab
                                label= {props.t('options.tournaments.pages.tournamentInfo.tabs.tabPodium.labelTitlePodium')}
                                onClick={props.resetPreSelectedTab}
                            />
                        }
                        {/* Tab calendar */}
                        <Tab className="tabs-icons_style_container"
                            // icon={ tab_1_checked ? <DoneIcon /> : "" } 
                            disabled={stateActiveTab} //tournamentInfo
                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabCalendar.labelTitle')}
                            onClick={props.resetPreSelectedTab}
                        />
                        
                        {/* Tab posiciones */}                        
                        <Tab 
                            // icon={ tab_2_checked ? <DoneIcon /> : "" }
                            disabled={
                                stateActiveTab 
                                || 
                                (props.tournament_phases.length === 1 && props.tournament_phases[props.tournament_phases.length - 1].phase_type === null ? true : false)
                            }
                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.labelTitle')}
                            onClick={props.resetPreSelectedTab}                                                        
                        /> 
                        
                        {/* Tab stats */}         
                        <Tab
                            disabled={stateActiveTab}
                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabStats.labelTitle')}
                            onClick={()=>{props.resetPreSelectedTab(); props.loadStatistics()}}                            
                        />
                        
                        
                        {/* Tab teams */}    
                        <Tab 
                            // icon={ tab_2_checked ? <DoneIcon /> : "" }
                            disabled={stateActiveTab}
                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabTeams.labelTitle')}
                            onClick={props.resetPreSelectedTab}                             
                        />
                        

                        {/*  props.selected_tournament.state === 'Finalized' && 
                            <Tab                             
                                label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelTitle')}
                                disabled={stateActiveTab}
                                onClick={props.resetPreSelectedTab}
                            /> */
                        }

                        {/* Tab phases */}                       
                        <Tab 
                            disabled={stateActiveTab}
                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabPhases.labelTitle')}
                            onClick={props.resetPreSelectedTab}                            
                        /> 

                        {/* Tab staf */}        
                        <Tab
                            // icon={ tab_3_checked ? <DoneIcon /> : "" } 
                            disabled={stateActiveTab}
                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabMembers.labelTitle')}
                            onClick={props.resetPreSelectedTab}                            
                        />    
                            
                        {/* Tab info */}          
                        <Tab                             
                            label={props.t('options.tournaments.pages.tournamentInfo.tabs.tabBasicData.labelTitle')}
                            disabled={stateActiveTab}
                            onClick={props.resetPreSelectedTab}
                        />
                        
                    </Tabs>                    

                    {/* tab podium */}
                    { props.selected_tournament.state === 'Finalized' &&
                        <TabPanel
                            index={ 0 } 
                            value={ tab_selected } 
                            onResetError={(data) => {props.onResetError(data)}} 
                            errors={props.errors}
                            errorsNewGroupTournament={props.errorsNewGroupTournament}
                            from_data={props.from_data}
                            to_data={props.to_data}
                            tournament_dates = { props.tournament_dates }                        
                            handleNewTournamentClick={ ( ) => { props.handleNewTournamentClick() } }
                            selectedDate= { props.selectedDate }
                            handleDateClick={ ( v ) => { props.handleDateClick( v ) } }
                            date_matches={ props.date_matches }
                            createTeamsTournament = { createTeamsTournament }
                            disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                            selected_tournament = { props.selected_tournament }
                            phase_teams={props.phase_teams}
                            tournament_teams={props.tournament_teams}
                            tournament_members={props.tournament_members}
                            tournament_results_table = { props.tournament_results_table }
                            // = { props.tournament_results_table }
                            viewMatchReport ={ ( v ) => { props.viewMatchReport( v ) } }
                            handleEndMatchTournament = { ( v ) => { props.handleEndMatchTournament( v ) } }
                            handleEditMatchTournament = { ( v ) => { props.handleEditMatchTournament( v ) } }
                            handleNewMatchTournament = { ( v ) => { props.handleNewMatchTournament( v ) } }
                            enable_add_match_button = { props.enable_add_match_button }                    
                            handleDeleteMatchTournament = { ( v ) => { props.handleDeleteMatchTournament( v ) } }   
                            handleOnFinishMatchActionClick = { ( v ) => { props.handleOnFinishMatchActionClick( v ) } } 
                            league_teams={ props.league_teams }
                            tournament_name = { props.tournament_name }                        
                            tournament_phases={ props.tournament_phases }
                            handleAddTeamToGroup = { props.handleAddTeamToGroup }
                            handleDeleteTeamFromGroup = { props.handleDeleteTeamFromGroup }
                            handleActivePhaseCreateState = { props.handleActivePhaseCreateState }
                            handleGroupClick={ ( v, tab_click ) => { props.handleGroupClick( v, tab_click ) } }
                            groups = { props.groups } 
                            handleOnClickPhaseTab = { handleOnClickPhaseTab }
                            handleOnClickPhaseTabInPositionsTable = { handleOnClickPhaseTabInPositionsTable }
                            showPhasePositionTable = { showPhasePositionTable }
                            //addTournamentPhase = {(id_tournament) => {props.addTournamentPhase(id_tournament)}}
                            tournament_active_phase={ props.tournament_active_phase }
                            edit_tournament={props.edit_tournament}                        
                            createNewGroupPhases = { props.createNewGroupPhases }                        
                            deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}}
                            handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}} 
                            teams_classified_by_phases={props.teams_classified_by_phases}   
                            showLockedPhase = { showLockedPhase } 
                            showLockedPhaseInPositionsTable = { showLockedPhaseInPositionsTable }  
                            showLockedAddPhase = { showLockedAddPhase }
                            is_loaded_tournament_phases={props.is_loaded_tournament_phases}
                            is_loaded_tournament_dates={props.is_loaded_tournament_dates}
                            handleAddPhaseFromOtherTabThanPhases = { handleAddPhaseFromOtherTabThanPhases }
                            finished_last_phase_with_one_clasified = { props.finished_last_phase_with_one_clasified }
                            disabledTabs= { (v)=>disabledTabs(v) }
                            t={props.t}
                            date_matches_is_loading = { props.date_matches_is_loading }
                            AddTeamToGroupModal = {() => props.AddTeamToGroupModal()}
                            handleOnProfileClick = { props.handleOnProfileClick }
                            handleOnPageClick = { props.handleOnPageClick }
                            deleteDate={ props.deleteDate }
                            handleTournamentFinalizedSuggestion={props.handleTournamentFinalizedSuggestion}
                            is_tournament_finalized = { props.is_tournament_finalized }
                            getIndexPhaseActive={getIndexPhaseActive}
                        />
                    }

                    {/* tab calendar */}
                    <TabPanel 
                        index={ props.selected_tournament.state === 'Finalized'? 1 : 0 } 
                        value={ tab_selected } 
                        onResetError={(data) => {props.onResetError(data)}} 
                        errors={props.errors}
                        errorsNewGroupTournament={props.errorsNewGroupTournament}
                        from_data={props.from_data}
                        to_data={props.to_data}
                        tournament_dates = { props.tournament_dates }                        
                        handleNewDateTournamentClick={ props.handleNewDateTournamentClick }
                        selectedDate= { props.selectedDate }
                        handleDateClick={ ( v ) => { props.handleDateClick( v ) } }
                        date_matches={ props.date_matches }
                        createTeamsTournament = { createTeamsTournament }
                        disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                        selected_tournament = { props.selected_tournament }
                        phase_teams={props.phase_teams}
                        tournament_teams={props.tournament_teams}
                        tournament_members={props.tournament_members}
                        tournament_results_table = { props.tournament_results_table }
                        // = { props.tournament_results_table }
                        viewMatchReport ={ ( v ) => { props.viewMatchReport( v ) } }
                        handleEndMatchTournament = { ( v ) => { props.handleEndMatchTournament( v ) } }
                        handleEditMatchTournament = { ( v ) => { props.handleEditMatchTournament( v ) } }
                        handleNewMatchTournament = { ( v ) => { props.handleNewMatchTournament( v ) } }
                        enable_add_match_button = { props.enable_add_match_button }                    
                        handleDeleteMatchTournament = { ( v ) => { props.handleDeleteMatchTournament( v ) } }   
                        handleOnFinishMatchActionClick = { ( v ) => { props.handleOnFinishMatchActionClick( v ) } } 
                        league_teams={ props.league_teams }
                        tournament_name = { props.tournament_name }                        
                        tournament_phases={ props.tournament_phases }
                        handleAddTeamToGroup = { props.handleAddTeamToGroup }
                        handleDeleteTeamFromGroup = { props.handleDeleteTeamFromGroup }
                        handleActivePhaseCreateState = { props.handleActivePhaseCreateState }
                        handleGroupClick={ ( v, tab_click ) => { props.handleGroupClick( v, tab_click ) } }
                        groups = { props.groups } 
                        handleOnClickPhaseTab = { handleOnClickPhaseTab } // 'tabCalendar'
                        handleOnClickPhaseTabInPositionsTable = { handleOnClickPhaseTabInPositionsTable }
                        showPhasePositionTable = { showPhasePositionTable }
                        //addTournamentPhase = {(id_tournament) => {props.addTournamentPhase(id_tournament)}}
                        tournament_active_phase={ props.tournament_active_phase }
                        edit_tournament={props.edit_tournament}                        
                        createNewGroupPhases = { props.createNewGroupPhases }                        
                        deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}}
                        handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}} 
                        teams_classified_by_phases={props.teams_classified_by_phases}   
                        showLockedPhase = { showLockedPhase } 
                        showLockedPhaseInPositionsTable = { showLockedPhaseInPositionsTable }  
                        showLockedAddPhase = { showLockedAddPhase }
                        is_loaded_tournament_phases={props.is_loaded_tournament_phases}
                        is_loaded_tournament_dates={props.is_loaded_tournament_dates}
                        handleAddPhaseFromOtherTabThanPhases = { handleAddPhaseFromOtherTabThanPhases }
                        finished_last_phase_with_one_clasified = { props.finished_last_phase_with_one_clasified }
                        disabledTabs= { (v)=>disabledTabs(v) }
                        t={props.t}
                        handleLeavingPhaseTab = {() => {props.handleLeavingPhaseTab()}}
                        date_matches_is_loading = { props.date_matches_is_loading }
                        AddTeamToGroupModal = {() => props.AddTeamToGroupModal()}
                        finishedMatchesByPhase = { (phase) => {props.finishedMatchesByPhase(phase)} }
                        unfinished_matches = {props.unfinished_matches}
                        //deleteDate={(date,index) => props.deleteDate(date,index)}
                        deleteDate={ props.deleteDate }
                        handleTournamentFinalizedSuggestion={props.handleTournamentFinalizedSuggestion}
                        is_tournament_finalized = { props.is_tournament_finalized }                            
                        handleOnAutomaticFixture = { props.handleOnAutomaticFixture }
                        fixture_button_active = { props.fixture_button_active }
                        pageRoles = { props.pageRoles }
                        nameActivePhase={nameActivePhase}
                        referees_payment_params={props.referees_payment_params}
                        handleOnClickNewPhase={props.handleOnClickNewPhase}
                        staff={props.staff}
                        changeTab={ (new_value) => changeTab(new_value) }
                        handleTournamentFieldsAdmin={ props.handleTournamentFieldsAdmin }
                        tournament_fields={ props.tournament_fields }
                        handleTournamentPhaseMatchFilters={ props.handleTournamentPhaseMatchFilters }
                        handleSubmitEditInfoMatch={ props.handleSubmitEditInfoMatch }
                        cities = { props.cities }
                        new_page_field={ props.new_page_field }
                        handleFieldsAdminActions={ props.handleFieldsAdminActions }
                        handleTournamentFieldActions={ props.handleTournamentFieldActions }
                        tournament_selected_date={ props.tournament_selected_date }
                        handleTournamentPhaseDaysFilters={ props.handleTournamentPhaseDaysFilters }
                        tournament_dates_filter={ props.tournament_dates_filter }
                        handleAddStaf= { props.handleAddStaf }
                        language_code =  {props.language_code }
                        list_phase_teams={props.list_phase_teams}  
                        handleDownloadMatchReport={ props.handleDownloadMatchReport }
                        tournament_staff={ props.tournament_staff }

                        next_date_matches={props.next_date_matches}
                        handleViewMoreMatches={props.handleViewMoreMatches}

                        editMatchMainInfo={ props.editMatchMainInfo }
                    />
                    
                            
                    {/* tab table */}
                    <TabPanel 
                        index={ props.selected_tournament.state === 'Finalized'? 2 : 1 } 
                        value={ tab_selected } 
                        onResetError={(data) => {props.onResetError(data)}}
                        errors={ props.errors }
                        errorsNewGroupTournament={props.errorsNewGroupTournament}
                        league_teams={ props.league_teams }
                        phase_teams={ props.phase_teams }
                        tournament_teams={props.tournament_teams}
                        handleOnAddTeamToTournament={  props.handleOnAddTeamToTournament }
                        handleOnDeleteTeamFromTournament={  props.handleOnDeleteTeamFromTournament }
                        handleOnDisableTeamFromTournament = { props.handleOnDisableTeamFromTournament }
                        tournament_dates = { props.tournament_dates }
                        selectedDate= { props.selectedDate }
                        handleDateClick={ ( v ) => { props.handleDateClick( v ) } }
                        createTeamsTournament = { createTeamsTournament }
                        disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                        selected_tournament = { props.selected_tournament }                        
                        tournament_members={ props.tournament_members }
                        tournament_results_table = { props.tournament_results_table }
                        viewMatchReport ={ ( v ) => { props.viewMatchReport( v ) } }
                        handleEndMatchTournament = { ( v ) => { props.handleEndMatchTournament( v ) } }
                        handleEditMatchTournament = { ( v ) => { props.handleEditMatchTournament( v ) } }
                        handleNewMatchTournament = { ( v ) => { props.handleNewMatchTournament( v ) } }
                        enable_add_match_button = { props.enable_add_match_button }
                        handleDeleteMatchTournament = { ( v ) => { props.handleDeleteMatchTournament( v ) } }    
                        handleOnFinishMatchActionClick = { ( v ) => { props.handleOnFinishMatchActionClick( v ) } }
                        tournament_name = { props.tournament_name }
                        tournament_phases={ props.tournament_phases }
                        handleAddTeamToGroup = { props.handleAddTeamToGroup }
                        handleDeleteTeamFromGroup = { props.handleDeleteTeamFromGroup }
                        handleActivePhaseCreateState = { props.handleActivePhaseCreateState }
                        handleGroupClick={ ( v, tab_click ) => { props.handleGroupClick( v, tab_click ) } }
                        //addTournamentPhase = {(id_tournament) => {props.addTournamentPhase(id_tournament)}}
                        groups = { props.groups } 
                        handleOnClickPhaseTab = { handleOnClickPhaseTab }
                        handleOnClickPhaseTabInPositionsTable = { handleOnClickPhaseTabInPositionsTable }
                        showPhasePositionTable = { showPhasePositionTable }
                        tournament_active_phase={ props.tournament_active_phase }
                        edit_tournament={props.edit_tournament}
                        createNewGroupPhases = { props.createNewGroupPhases }                        
                        handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}}                                     
                        deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}}  
                        teams_classified_by_phases={props.teams_classified_by_phases}   
                        showLockedPhase = { showLockedPhase }
                        showLockedPhaseInPositionsTable = { showLockedPhaseInPositionsTable }  
                        showLockedAddPhase = { showLockedAddPhase }  
                        is_loaded_tournament_phases={props.is_loaded_tournament_phases}
                        is_loaded_tournament_dates={props.is_loaded_tournament_dates}  
                        handleAddPhaseFromOtherTabThanPhases = { handleAddPhaseFromOtherTabThanPhases }   
                        finished_last_phase_with_one_clasified = { props.finished_last_phase_with_one_clasified }    
                        disabledTabs= { (v)=>disabledTabs(v) } 
                        t={props.t}          
                        handleLeavingPhaseTab = {() => {props.handleLeavingPhaseTab()}}    
                        date_matches_is_loading = { props.date_matches_is_loading }  
                        AddTeamToGroupModal = {() => props.AddTeamToGroupModal()}        
                        finishedMatchesByPhase = { (phase) => {props.finishedMatchesByPhase(phase)} }  
                        unfinished_matches = {props.unfinished_matches}
                        date_matches = { props.date_matches }
                        //deleteDate={(date,index) => props.deleteDate(date,index)}
                        deleteDate={ props.deleteDate }
                        handleTournamentFinalizedSuggestion={ props.handleTournamentFinalizedSuggestion } 
                        is_best_third={props.is_best_third}                       
                        handleOpenShareIframeLinkModal={props.handleOpenShareIframeLinkModal}
                        handleOnClickNewPhase={props.handleOnClickNewPhase}

                        phase_groups_results_table={ props.phase_groups_results_table }
                        handleOnPhaseGroupPositionTable={ props.handleOnPhaseGroupPositionTable }
                        loading_phase_group_positions_table={ props.loading_phase_group_positions_table }
                    />

                    {/* tab Stats */}                        
                    <TabPanel 
                        index={ props.selected_tournament.state === 'Finalized'? 3 : 2 } 
                        value={ tab_selected } 
                        tournament_results_table = { props.tournament_results_table }
                        tournament_scorers = { props.tournament_scorers }
                        tournament_yellow_cards = { props.tournament_yellow_cards }
                        tournament_red_cards = { props.tournament_red_cards }
                        tournament_blue_cards = { props.tournament_blue_cards }
                        tournament_assists = { props.tournament_assists }
                        tournament_least_defeated_fence = { props.tournament_least_defeated_fence }
                        tournament_teams_most_goals = { props.tournament_teams_most_goals }
                        tournament_summary = { props.tournament_summary }
                        loading_tournament_scorers = { props.loading_tournament_scorers }
                        loading_tournament_yellow_cards = { props.loading_tournament_yellow_cards }
                        loading_tournament_red_cards = { props.loading_tournament_red_cards }
                        loading_tournament_blue_cards = { props.loading_tournament_blue_cards }
                        loading_tournament_assists = { props.loading_tournament_assists }
                        loading_tournament_least_defeated_fence = { props.loading_tournament_least_defeated_fence }
                        loading_tournament_teams_most_goals = { props.loading_tournament_teams_most_goals }
                        loading_tournament_summary = { props.loading_tournament_summary }
                        next_tournament_scorers = { props.next_tournament_scorers }
                        next_tournament_yellow_cards = { props.next_tournament_yellow_cards }
                        next_tournament_red_cards = { props.next_tournament_red_cards }
                        next_tournament_blue_cards = { props.next_tournament_blue_cards }
                        next_tournament_assists = { props.next_tournament_assists }
                        next_tournament_least_defeated_fence = { props.next_tournament_least_defeated_fence }
                        next_tournament_teams_most_goals = { props.next_tournament_teams_most_goals }
                        handleOnViewMoreStatistics = { props.handleOnViewMoreStatistics }
                        selected_tournament = { props.selected_tournament }
                    />
                        

                    {/* tab basic data ended tournament */}
                    {/* errors not necesary but mandatory */}
                    { /* props.selected_tournament.state === 'Finalized' &&                    
                        <TabPanel 
                            index={ 2 } 
                            value={ tab_selected } 
                            onResetError={(data) => {props.onResetError(data)}} 
                            errors={props.errors}
                            errorsNewGroupTournament={props.errorsNewGroupTournament}
                            from_data={props.from_data}
                            to_data={props.to_data}
                            tournament_dates = { props.tournament_dates } 
                            phase_teams={props.phase_teams}
                            tournament_teams={props.tournament_teams}
                            tournament_members={props.tournament_members}                    
                            handleNewDateTournamentClick={ ( ) => { props.handleNewDateTournamentClick() } }
                            selectedDate= { props.selectedDate }
                            handleDateClick={ ( v ) => { props.handleDateClick( v ) } }
                            date_matches={ props.date_matches }
                            createTeamsTournament = { createTeamsTournament }
                            disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                            selected_tournament = { props.selected_tournament }
                            tournament_results_table = { props.tournament_results_table }
                            viewMatchReport ={ ( v ) => { props.viewMatchReport( v ) } }
                            handleEndMatchTournament = { ( v ) => { props.handleEndMatchTournament( v ) } }
                            handleEditMatchTournament = { ( v ) => { props.handleEditMatchTournament( v ) } }
                            handleNewMatchTournament = { ( v ) => { props.handleNewMatchTournament( v ) } }
                            enable_add_match_button = { props.enable_add_match_button }
                            handleDeleteMatchTournament = { ( v ) => { props.handleDeleteMatchTournament( v ) } }    
                            handleOnFinishMatchActionClick = { ( v ) => { props.handleOnFinishMatchActionClick( v ) } }
                            league_teams={ props.league_teams }
                            tournament_name = { props.tournament_name }                        
                            tournament_phases={ props.tournament_phases }
                            handleAddTeamToGroup = { props.handleAddTeamToGroup }
                            handleDeleteTeamFromGroup = { props.handleDeleteTeamFromGroup }
                            handleActivePhaseCreateState = { props.handleActivePhaseCreateState }
                            handleGroupClick={ ( v, tab_click ) => { props.handleGroupClick( v, tab_click ) } }
                            //addTournamentPhase = {(id_tournament) => {props.addTournamentPhase(id_tournament)}}
                            groups = { props.groups } 
                            handleOnClickPhaseTab = { handleOnClickPhaseTab }
                            handleOnClickPhaseTabInPositionsTable = { handleOnClickPhaseTabInPositionsTable }
                            showPhasePositionTable = { showPhasePositionTable }
                            tournament_active_phase={ props.tournament_active_phase }
                            edit_tournament={props.edit_tournament}
                            createNewGroupPhases = { props.createNewGroupPhases }                        
                            deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}}
                            handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}}   
                            teams_classified_by_phases={props.teams_classified_by_phases}   
                            showLockedPhase = { showLockedPhase }                         
                            showLockedPhaseInPositionsTable = { showLockedPhaseInPositionsTable }
                            showLockedAddPhase = { showLockedAddPhase }
                            is_loaded_tournament_phases={props.is_loaded_tournament_phases}
                            is_loaded_tournament_dates={props.is_loaded_tournament_dates}
                            handleAddPhaseFromOtherTabThanPhases = { handleAddPhaseFromOtherTabThanPhases }
                            finished_last_phase_with_one_clasified = { props.finished_last_phase_with_one_clasified }
                            disabledTabs= { (v)=>disabledTabs(v) }
                            t={props.t}
                            handleLeavingPhaseTab = {() => {props.handleLeavingPhaseTab()}}
                            date_matches_is_loading = { props.date_matches_is_loading }
                            AddTeamToGroupModal = {() => props.AddTeamToGroupModal()}
                            deleteDate={(date,index) => props.deleteDate(date,index)}
                            handleTournamentFinalizedSuggestion={props.handleTournamentFinalizedSuggestion}

                        /> */
                    }

                    {/* tab team */}
                    {/* errors not necesary but mandatory */}
                    <TabPanel 
                        index={ props.selected_tournament.state === 'Finalized'? 4 : 3 } 
                        value={ tab_selected } 
                        onResetError={(data) => {props.onResetError(data)}}
                        errors={props.errors}
                        errorsNewGroupTournament={props.errorsNewGroupTournament}
                        league_teams={props.league_teams}
                        phase_teams={props.phase_teams}
                        tournament_teams={props.tournament_teams}
                        tournament_members={props.tournament_members}
                        handleOnAddTeamToTournament={  props.handleOnAddTeamToTournament }
                        handleOnDeleteTeamFromTournament={  props.handleOnDeleteTeamFromTournament }
                        handleOnDisableTeamFromTournament = { props.handleOnDisableTeamFromTournament }
                        tournament_dates = { props.tournament_dates }
                        selectedDate= { props.selectedDate }
                        handleDateClick={ ( v ) => { props.handleDateClick( v ) } }
                        createTeamsTournament = { createTeamsTournament }
                        disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                        selected_tournament = { props.selected_tournament }
                        tournament_results_table = { props.tournament_results_table }
                        viewMatchReport ={ ( v ) => { props.viewMatchReport( v ) } }
                        handleEndMatchTournament = { ( v ) => { props.handleEndMatchTournament( v ) } }
                        handleEditMatchTournament = { ( v ) => { props.handleEditMatchTournament( v ) } }
                        handleNewMatchTournament = { ( v ) => { props.handleNewMatchTournament( v ) } }
                        enable_add_match_button = { props.enable_add_match_button }
                        handleDeleteMatchTournament = { ( v ) => { props.handleDeleteMatchTournament( v ) } }  
                        handleOnFinishMatchActionClick = { ( v ) => { props.handleOnFinishMatchActionClick( v ) } }  
                        tournament_name = { props.tournament_name }                        
                        tournament_phases={ props.tournament_phases }
                        handleAddTeamToGroup = { props.handleAddTeamToGroup }
                        handleDeleteTeamFromGroup = { props.handleDeleteTeamFromGroup }
                        handleActivePhaseCreateState = { props.handleActivePhaseCreateState }
                        handleGroupClick={ ( v, tab_click ) => { props.handleGroupClick( v, tab_click ) } }
                        //addTournamentPhase = {(id_tournament) => {props.addTournamentPhase(id_tournament)}}
                        groups = { props.groups } 
                        handleOnClickPhaseTab = { handleOnClickPhaseTab }
                        handleOnClickPhaseTabInPositionsTable = { handleOnClickPhaseTabInPositionsTable }
                        showPhasePositionTable = { showPhasePositionTable }
                        tournament_active_phase={ props.tournament_active_phase }
                        edit_tournament={props.edit_tournament}
                        createNewGroupPhases = { props.createNewGroupPhases }                        
                        handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}}                                     
                        deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}}
                        teams_classified_by_phases={props.teams_classified_by_phases}  
                        showLockedPhase = { showLockedPhase }
                        showLockedPhaseInPositionsTable = { showLockedPhaseInPositionsTable } 
                        showLockedAddPhase = { showLockedAddPhase }    
                        is_loaded_tournament_phases={props.is_loaded_tournament_phases}
                        is_loaded_tournament_dates={props.is_loaded_tournament_dates}   
                        handleAddPhaseFromOtherTabThanPhases = { handleAddPhaseFromOtherTabThanPhases }   
                        finished_last_phase_with_one_clasified = { props.finished_last_phase_with_one_clasified }      
                        disabledTabs= { (v)=>disabledTabs(v) }
                        t={props.t}
                        handleLeavingPhaseTab = {() => {props.handleLeavingPhaseTab()}}
                        date_matches_is_loading = { props.date_matches_is_loading }
                        AddTeamToGroupModal = {() => props.AddTeamToGroupModal()}
                        //deleteDate={(date,index) => props.deleteDate(date,index)}
                        deleteDate={ props.deleteDate }
                        handleTournamentFinalizedSuggestion={props.handleTournamentFinalizedSuggestion}
                        handleSearchBaloaTeam = { props.handleSearchBaloaTeam }
                        baloaTeams = { props.baloaTeams }
                        handleCleanBaloaTeamsSearch = { props.handleCleanBaloaTeamsSearch }
                        pageRoles = { props.pageRoles }
                        tournament={props.tournament}
                        tournament_setup_teams={props.tournament_setup_teams}
                        handleTeamInfo={props.handleTeamInfo}
                        handleDisableTeam={props.handleDisableTeam}
                        handleInscriptionInvitationModalActions={props.handleInscriptionInvitationModalActions}
                        handleOnInviteTeamModal={props.handleOnInviteTeamModal}
                        handleOnValidateTeamInscriptionModal={ props.handleOnValidateTeamInscriptionModal }
                        handleOnDownloadForms= { props.handleOnDownloadForms }
                        handleOnDownloadPayments={ props.handleOnDownloadPayments }
                        
                        //CardIds
                        handleLoadformPlayer = {props.handleLoadformPlayer}
                        sendCarnet = {props.sendCarnet}
                        GetCodeFormTemplate = {props.GetCodeFormTemplate}
                        card_id_template={props.card_id_template}
                        deleteCardIdTemplate={props.deleteCardIdTemplate}
                        handleOnDeleteCardIdTemplate={props.handleOnDeleteCardIdTemplate}
                        handleOnDownloadCardIds={props.handleOnDownloadCardIds}

                        next_tournament_teams={props.next_tournament_teams}
                        loadMoreTeams={props.loadMoreTeams}
                        tournament_teams_loading={props.tournament_teams_loading}
                        tournament_teams_count={props.tournament_teams_count}
                        loadInfoFormModal={props.loadInfoFormModal}
                        handleTeamPlayerPaymentInfo={ props.handleTeamPlayerPaymentInfo }

                        handleOnSearchTeams={props.handleOnSearchTeams}
                    />
                    
                    {/* tab phases */}
                    {/* errors not necesary but mandatory */}
                    <TabPanel 
                        index={ props.selected_tournament.state === 'Finalized'? 5 : 4 } 
                        value={ tab_selected } 
                        onResetError={(data) => {props.onResetError(data)}}
                        errors={props.errors}
                        errorsNewGroupTournament={props.errorsNewGroupTournament}
                        league_members={props.league_members}                        
                        phase_teams={props.phase_teams}
                        tournament_teams={props.tournament_teams}
                        tournament_members={props.tournament_members}
                        handleOnAddMemberToTournament={ props.handleOnAddMemberToTournament }
                        handleOnDeleteMemberFromTournament={ props.handleOnDeleteMemberFromTournament }
                        tournament_dates = { props.tournament_dates }
                        selectedDate= { props.selectedDate }
                        handleDateClick={ ( v ) => { props.handleDateClick( v ) } }
                        createTeamsTournament = { createMembersTournament }
                        disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                        selected_tournament = { props.selected_tournament }
                        tournament_results_table = { props.tournament_results_table }
                        viewMatchReport ={ ( v ) => { props.viewMatchReport( v ) } }
                        handleEndMatchTournament = { ( v ) => { props.handleEndMatchTournament( v ) } }
                        handleEditMatchTournament = { ( v ) => { props.handleEditMatchTournament( v ) } }
                        handleNewMatchTournament = { ( v ) => { props.handleNewMatchTournament( v ) } }
                        enable_add_match_button = { props.enable_add_match_button }
                        handleDeleteMatchTournament = { ( v ) => { props.handleDeleteMatchTournament( v ) } } 
                        handleOnFinishMatchActionClick = { ( v ) => { props.handleOnFinishMatchActionClick( v ) } }  
                        league_teams={ props.league_teams } 
                        tournament_name = { props.tournament_name }                        
                        selectNextTab = { selectNextTab }
                        tabval = { 5 }
                        tournament_phases={ props.tournament_phases }
                        tournament_active_phase={ props.tournament_active_phase }
                        handleAddTeamToGroup = { props.handleAddTeamToGroup }
                        handleDeleteTeamFromGroup = { props.handleDeleteTeamFromGroup }
                        active_phase_groups_create = { props.active_phase_groups_create }
                        handleActivePhaseCreateState = { props.handleActivePhaseCreateState }
                        handleGroupClick={ ( v, tab_click ) => { props.handleGroupClick( v, tab_click ) } }
                        addTournamentPhase = { props.addTournamentPhase }
                        groups = { props.groups } 
                        handleOnClickPhaseTab = { handleOnClickPhaseTab }
                        handleOnClickPhaseTabInPositionsTable = { handleOnClickPhaseTabInPositionsTable }
                        showPhasePositionTable = { showPhasePositionTable }
                        edit_tournament={props.edit_tournament}
                        createNewGroupPhases = { props.createNewGroupPhases }                        
                        handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}}                                     
                        deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}} 
                        teams_classified_by_phases={props.teams_classified_by_phases}  
                        showLockedPhase = { showLockedPhase } 
                        showLockedPhaseInPositionsTable = { showLockedPhaseInPositionsTable } 
                        showLockedAddPhase = { showLockedAddPhase }  
                        is_loaded_tournament_phases={props.is_loaded_tournament_phases} 
                        is_loaded_tournament_dates={props.is_loaded_tournament_dates}                                                           
                        handleAddPhaseFromOtherTabThanPhases = { handleAddPhaseFromOtherTabThanPhases }
                        finished_last_phase_with_one_clasified = { props.finished_last_phase_with_one_clasified }
                        disabledTabs= { (v)=>disabledTabs(v) }
                        t={props.t}
                        handleLeavingPhaseTab = {() => {props.handleLeavingPhaseTab()}}
                        date_matches_is_loading = { props.date_matches_is_loading }  
                        AddTeamToGroupModal = {() => props.AddTeamToGroupModal()}                      
                        date_matches = { props.date_matches }

                        finishedMatchesByPhase = { (phase) => {props.finishedMatchesByPhase(phase)} }
                        unfinished_matches = {props?.unfinished_matches}
                        //deleteDate={(date,index) => props.deleteDate(date,index)}
                        deleteDate={ props.deleteDate }
                        phase_name_values = { props.phase_name_values }
                        handleTournamentFinalizedSuggestion={props.handleTournamentFinalizedSuggestion}                        
                        is_tournament_finalized = { props.is_tournament_finalized }
                        deleteTournamentPhaseWihtOutType = { props.deleteTournamentPhaseWihtOutType }
                        pageRoles = { props.pageRoles }
                        handleCalendarStep={ () => setTabSelected(0) }
                        tournament_criteria_tie_breaking= {props.tournament_criteria_tie_breaking}
                        tournament_new_order_criteria_tie_breaking= {props.tournament_new_order_criteria_tie_breaking}
                        handle_team_filter= {props.handle_team_filter}
                        filter_phase_teams={props.filter_phase_teams}
                        handleBestThirdByGroup={props.handleBestThirdByGroup}
                        loadTieBreakerByPhase={props.loadTieBreakerByPhase}
                        handleEditPhase={props.handleEditPhase}
                        handleMoveToGroup={props.handleMoveToGroup}
                        handleTeamGroupPhaseknockout={props.handleTeamGroupPhaseknockout}
                        nameActivePhase={nameActivePhase}
                        not_assigned_teams={props.not_assigned_teams}

                        handleOnEditPhaseModal={props.handleOnEditPhaseModal}
                        handleOnClickNewPhase={props.handleOnClickNewPhase}
                        handleDeleteGroupPhase={props.handleDeleteGroupPhase}
                        handleNumberClassified={props.handleNumberClassified}
                        deleteTeamFromCreatePhase={props.deleteTeamFromCreatePhase}
                        teams_filter_type={props.teams_filter_type}
                    />

                    {/* tab members */}
                    {/* errors not necesary but mandatory */}
                    <TabPanel 
                        index={ props.selected_tournament.state === 'Finalized'? 6 : 5 } 
                        value={ tab_selected } 
                        onResetError={(data) => {props.onResetError(data)}}
                        errors={props.errors}
                        errorsNewGroupTournament={props.errorsNewGroupTournament}
                        league_members={props.league_members}                        
                        phase_teams={props.phase_teams}
                        tournament_teams={props.tournament_teams}
                        tournament_members={props.tournament_members}
                        handleOnAddMemberToTournament={ props.handleOnAddMemberToTournament }
                        handleOnDeleteMemberFromTournament={ props.handleOnDeleteMemberFromTournament }
                        tournament_dates = { props.tournament_dates }
                        selectedDate= { props.selectedDate }
                        handleDateClick={ ( v ) => { props.handleDateClick( v ) } }
                        createTeamsTournament = { createMembersTournament }
                        disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                        selected_tournament = { props.selected_tournament }
                        tournament_results_table = { props.tournament_results_table }
                        viewMatchReport ={ ( v ) => { props.viewMatchReport( v ) } }
                        handleEndMatchTournament = { ( v ) => { props.handleEndMatchTournament( v ) } }
                        handleEditMatchTournament = { ( v ) => { props.handleEditMatchTournament( v ) } }
                        handleNewMatchTournament = { ( v ) => { props.handleNewMatchTournament( v ) } }
                        enable_add_match_button = { props.enable_add_match_button }
                        handleDeleteMatchTournament = { ( v ) => { props.handleDeleteMatchTournament( v ) } } 
                        handleOnFinishMatchActionClick = { ( v ) => { props.handleOnFinishMatchActionClick( v ) } }  
                        league_teams={ props.league_teams } 
                        tournament_name = { props.tournament_name }                        
                        tournament_phases={ props.tournament_phases }
                        handleAddTeamToGroup = { props.handleAddTeamToGroup }
                        handleDeleteTeamFromGroup = { props.handleDeleteTeamFromGroup }
                        handleActivePhaseCreateState = { props.handleActivePhaseCreateState }
                        handleGroupClick={ ( v, tab_click ) => { props.handleGroupClick( v, tab_click ) } }
                        //addTournamentPhase = {(id_tournament) => {props.addTournamentPhase(id_tournament)}}
                        groups = { props.groups } 
                        handleOnClickPhaseTab = { handleOnClickPhaseTab }
                        handleOnClickPhaseTabInPositionsTable = { handleOnClickPhaseTabInPositionsTable }
                        showPhasePositionTable = { showPhasePositionTable }
                        tournament_active_phase={ props.tournament_active_phase }
                        edit_tournament={props.edit_tournament}
                        createNewGroupPhases = { props.createNewGroupPhases }                        
                        handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}}                                     
                        deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}}  
                        teams_classified_by_phases={props.teams_classified_by_phases} 
                        showLockedPhase = { showLockedPhase }
                        showLockedPhaseInPositionsTable = { showLockedPhaseInPositionsTable }  
                        showLockedAddPhase = { showLockedAddPhase } 
                        is_loaded_tournament_phases={props.is_loaded_tournament_phases}  
                        is_loaded_tournament_dates={props.is_loaded_tournament_dates}
                        handleAddPhaseFromOtherTabThanPhases = { handleAddPhaseFromOtherTabThanPhases }  
                        finished_last_phase_with_one_clasified = { props.finished_last_phase_with_one_clasified }    
                        disabledTabs= { (v)=>disabledTabs(v) }  
                        t={props.t}    
                        handleLeavingPhaseTab = {() => {props.handleLeavingPhaseTab()}}   
                        date_matches_is_loading = { props.date_matches_is_loading } 
                        AddTeamToGroupModal = {() => props.AddTeamToGroupModal( true )}  
                        //deleteDate={(date,index) => props.deleteDate(date,index)}
                        deleteDate={ props.deleteDate }
                        handleTournamentFinalizedSuggestion={props.handleTournamentFinalizedSuggestion}
                        pageRoles = { props.pageRoles }
                        handleAddStaf={ props.handleAddStaf }
                        staff={props.staff}
                        handleOnAcceptStaffClick = { props.handleOnAcceptStaffClick }
                        handleOnRejectStaffClick = { props.handleOnRejectStaffClick }
                        handleOnEditStaffClick = { props.handleOnEditStaffClick }
                        handleOnDeleteStaffClick = { props.handleOnDeleteStaffClick }
                    />
                        
                    {/* tab info */}
                    {/* errors not necesary but mandatory */}
                    <TabPanel 
                        index={ props.selected_tournament.state === 'Finalized'? 7 : 6 } 
                        value={ tab_selected } 
                        onResetError={(data) => {props.onResetError(data)}} 
                        errors={props.errors}
                        errorsNewGroupTournament={props.errorsNewGroupTournament}
                        from_data={props.from_data}
                        to_data={props.to_data}
                        tournament_dates = { props.tournament_dates } 
                        phase_teams={props.phase_teams}
                        tournament_teams={props.tournament_teams}
                        tournament_members={props.tournament_members}                    
                        handleNewDateTournamentClick={ ( ) => { props.handleNewDateTournamentClick() } }
                        selectedDate= { props.selectedDate }
                        handleDateClick={ ( v ) => { props.handleDateClick( v ) } }
                        date_matches={ props.date_matches }
                        createTeamsTournament = { createTeamsTournament }
                        disabled_edit_tournament_data = { props.disabled_edit_tournament_data }
                        selected_tournament = { props.selected_tournament }
                        tournament_results_table = { props.tournament_results_table }
                        viewMatchReport ={ ( v ) => { props.viewMatchReport( v ) } }
                        handleEndMatchTournament = { ( v ) => { props.handleEndMatchTournament( v ) } }
                        handleEditMatchTournament = { ( v ) => { props.handleEditMatchTournament( v ) } }
                        handleNewMatchTournament = { ( v ) => { props.handleNewMatchTournament( v ) } }
                        enable_add_match_button = { props.enable_add_match_button }
                        handleDeleteMatchTournament = { ( v ) => { props.handleDeleteMatchTournament( v ) } }    
                        handleOnFinishMatchActionClick = { ( v ) => { props.handleOnFinishMatchActionClick( v ) } }
                        league_teams={ props.league_teams }
                        tournament_name = { props.tournament_name }                        
                        tournament_phases={ props.tournament_phases }
                        handleAddTeamToGroup = { props.handleAddTeamToGroup }
                        handleDeleteTeamFromGroup = { props.handleDeleteTeamFromGroup }
                        handleActivePhaseCreateState = { props.handleActivePhaseCreateState }
                        handleGroupClick={ ( v, tab_click ) => { props.handleGroupClick( v, tab_click ) } }
                        //addTournamentPhase = {(id_tournament) => {props.addTournamentPhase(id_tournament)}}
                        groups = { props.groups } 
                        handleOnClickPhaseTab = { handleOnClickPhaseTab }
                        handleOnClickPhaseTabInPositionsTable = { handleOnClickPhaseTabInPositionsTable }
                        showPhasePositionTable = { showPhasePositionTable }
                        tournament_active_phase={ props.tournament_active_phase }
                        edit_tournament={props.edit_tournament}
                        createNewGroupPhases = { props.createNewGroupPhases }                        
                        deleteTournamentPhase= {(phase, index) => {props.deleteTournamentPhase(phase, index)}}
                        handleOnClickFinishPhase = {(phase) => {props.handleOnClickFinishPhase(phase)}}   
                        teams_classified_by_phases={props.teams_classified_by_phases}   
                        showLockedPhase = { showLockedPhase }                         
                        showLockedPhaseInPositionsTable = { showLockedPhaseInPositionsTable }
                        showLockedAddPhase = { showLockedAddPhase }
                        is_loaded_tournament_phases={props.is_loaded_tournament_phases}
                        is_loaded_tournament_dates={props.is_loaded_tournament_dates}
                        handleAddPhaseFromOtherTabThanPhases = { handleAddPhaseFromOtherTabThanPhases }
                        finished_last_phase_with_one_clasified = { props.finished_last_phase_with_one_clasified }
                        disabledTabs= { (v)=>disabledTabs(v) }
                        t={props.t}
                        handleLeavingPhaseTab = {() => {props.handleLeavingPhaseTab()}}
                        date_matches_is_loading = { props.date_matches_is_loading }
                        AddTeamToGroupModal = {() => props.AddTeamToGroupModal()}
                        //deleteDate={(date,index) => props.deleteDate(date,index)}
                        deleteDate={ props.deleteDate }
                        handleTournamentFinalizedSuggestion={props.handleTournamentFinalizedSuggestion}
                        tournament_tag = { props.tournament_tag }
                        handleSearchNationality={ props.handleSearchNationality }
                        handleSearchWorldCities = { props.handleSearchWorldCities }
                        editBasicInfo = { props.editBasicInfo }
                        handleOnEditTournamentInfo = { props.handleOnEditTournamentInfo }
                        cities = { props.cities }
                        countries = {props.countries}
                        league_page = {props.league_page}
                        editTournamentInfo = { props.editTournamentInfo }
                        pageRoles = { props.pageRoles }
                        stopEditTournamentInfo = { props.stopEditTournamentInfo }
                        tournament_football_type={props.tournament_football_type}
                        selected_edit_tournament={props.selected_edit_tournament}
                        inscription_errors={props.inscription_errors}
                        handleOpenEditFormModal={props.handleOpenEditFormModal}
                        handlebackToSummaryTournament={props.handlebackToSummaryTournament}
                        handleOpenConfirmationModal={props.handleOpenConfirmationModal}
                        fieldStatusEditing={props.fieldStatusEditing}
                    />
                
                </div>
            </div>
            {/* </div> */}
        </div>
    )
}

export default withTranslation('league')(TournamentEditTournamentComponent)