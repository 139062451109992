/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {compose} from 'redux';
import {Helmet} from "react-helmet";

// Actions
import { 
    loadHomeProfileSuggestions, 
    loadHomePageSuggestions, 
    /*loadGroupsSuggestions,*/ 
    loadLeguesSuggestions, 
    loadHomePosts,
    updateUserData,
    loadHomePagesShortcuts,
    loadFollowedPages,
    unfollowHomePages,
    explorePagesFilter,
    followExplorePage,
    unfollowExplorePage,
    loadHomePlayers,
    loadPlayerAttributes,
    loadHomeFeaturedClips,    
    sendFeaturedClipReaction,
    overrideFeaturedClipReaction,
    deleteFeaturedClipReaction,
 } from 'app/home/actions/HomeActions';
import { followProfile, 
    unfollowProfile, 
    blockProfile,
    switchProfileToPage,
    searchProfileNationality, 
    searchProfileLocations, 
    addProfileNationality,
    searchProfileWorldCities,
    uploadVideoProfile,
 } from 'app/profiles/actions/ProfileActions';
import { followPage, blockPage } from 'app/pages/actions/PageActions';
import { clapPost, 
    deletePost, 
    loadPostClapsById, 
    commentPostById, 
    reportPostById, 
    loadPostClapsNext, 
    sendReaction, 
    overrideReaction, 
    loadPostReactionsById,
    loadPostReactionsNext,
    loadPostReactionsByReactionType,
    deleteReaction,
    followPostProfile,
    unfollowPostProfile,
    followPostPage,
    unfollowPostPage,
    answerQuiz,
    answerPoll,
    uploadPost,
    loadPostCommentsById,
 } from 'app/posts/actions/PostActions';
import { 
    changePageNewPageTypeModalStateAction, 
    changePageNewPagePrivacyModalStateAction, 
    changePageNewPageInformationModalStateAction, 
    changePageNewPageCategoryModalStateAction, 
    cleanPagesStateAction, 
    changePageNewTeamModalStateAction,
    changePageJoinToTeamStateAction,
    changeTeamMemberDetailModalStateAction,
    changeAdminResponsiveModalStateAction,
    changeAdminResponsiveLockModalStateAction,
} from 'app/pages/actions/PageActionsCreators';
import { 
    changePostIsFollowingStateAction, 
    changePostClapsModalStateAction, 
    changeReportPostModalStateAction,
    changeBlockPostModalStateAction,
    changeAcceptReportPostModalStateAction,
    changePostReactionsModalStateAction,
    changeQuotePostModalStateAction,
    postToQuoteAction,
    changePostIsFollowingStateFromReactionTypeAction,
 } from 'app/posts/actions/PostsActionsCreators';
import {
    changeErrorMessageSnackBar,
    changePageSuggestionsModalStateAction,
    changeProfileSuggestionsModalStateAction,
    changeHomeNewPostModal, 
    changeNewHomePostAction,
    changeMobileMenuStateAction,
    changeResponsiveRestrictionModalStateAction,
    resetExplorePagesAction,
    changeHomeFeaturedClipsModalStateAction,
    changeFeaturedProfileAddVideoModalStateAction,
 } from 'app/home/actions/HomeActionsCreators';
import { loadGroupsSuggestions,loadMemberGroupPages } from 'app/pages/actions/PageGroupsActions';
import { changeNewGroupPage } from 'app/pages/actions/PageGroupsActionsCreators';
import { changeNewLeaguePage } from 'app/leagues/actions/LeagueActionsCreators';
import { changeProfileNationalityModalStateAction } from 'app/profiles/actions/ProfileActionsCreators';
import { loadProfile } from 'app/profiles/actions/ProfileActions';
import { loadTeamData, getTeamPlayers } from 'app/leagues/actions/LeagueTeamsActions';

// Components
import HomeFeedComponent from 'app/home/components/home-feed/HomeFeedComponent';
// import SecondaryButtonComponent from 'shared/components/secondary-button/SecondaryButtonComponent';
import ProfileSuggestionsComponent from 'app/profiles/components/profile-suggestions/ProfileSuggestionsComponent';
import PageSuggestionsComponent from 'app/pages/components/page-suggestions/PageSuggestionsComponent';
import PagesSuggestionsComponent from 'app/pages/components/page-groups-suggestions/PagesSuggestionsComponent';
import ClapsModalComponent from 'app/posts/components/claps-modal/ClapsModalComponent';
import ReactionsModalComponent from 'app/posts/components/reactions-modal/ReactionsModalComponent';
//import HomePageGroupsListComponent from 'app/home/components/home-page-groups-list/HomePageGroupsListComponent';
import ReportModalComponent from 'app/posts/components/report-modal/ReportModalComponent';
import BlockModalComponent from 'app/posts/components/block-modal/BlockModalComponent';
import AcceptReportModalComponent from 'app/posts/components/acceptance-modal/AcceptReportModalComponent';
import ProfileSuggestionsModalComponent from 'app/profiles/components/profile-suggestions-modal/ProfileSuggestionsModalComponent';
import PageSuggestionsModalComponent from 'app/pages/components/page-suggestions-modal/PageSuggestionsModalComponent';
import ProfileNationalityModalComponent from 'app/profiles/components/profile-nationality-modal/ProfileNationalityModalComponent';
import HomeUpdateLocationModalComponent from 'app/home/components/home-update-location-modal/HomeUpdateLocationModalComponent';
import HomePostWidgetComponent from 'app/home/components/home-post-widget/HomePostWidgetComponent';
import QuoteModalComponent from 'app/posts/components/quote-modal/QuoteModalComponent';
import HomeUpdateUserInfoModalComponent from 'app/home/components/home-update-user-info-modal/HomeUpdateUserInfoModalComponent';
import HomePagePagesShortcutsComponent from 'app/home/components/home-page-shortcuts/HomePagePagesShortcutsComponent';
import HomeShortCutsComponent from 'app/home/components/home-shortcuts/HomeShortCutsComponent';
import PageTeamDetailModalComponent from 'app/pages/components/page-team-detail-modal/PageTeamDetailModalComponent';
import TournamentValidationResponsiveModalComponent from 'app/leagues/components/tournament-validationResposive-modal/TournamentValidationResponsiveModalComponent';
import ResponsiveRestrictionModalComponent from 'shared/components/responsive-restriction-modal/ResponsiveRestrictionModalComponent';
import HomeCreateProfilePaymentAccountModal from 'app/home/components/home-create-profile-payment-account-modal/HomeCreateProfilePaymentAccountModal';
import HomePagesMenuComponent from 'app/pages/components/home-pages-menu/HomePagesMenuComponent';
import HomePagesComponent from 'app/pages/components/home-pages/HomePagesComponent';
import HomePlayersComponent from 'app/home/components/home-players/HomePlayersComponent';
import HomePlayersFilterComponent from 'app/home/components/home-players-filter/HomePlayersFilterComponent';
import HomeFeaturedClipsWidgetComponent from 'app/home/components/home-featured-clips/HomeFeaturedClipsWidgetComponent';
import HomeFeaturedClipsModalComponent from 'app/home/components/home-featured-clips-modal/HomeFeaturedClipsModalComponent';
import ProfileAddVideoModalComponent from 'app/profiles/components/profile-add-video-modal/ProfileAddVideoModalComponent';
import BaloaProSubscriptionWidgetComponent from 'shared/components/baloa-pro-subscription-widget/BaloaProSubscriptionWidgetComponent';
import HomeMenuProfileWidget from 'app/home/components/home-menu-profile-widget/HomeMenuProfileWidget';

//SmartLook
import smartlookClient from 'smartlook-client'

// Styles
import './HomeView.styles.css';

// Variables
import { web_url } from "config/GlobalData";

// Assests
import baloa_green_logo from 'assets/logos/baloa-logo.png';
import loading_baloa from 'assets/images/loading-baloa.gif';
import loading_ball from 'assets/images/loading_ball.gif';

// isMobile
import { isMobile } from 'react-device-detect';
import HomeBaloaProWelcomeWidgetComponent from 'app/home/components/home-baloa-pro-welcome-widget/HomeBaloaProWelcomeWidgetComponent';

class HomeView extends React.Component { 

    state = {
        menu_is_open: false,
        post_id: null,
        profile_username: null,
        content_info_to_show: "post",
        page_pagename: null,
        update_location_is_open: false,
        update_user_info_is_open: false,
        create_payment_account_is_open: false,
        playersearch: null,
        pagesearch: null,
        featured_clip_selected: null,
        featured_clip_selected_index: 0,
        featured_selected_video: null,
        type_modal_video_clip: null,
        baloa_pro_welcome_widget: false,
    }

    componentDidMount() {
        //Smartlook init
        //console.log("Start tracking: ef4f8bd112f046b748f61fa81dc4cb58c0886f0f");
        smartlookClient.init('ef4f8bd112f046b748f61fa81dc4cb58c0886f0f');
        if(this.props.state.home.posts.length <= 0){
            this.props.loadHomePosts();
        }
        

        this.props.loadHomeProfileSuggestions();
        this.props.loadHomePageSuggestions();
        this.props.loadGroupsSuggestions();
        this.props.loadLeguesSuggestions();
        this.props.loadHomePagesShortcuts();
        this.props.loadHomeFeaturedClips();
        if(!this.props.state.signup.show_welcome_modal) {
            if( this.props.state.user?.me?.username ){
                this.props.loadMemberGroupPages( this.props.state.user?.me?.username );
                if(this.props.state.user?.me.birthday == null) {
                    this.setState({ update_user_info_is_open: true });
                } else if(this.props.state.user?.me.location == null || this.props.state.user?.me.nationality == null){
                    //this.setState( { update_location_is_open: true })
                    console.log('Pending update profile location and nationality');
                }
            }
        }
        if(this.props.state.profile?.loggedin?.pages && this.props.location.state){
            this.handleTypeActionLink(this.props.location.state.typeActionLink);
        }

        this.props.cleanPagesStateAction();

        smartlookClient.track('test', {
			value: 1,
			caption: 'Prueba de home track',
			application: 'Baloa web',
			// other custom properties
		})
        //Should always take the profile, not the selected page
        this.props.switchProfileToPage( undefined );

        //Show create profile payment account modal
        if(this.props.state.user?.me?.is_staff_tournament && !this.props.state.user?.me?.is_stripe_account){
            this.setState({ create_payment_account_is_open: true });
        }

        //Set page_type to players from external link to players section
        let pagesearch = this.props.location.search.split('?search=');
        if(this.props.location.pathname === '/pages' && pagesearch.length === 1){
            this.handleClickMenuSugestions('pages');
        }else if(this.props.location.pathname === '/pages' && pagesearch.length > 1 && (pagesearch[1] !== this.state.pagesearch)) {
            this.setState({pagesearch: pagesearch[1]});
            this.handleClickMenuSugestions('explore', pagesearch[1]);
        }

        //Set page_type to players from external link to players section
        let playersearch = this.props.location.search.split('?search=');
        if(this.props.location.pathname === '/players' && playersearch.length === 1){
            this.handleClickMenuSugestions('players');
        }else if(this.props.location.pathname === '/players' && playersearch.length > 1 && (playersearch[1] !== this.state.playersearch)) {
            this.setState({playersearch: playersearch[1]});
            this.handleClickMenuSugestions('players', playersearch[1]);
        }

        //Load from BaloPro payment
        let prosearch = this.props.location.search.split('?pro');
        if(prosearch.length > 1 && this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' ){
            this.props.history.replace({
                search: ''
            });
            this.setState({baloa_pro_welcome_widget: true})
        }
    }

    componentDidUpdate(prevProps) {
        if(typeof prevProps.state.profile.page_active != typeof this.props.state.profile.page_active){
            this.props.loadHomePosts();
            this.props.loadHomeProfileSuggestions();
            this.props.loadHomePageSuggestions();
        }
        
        if(prevProps.location.key !== this.props.location.key){
            this.handleClickMenuSugestions("post");
        }
        if(!this.props.state.signup.show_welcome_modal) {
            if( prevProps.state.user?.me?.username != this.props.state.user?.me?.username){
                this.props.loadMemberGroupPages( this.props.state.user?.me?.username );
                if(this.props.state.user?.me.birthday === null) {
                    this.setState({ update_user_info_is_open: true });
                } else if(this.props.state.user?.me.location == null || this.props.state.user?.me.nationality == null){
                    //this.setState( { update_location_is_open: true })
                    console.log('Pending update profile location and nationality');
                }
            }
        }
        {/** Featured Clips modal post update */}
        if(this.props.state.home?.home_featured_clips && prevProps.state.home.home_featured_clips !== this.props.state.home.home_featured_clips){
            this.setState({featured_clip_selected: this.props.state.home.home_featured_clips[this.state.featured_clip_selected_index]});
        }
    }

    handleOnProfileClick = ( profile ) => {

        this.props.history.push(`/profile/${profile.user?.username? profile.user.username : profile}`);
    }

    handleOnPageClick = ( page ) => {        
        if(page !== null && page.pagename !== null && page.pagename != undefined && page.pagename !== ""){
            this.props.history.push(`/page/${page.pagename}`);
        }
    }

    handleOnAuthorClickV2 = ( author ) => {
        this.props.history.push(`/${ author.type_object }/${ author.identifier }`);
    }

    handleOnMessageIconClick = ( post ) => {
        this.props.history.push(`/posts/${post.id}`);
    }

    handleOnCommentsClick = ( post ) => {
        this.props.history.push(`/posts/${post.id}`);
    }

    number_of_showing_pages = 4;
    number_of_showing_profiles = 4;

    handleOnCopyLinkClick = async (post) => {
        try {
            const url = `${web_url}/api/social/posts/${post.id}/`;
            await navigator.clipboard.writeText(url);
            this.props.showCopyPostMessage(this.props.t('copyLink.copyLinkSuccess'));
        } catch (err) {
            this.props.showCopyPostMessage(this.props.t('copyLink.copyLinkError'));
        }
    };

    handleReportPost = ( post ) => {
        this.setState({post_id: post});
    };

    handleBlockProfile = ( username ) => {
        this.setState({profile_username: username});
    };

    handleBlockPage = ( pagename ) => {
        this.setState({page_pagename: pagename});
    }

    handleOnLoadMorePosts = () => {
        if(this.props.state.home.posts_next_page !== null){
            this.props.loadHomePosts( this.props.state.home.loading_posts, this.props.state.home.posts_next_page );
        }
    }

    handleClickMenuSugestions = (page_type, search) => {
        if( page_type === 'followed'){
            this.props.loadFollowedPages( this.props.state.user?.me?.username );
        }
        if( page_type !== 'explore'){
            this.props.resetExplorePagesAction( );
        }
        if( page_type === 'players' && !search){
            this.props.loadHomePlayers( '', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
            this.props.loadPlayerAttributes();
        }
        if( page_type === 'players' && search){
            this.props.loadHomePlayers( search, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
            this.props.loadPlayerAttributes();
        }

        if(page_type === 'explore' && search){
            this.props.explorePagesFilter(search, null, null)
        }

        if(page_type === 'BaloaPro'){
            this.props.history.push('/BaloaPro');
        }

        if(page_type === 'BaloaPlay'){
            this.props.history.push('/BaloaPlay');
        }
        
        this.setState({content_info_to_show: page_type});
    }

    handleQuoteClick = ( post ) => {
        this.props.changeQuotePostModalStateAction( true );
        this.props.postToQuoteAction( post );
    }

    handleTypeActionLink = (typeActionLink) =>{
        if(typeActionLink === 'pages'){
            this.handleClickMenuSugestions(typeActionLink);
            this.props.location.state = undefined
        } else if (typeActionLink === 'createPage'){
            this.props.changePageNewPageInformationModalStateAction( true );
        }
    }

    handleUpdateUserInfo = (new_user_data) => {
        if(this.props.state.user.me.birthday === null) {
            let obj = { 
                "birthday": new_user_data[2] 
            }
            this.props.updateUserData(obj)
        }
        this.props.changeProfileNationalityModalStateAction( false );
        this.props.addProfileNationality( this.props.state.profile.loggedin, new_user_data ); 
    }

    handleOpenTeamDetailModalComponent =(team_name, page_admin)=> {
        this.props.loadTeamData(team_name);
        this.props.getTeamPlayers(team_name);
        this.props.changeTeamMemberDetailModalStateAction(true);
        if(page_admin && !this.props.state.page.modals?.admin_lock_modal) this.props.changeAdminResponsiveModalStateAction(true);
    };
    
    handleOpenTournamentDetailModalComponent =(page_admin)=> {
        // this.props.changeTeamMemberDetailModalStateAction(true);
        if(page_admin && !this.props.state.page.modals?.admin_lock_modal) this.props.changeAdminResponsiveModalStateAction(true);
    };

    handleOnClosePageTeamModal = () =>{
        this.props.changeTeamMemberDetailModalStateAction(false);
    };

    handleValidationResponsiveModalState(state){
        this.props.changeAdminResponsiveModalStateAction(state);
    };

    handleBlockValidationModalState=(block_state)=>{
        this.props.changeAdminResponsiveLockModalStateAction(block_state);
    };

    handleOnCreateProfilePaymentAccount=()=>{
        this.setState( { create_payment_account_is_open: false });
        this.props.history.push(`/user/${this.props.state.user.me.username}?paymentAccount`);
    }

    handleGoLink=(home_page_type)=>{
        switch(home_page_type){
            case 'players':
                this.props.history.push('/players');
                break;
            case 'pages':
                this.props.history.push('/pages');
                break;
            case 'BaloaPro':
                this.props.history.push('/BaloaPro');
                break;
            case 'BaloaPlay':
                this.props.history.push('/BaloaPlay');
                break;
            // no default
        }
    }

    async handleOnSelectFeaturedClip( index ){
        //console.log('index que ingresa', index);
        if(this.props.state.home.home_featured_clips && index < this.props.state.home.home_featured_clips?.length){
            let selected_clip = this.props.state.home.home_featured_clips? this.props.state.home.home_featured_clips[index] : null;
            if(selected_clip){
                this.props.loadPostCommentsById( selected_clip.id );
                this.setState({featured_clip_selected: selected_clip, featured_clip_selected_index: index});
                if(!this.props.state.home.modals?.home_featured_clips_is_open){
                    this.props.changeHomeFeaturedClipsModalStateAction(true);
                }
            }
        }else if(this.props.state.home.home_featured_clips && this.props.state.home.home_featured_clips_next_page){
            await this.props.loadHomeFeaturedClips( this.props.state.home.home_featured_clips_next_page );
            let selected_clip = this.props.state.home.home_featured_clips? this.props.state.home.home_featured_clips[index] : null;
            if(selected_clip){
                this.props.loadPostCommentsById( selected_clip.id );
                this.setState({featured_clip_selected: selected_clip, featured_clip_selected_index: index});                
            }	            
        }
        
    }

    handleCloseFeaturedClipsModal(){
        this.setState({featured_clip_selected: null, featured_clip_selected_index: 0});
        this.props.changeHomeFeaturedClipsModalStateAction(false);
    }

    handleOnVideoChange=( videoObject ,modalPostType )=>{
        this.setState({featured_selected_video: videoObject});
        this.setState({type_modal_video_clip: modalPostType});
        this.props.changeFeaturedProfileAddVideoModalStateAction(true);
    }
    
    handleOnPostVideo =(formData, configUpload, loadPost, username)=>{
        this.props.uploadVideoProfile(formData, configUpload, loadPost, username)
    }

    handleOnBaloaProSubscription = ( ) => {
        this.props.history.push(`/BaloaPro`);
    }

    handleOnImageRecord = ( match_id ) => {
        this.props.history.push(`/BaloaPlay/match/${match_id}`);
    }


    render() {

        return (
            <div className={"home " + (this.state.content_info_to_show !== "post"? 'gphome':'')}>
                
                {/* Modals */}
                <ResponsiveRestrictionModalComponent 
                    isOpen={ this.props.state.home.modals?.responsive_restriction_modal_is_open }
                    onClose={ () => { this.props.changeResponsiveRestrictionModalStateAction(false) } }
                />
                
                {/* Social share headers */}
                <Helmet>
                    <title>{`Baloa :: The Global Football Community`}</title>
                    {/* Facebook/WhatsApp  */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={`${web_url}`} />
                    <meta property="og:title" content="Baloa" />
                    <meta property="og:description" content="The Global Football Community" />
                    <meta property="og:image" content={`${web_url}/logo_baloa_social_share.png`} />
                    <meta property="og:image:secure_url" content={`${web_url}/logo_baloa_social_share.png`} />
                    {/* Twitter  */}
                    <meta name="twitter:card" content="summary_large_image" />                    
                    <meta name="twitter:title" content="Baloa" />
                    <meta name="twitter:description" content="The Global Football Community" />
                    <meta name="twitter:image" content={`${web_url}/logo_baloa_social_share.png`} />
                    <meta name="twitter:url" content={`${web_url}`} />
                </Helmet>

                <div className={"home__left-container " + 
                    (this.state.content_info_to_show !== "post"? 
                        (this.state.content_info_to_show !== "pages" && this.state.content_info_to_show !== "explore" && this.state.content_info_to_show !== "players")? 
                            'gphome womenu' : (isMobile && this.state.content_info_to_show === "explore")? 'gphome mob-explore' :'gphome':'')}>
                    { this.state.content_info_to_show === "post" &&
                        <React.Fragment>
                        {/** Profile Widget */}
                        <HomeMenuProfileWidget
                            profile_photo={ this.props.state.profile.loggedin?.user?.photo }
                            profile_name={`${this.props.state.profile.loggedin?.user?.first_name} ${this.props.state.profile.loggedin?.user?.last_name}`}
                            is_baloa_pro={this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE'}
                        />

                        {/* Shortcuts */}
                        <HomeShortCutsComponent
                            handleGoLink={(value)=>{this.handleGoLink(value)}}
                            handleCreateTeam = {()=>{this.props.changePageNewTeamModalStateAction( true )}}
                            handleJoinToTeam={()=>{
                                this.props.loadProfile(this.props.state.user?.me?.username);
                                this.props.changePageJoinToTeamStateAction( true )}
                            }
                            is_baloa_pro={ this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' }
                            is_new_baloa_pro_user={this.props.state.user?.me?.is_new_subscriber}
                        />

                        {/** Subscription Widget */}
                        {(this.props.state.profile.loggedin?.user?.subscription_plan === 'FREE') &&
                            <BaloaProSubscriptionWidgetComponent
                                handleOnBaloaProSubscription={()=>{this.handleOnBaloaProSubscription()}}
                                is_new_baloa_pro_user={this.props.state.user?.me?.is_new_subscriber}
                            />
                        }                        

                        {/* Mis paginas */}
                        {/* <HomePageGroupsListComponent
                            title={this.props.t('profile:pages.title1')}
                            pages={this.props.state.profile.loggedin?.pages?.filter(p => p.page_type != 'group')}
                            sliceSize={"-4"}
                            create_link={this.props.t('pagesGroupsSuggestions.sugestions.addPageButtonCaption')}
                            handleCreateLink={()=>{this.props.changePageNewPageInformationModalStateAction( true ) }}
                            go_link={this.props.t('homePageGroupsList.pageGoLink')}
                            handleGoLink={()=>{this.handleClickMenuSugestions('pages')}}
                        />  */}  
                        {/* Mis grupos */}
                        {/* <HomePageGroupsListComponent
                            title={this.props.t('page:groups.myGroups.title')}
                            pages={this.props.state.profile.loggedin?.pages?.filter(p => p.page_type == 'group')}
                            sliceSize={"-4"}
                            create_link={this.props.t('pagesGroupsSuggestions.sugestions.addGroupButtonCaption')}
                            handleCreateLink={()=>{
                                this.props.changeNewGroupPage( true );
                                this.props.changePageNewPagePrivacyModalStateAction( true ); 
                                // this.props.changePageNewPageInformationModalStateAction( true )
                            }}
                            go_link={this.props.t('homePageGroupsList.groupGoLink')}
                            handleGoLink={()=>{this.handleClickMenuSugestions('groups')}}
                        /> */}
                        {/* Pages Shortcuts */}
                        <HomePagePagesShortcutsComponent
                            title={this.props.t('shortcuts.title')}
                            pages={this.props.state.home.pages_shortcuts}
                            handleOpenTeamDetailModal={ (team_name, page_admin)=> this.handleOpenTeamDetailModalComponent(team_name, page_admin)}
                            handleOpenTournamentDetailModal = { (page_admin)=> this.handleOpenTournamentDetailModalComponent(page_admin)}
                        />                        
                        </React.Fragment>
                    }
                    {/* home menu */}
                    { this.state.content_info_to_show !== "post" && this.state.content_info_to_show !== "players" &&
                        /* <PageGroupsSuggestionsMenuComponent
                            handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                            handleContentInfoActive = { this.state.content_info_to_show }
                        /> */
                        <HomePagesMenuComponent
                            handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                            handleContentInfoActive = { this.state.content_info_to_show }
                            onClickAddButton={()=>{ this.props.changePageNewPageInformationModalStateAction( true ) }}
                            page_categories={ this.props.state.page.page_categories }
                            handleOnExplorePagesFilter={ (pagename, page_type, categories)=>{ this.props.explorePagesFilter(pagename, page_type, categories) }}
                            title = {this.props.t('pages.explorePages')} 
                            disableFilterInputSearch={this.props.state.page.modals?.new_page_information_is_open}
                            pagesearch={this.state.pagesearch}
                        />
                    }
                    { this.state.content_info_to_show === "players" &&
                        <HomePlayersFilterComponent 
                            handleSearchNationality={(new_nationality_search) => { this.props.searchProfileNationality( new_nationality_search) }}
                            searchProfileWorldCities = { (city_name)=>{ this.props.searchProfileWorldCities(city_name) }}
                            nationalities = { this.props.state.profile.nationalities_search_results }                    
                            cities = { this.props.state.profile.world_cities_search_result }
                            lateralityOptions = {this.props.state.home.home_player_attributes.laterality}
                            primaryPositionOptions = {this.props.state.home.home_player_attributes.primary_position}
                            secondaryPositionOptions = {this.props.state.home.home_player_attributes.secondary_position}
                            handleOnPlayersFilter = { ( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange, next_page ) => {this.props.loadHomePlayers( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange, next_page )}}
                            handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                            playersearch={this.state.playersearch}
                        />
                    }

                </div>
                { this.props.state.home.is_mobile_menu_on && 
                    <div className='home__mobile__left-container' >
                        <div className='home__mobile__left-container__header'>
                            <img src={baloa_green_logo} alt='baloa logo' />
                            <span className='icon-x' onClick={() => {this.props.changeMobileMenuStateAction(false)}} />                            
                        </div>
                        {/** Profile Widget */}
                        <HomeMenuProfileWidget
                            profile_photo={ this.props.state.profile.loggedin?.user?.photo }
                            profile_name={`${this.props.state.profile.loggedin?.user?.first_name} ${this.props.state.profile.loggedin?.user?.last_name}`}
                            is_baloa_pro={this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE'}
                        />
                        {/* Shortcuts */}
                        <HomeShortCutsComponent
                            handleGoLink={(value) => { this.handleClickMenuSugestions(value); this.props.changeMobileMenuStateAction(false); }}
                            //handleCreateTeam = {() => { this.props.changeMobileMenuStateAction(false); this.props.changeResponsiveRestrictionModalStateAction( true ); }}
                            handleCreateTeam = {() => { this.props.changeMobileMenuStateAction(false); this.props.changePageNewTeamModalStateAction( true ); }}
                            handleJoinToTeam={()=>{
                                this.props.loadProfile(this.props.state.user?.me?.username);
                                this.props.changePageJoinToTeamStateAction( true );
                                this.props.changeMobileMenuStateAction(false);}
                            }
                            is_baloa_pro={ this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' }
                            profile_type = { this.props.state.profile.loggedin.profile_type }
                            handleOnBaloaProSubscription={()=>{this.handleOnBaloaProSubscription()}}
                            is_new_baloa_pro_user={this.props.state.user?.me?.is_new_subscriber}
                        />
                        
                        <HomePagePagesShortcutsComponent
                            title={this.props.t('shortcuts.title')}
                            pages={this.props.state.home.pages_shortcuts}
                            handleOpenTeamDetailModal={ (team_name, page_admin)=> { 
                                this.props.changeMobileMenuStateAction(false); 
                                this.handleOpenTeamDetailModalComponent(team_name, page_admin);
                                }
                            }
                            handleOpenTournamentDetailModal = { (page_admin)=> { 
                                this.props.changeMobileMenuStateAction(false);
                                this.handleOpenTournamentDetailModalComponent(page_admin);
                                }
                            }
                        />
                    </div>
                }
                <div className={"home__center-container " + (this.state.content_info_to_show !== "post"? (this.state.content_info_to_show !== "pages" && this.state.content_info_to_show !== "explore" && this.state.content_info_to_show !== "players")? 'gphome womenu' : 'gphome':'')}>

                    { this.state.content_info_to_show === "post" &&
                        <React.Fragment>
                        {this.props.state.profile.loggedin?.user?.subscription_plan === 'FREE' && this.props.state.profile.loggedin?.profile_type === 'player' &&
                            <HomeFeaturedClipsWidgetComponent
                                featured_clips ={ this.props.state.home.home_featured_clips}
                                is_baloa_pro={ this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' }
                                profile_type = { this.props.state.profile.loggedin?.profile_type }
                                home_featured_clips_next_page={ this.props.state.home.home_featured_clips_next_page}
                                handleOnClip={( index )=>{ this.handleOnSelectFeaturedClip( index ) }}
                                fetchMoreData={ ()=>{ this.props.loadHomeFeaturedClips( this.props.state.home.home_featured_clips_next_page ) }}
                                handleOnVideoChange={(videoObj, typeModal)=> this.handleOnVideoChange(videoObj, typeModal)}
                                handleOnBaloaProSubscription={()=>{ this.handleOnBaloaProSubscription() }}
                                is_new_baloa_pro_user={this.props.state.user?.me?.is_new_subscriber}
                            />
                        }
                        {this.state.baloa_pro_welcome_widget && this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' &&
                            <HomeBaloaProWelcomeWidgetComponent
                                onClose={()=>{this.setState({baloa_pro_welcome_widget: false})}}
                            />
                        }
                        {!isMobile &&
                            <HomePostWidgetComponent
                                onClick={()=>{ this.props.changeHomeNewPostModal( true ); this.props.changeNewHomePostAction( true ); } }
                                photo={this.props.state.user.me.photo}
                            />
                        }
                        
                        {(this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' || this.props.state.profile.loggedin?.profile_type !== 'player') &&
                            <HomeFeaturedClipsWidgetComponent
                                featured_clips ={ this.props.state.home.home_featured_clips}
                                is_baloa_pro={ this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE' }
                                profile_type = { this.props.state.profile.loggedin?.profile_type }
                                home_featured_clips_next_page={ this.props.state.home.home_featured_clips_next_page}
                                handleOnClip={( index )=>{ this.handleOnSelectFeaturedClip( index ) }}
                                fetchMoreData={ ()=>{ this.props.loadHomeFeaturedClips( this.props.state.home.home_featured_clips_next_page ) }}
                                handleOnVideoChange={(videoObj, typeModal)=> this.handleOnVideoChange(videoObj, typeModal)}
                                handleOnBaloaProSubscription={()=>{ this.handleOnBaloaProSubscription() }}
                                is_new_baloa_pro_user={this.props.state.user?.me?.is_new_subscriber}
                            />
                        }
                        <HomeFeedComponent 

                            posts = { this.props.state.home.posts }
                            profile_suggestions = { this.props.state.home.profile_suggestions }
                            me_photo = { this.props.state.user.me.photo }

                            handleOnLoadMorePosts = { () => this.handleOnLoadMorePosts() }
                            
                            //handleOnProfileClick = { ( profile ) => this.handleOnProfileClick( profile ) }
                            //handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }

                            handleClap = { ( post ) => this.props.clapPost( post ) }
                            onCloseClapsModal={() => { this.props.changePostClapsModalStateAction( true ) }}
                            loadPostClapsById={(post_id) => this.props.loadPostClapsById(post_id)}

                            handleOnMessageIconClick = { ( post ) => this.handleOnMessageIconClick( post ) }
                            handleOnCommentsClick = { ( post ) => this.handleOnCommentsClick( post ) }
                            handleOnComment = { ( post, comment, pagename, page_id ) => this.props.commentPostById( post.id, comment, pagename, page_id )}

                            openReportPostModal={() => {this.props.changeReportPostModalStateAction( true )}}
                            handleReportPost={(post_id) => {this.handleReportPost(post_id)}}

                            openBlockPostModal={() => {this.props.changeBlockPostModalStateAction( true )}}
                            handleBlockProfile={(username) => {this.handleBlockProfile(username)}}
                            handleBlockPage={(pagename) => {this.handleBlockPage(pagename)}}

                            handleOnDeletePostClick = { ( post ) => this.props.deletePost( post ) }

                            handleOnCopyLinkClick = {(post) => {this.handleOnCopyLinkClick(post)}}

                            handleOnAuthorClick = { ( author ) => this.handleOnAuthorClickV2( author ) }

                            menu_is_open = {true}
                            isOpen={ this.props.state.home.modals.new_post_is_open }
                            profile = { this.props.state.profile }
                            user = { this.props.state.user }
                            page = { this.props.state.page }
                            sendReaction= { (post_id, reaction)=> { this.props.sendReaction(post_id, reaction) }}
                            overrideReaction= { (post_id, reaction, reaction_id)=> { this.props.overrideReaction(post_id, reaction, reaction_id) }}
                            handleReactionsDetails = { (new_state) => { this.props.changePostReactionsModalStateAction(new_state) } }
                            loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                            deleteReaction = { ( post_id, reaction_id )=> { this.props.deleteReaction( post_id, reaction_id ) } }                            
                            followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id ) } }                            
                            unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id ) } }                            
                            followPostPage= { ( pagename, post_id )=> { this.props.followPostPage( pagename, post_id ) } }                            
                            unfollowPostPage= { ( pagename, post_id )=> { this.props.unfollowPostPage( pagename, post_id ) } }      
                            
                            handleOnClickQuizAnswer={ (post_id, answer_id)=>{ this.props.answerQuiz(post_id, answer_id) } }
                            handleOnClickPollAnswer ={ (post_id, answer_id)=>{ this.props.answerPoll(post_id, answer_id) } }
                            handleOnQuoteIconClick = { ( post )=>{ this.handleQuoteClick( post ) }}
                            handleOnImageRecord = { ( match_id )=>{ this.handleOnImageRecord( match_id ) } }
                        />
                        </React.Fragment>
                    }

                    { this.state.content_info_to_show === "groups" &&

                        <div>
                            <PagesSuggestionsComponent
                                //pages = { this.props.state.home.group_suggestions }
                                pages = { this.props.state.pageGroups.group_suggestions }
                                handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }
                                handleBackButton = { ( page_type ) => this.handleClickMenuSugestions( page_type ) } 
                                title = {this.props.t('pagesGroupsSuggestions.groups')} 
                                subTitleCaption = {this.props.t('pagesGroupsSuggestions.sugestions.groupsAddButton')}                                 
                                addButtonCaption = {this.props.t('pagesGroupsSuggestions.sugestions.addGroupButtonCaption')} 
                                cardsCaption = {this.props.t('pagesGroupsSuggestions.sugestions.groupCardCaption')}
                                //onClickAddButton={()=>{ this.props.changePageNewPageTypeModalStateAction( true ) }}   
                                onClickAddButton={()=>{
                                    this.props.changeNewGroupPage( true );
                                    this.props.changePageNewPagePrivacyModalStateAction( true );                                       
                                }}  
                                pages_type = {this.props.t('pagesGroupsSuggestions.group')} 
                                page_type = { "groups" }  
                                memberGroupPages = { this.props.state.pageGroups.member_group_pages }  
                                handleFollow = { ( page ) => { this.props.followPage( page ) } }                      
                                addClassName = {"eight-width"}
                            >
                            </PagesSuggestionsComponent>
                        </div>

                    }

                    { this.state.content_info_to_show === "pages" &&

                        <div>
                            {!this.props.state.profile?.loggedin?.pages?
                                <div className={isMobile? 'tournament-hub__loader-mobile' : 'tournament-hub__loader'}><img src={isMobile? loading_ball:loading_baloa} alt="loader" /></div>
                                :                                                        
                                <HomePagesComponent
                                    handleOnPageClick = { ( page ) => {this.handleOnPageClick( page )} }
                                    //handleBackButton = { ( page_type ) => this.handleClickMenuSugestions( page_type ) } 
                                    title = {this.props.t('pages.yourPages')} 
                                    //subTitleCaption = {this.props.t('pagesGroupsSuggestions.sugestions.pageAddButton')} 
                                    //cardsCaption = {this.props.t('pagesGroupsSuggestions.sugestions.pageCardCaption')} 
                                    addButtonCaption = {this.props.t('pages.createPage')}
                                    emptyPagesCaption = {this.props.t('pages.emptyPagesCaption')} 
                                    //onClickAddButton={()=>{ this.props.changePageNewPageTypeModalStateAction( true ) }}
                                    onClickAddButton={()=>{ this.props.changePageNewPageInformationModalStateAction( true ) }}
                                    //pages_type = {this.props.t('pagesGroupsSuggestions.page')} 
                                    page_type = { "pages" }
                                    myPages = { this.props.state.profile?.loggedin?.pages?.filter(pg => (pg.page_type != 'group')) }
                                    //handleFollow = { ( page ) => { this.props.followPage( page ) } }
                                    //addClassName = {"eight-width"}
                                >
                                </HomePagesComponent>
                            }
                        </div>

                    }

                    { this.state.content_info_to_show === "followed" &&
                        <div>                            
                            <HomePagesComponent
                                handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }
                                //handleBackButton = { ( page_type ) => this.handleClickMenuSugestions( page_type ) } 
                                title = {this.props.t('pages.pagesFollowed')} 
                                //subTitleCaption = {this.props.t('pagesGroupsSuggestions.sugestions.pageAddButton')} 
                                //cardsCaption = {this.props.t('pagesGroupsSuggestions.sugestions.pageCardCaption')} 
                                addButtonCaption = {this.props.t('pages.explorePages')}
                                emptyPagesCaption = {this.props.t('pages.emptyFollowedCaption')}
                                //onClickAddButton={()=>{ this.props.changePageNewPageTypeModalStateAction( true ) }}
                                //onClickAddButton={()=>{ this.props.changePageNewPageInformationModalStateAction( true ) }}
                                page_type = { "followed" }
                                myPages = { this.props.state.home?.followed_pages }
                                handleUnFollow = { ( page ) => { this.props.unfollowHomePages( page, this.props.state.user?.me?.username ) } }
                                handleFollow = { ( page ) => { this.props.followPage( page ) } }
                                followCaption = { this.props.t('page:follower.request.button.follow') }
                                followingCaption = { this.props.t('page:follower.request.button.following') }
                                //addClassName = {"eight-width"}
                                fetchMoreData = { this.props.state.home?.followed_pages_next_page? ()=>{ this.props.loadFollowedPages(null, this.props.state.home?.followed_pages_next_page) } : ()=>{console.log('llamo vacio followed')} }
                                handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                            >
                            </HomePagesComponent>
                        </div>
                    }

                    { this.state.content_info_to_show === "explore" &&
                        <div>                            
                            <HomePagesComponent
                                handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }
                                //handleBackButton = { ( page_type ) => this.handleClickMenuSugestions( page_type ) } 
                                title = {this.props.t('pages.explorePages')} 
                                //subTitleCaption = {this.props.t('pagesGroupsSuggestions.sugestions.pageAddButton')} 
                                //cardsCaption = {this.props.t('pagesGroupsSuggestions.sugestions.pageCardCaption')} 
                                emptyPagesCaption = {this.props.t('pages.emptyExploreCaption')}
                                //addButtonCaption = {this.props.t('pagesGroupsSuggestions.sugestions.addPageButtonCaption')} 
                                //onClickAddButton={()=>{ this.props.changePageNewPageTypeModalStateAction( true ) }}
                                //onClickAddButton={()=>{ this.props.changePageNewPageInformationModalStateAction( true ) }}
                                page_type = { "explore" }
                                myPages = { this.props.state.home?.explore_pages? this.props.state.home?.explore_pages : this.props.state.home?.page_suggestions?.slice(0,9) }
                                handleFollow = { ( page ) => { this.props.followExplorePage( page, this.props.state.home?.explore_pages? null : 'suggestions' ) } }
                                handleUnFollow = { ( page ) => { this.props.unfollowExplorePage( page, this.props.state.home?.explore_pages? null : 'suggestions' ) } }
                                followCaption = { this.props.t('page:follower.request.button.follow') }
                                followingCaption = { this.props.t('page:follower.request.button.following') }
                                addClassName = {"explore"}
                                handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                                fetchMoreData = { this.props.state.home?.explore_pages_next_page? ()=>{ this.props.explorePagesFilter(null, null, null, this.props.state.home?.explore_pages_next_page) } : ()=>{console.log('llamo vacio')} }
                                pages_next_page = { this.props.state.home?.explore_pages_next_page }
                            >
                            </HomePagesComponent>
                        </div>
                    }

                    { /* this.state.content_info_to_show === "leagues" &&

                        <div>
                            <PagesSuggestionsComponent
                                pages = { this.props.state.home.league_suggestions }                                
                                handleOnPageClick = { ( page ) => this.handleOnPageClick( page ) }
                                handleBackButton = { ( page_type ) => this.handleClickMenuSugestions( page_type ) } 
                                title = {this.props.t('pagesGroupsSuggestions.leaguesAndTournaments')} 
                                subTitleCaption = {this.props.t('pagesGroupsSuggestions.sugestions.leaguesAddButton')} 
                                addButtonCaption = {this.props.t('pagesGroupsSuggestions.sugestions.addLeagueButtonCaption')} 
                                cardsCaption = {this.props.t('pagesGroupsSuggestions.sugestions.leagueCardCaption')} 
                                //onClickAddButton={()=>{ this.props.changePageNewPageTypeModalStateAction( true ) }}
                                onClickAddButton={ ()=>{
                                        this.props.changeNewLeaguePage( true );
                                        this.props.changePageNewPageInformationModalStateAction( true );
                                    }
                                }
                                pages_type = {this.props.t('pagesGroupsSuggestions.league')}
                                page_type = { "leagues" }
                            >
                            </PagesSuggestionsComponent>
                        </div> */

                    }
                    {/* Players seccion */}
                    {this.state.content_info_to_show === "players" &&
                        <div>                            
                            <HomePlayersComponent
                                home_players={this.props.state.home?.home_players}
                                emptyPlayers={this.props.t('players.filter.emptyPlayers')}
                                playerVideo={this.props.t('players.filter.playerVideo')}

                                handleOnProfileClick={ (profile)=>{ this.handleOnProfileClick(profile) } }
                                handleClickMenuSugestions = { ( page_type ) => this.handleClickMenuSugestions( page_type ) }
                                fetchMoreData = { ()=>{ this.props.loadHomePlayers( null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.props.state.home?.home_players_next_page ) } }
                                home_players_next_page = { this.props.state.home?.home_players_next_page }

                                handleSearchNationality={(new_nationality_search) => { this.props.searchProfileNationality( new_nationality_search) }}
                                searchProfileWorldCities = { (city_name)=>{ this.props.searchProfileWorldCities(city_name) }}
                                nationalities = { this.props.state.profile.nationalities_search_results }                    
                                cities = { this.props.state.profile.world_cities_search_result }
                                lateralityOptions = {this.props.state.home.home_player_attributes.laterality}
                                primaryPositionOptions = {this.props.state.home.home_player_attributes.primary_position}
                                secondaryPositionOptions = {this.props.state.home.home_player_attributes.secondary_position}
                                handleOnPlayersFilter = { ( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange, next_page ) => {this.props.loadHomePlayers( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange, next_page )}}
                                handleOnBaloaProSubscription={()=>{this.handleOnBaloaProSubscription()}}
                                is_baloa_pro={ this.props.state.profile.loggedin?.user?.subscription_plan !== 'FREE'}
                                profile_type={ this.props.state.profile.loggedin.profile_type }
                                is_new_baloa_pro_user={this.props.state.user?.me?.is_new_subscriber}
                            />
                        </div>
                    }

                </div>
                {this.state.content_info_to_show === "post" &&
                    <div className="home__right-container">
                        <PageSuggestionsComponent
                            title={this.props.t('follow.suggestions.subtitle')}
                            pages = { this.props.state.home.page_suggestions?.slice(0, this.number_of_showing_pages) }
                            className = "home__right-container__pages-suggestions sm"
                            handleFollow = { ( page ) => { this.props.followPage( page ) } }
                            loading_page_suggestions = { this.props.state.home.loading_page_suggestions }
                            go_link={this.props.t('follow.suggestions.viewAllBtn')}
                            handleGoLink={()=>{this.props.changePageSuggestionsModalStateAction( true )}}
                        />
                        <ProfileSuggestionsComponent
                            title={this.props.t('follow.suggestions.title')}
                            profiles = { this.props.state.home.profile_suggestions?.slice(0, this.number_of_showing_profiles) }
                            className = "home__right-container__profile-suggestions sm"
                            handleFollow = { ( profile ) => { this.props.followProfile( profile ) } }
                            loading_profile_suggestions = { this.props.state.home.loading_profile_suggestions }
                            go_link={this.props.t('follow.suggestions.viewAllBtn')}
                            handleGoLink={()=>{ this.props.changeProfileSuggestionsModalStateAction( true )}}
                        />
                        
                        {/*<div className="home__right-container__cta-pages">
                            <p className="home__right-container__cta-pages__subtitle baloa-subtitle-1">{this.props.t('follow.suggestions.subtitle2')}</p>
                            <p className="home__right-container__cta-pages__text baloa-body-2">{this.props.t('follow.suggestions.text')}</p>
                            <SecondaryButtonComponent 
                                onClick={()=>{ this.props.changePageNewPageTypeModalStateAction( true ) }} 
                                className="home__right-container__cta-pages__new"
                            >
                                <span>{this.props.t('follow.suggestions.button')}</span>
                            </SecondaryButtonComponent>
                        </div>*/}
                    </div>
                }
                <React.Fragment>
                    <ClapsModalComponent
                        onClick={() => {}} 
                        isOpen={ this.props.state.post.modals?.claps_is_open } 
                        onClose={() => { this.props.changePostClapsModalStateAction( false ) }}
                        claps={ this.props.state.post.claps }
                        page={this.props.state.profile.page_active}
                        user_me={this.props.state.user.me}
                        claps_next_page = { this.props.state.post.claps_next_page}
                        followProfile = { ( profile ) => { this.props.followProfile( profile ) } }
                        unfollowProfile = { ( profile ) => { this.props.unfollowProfile( profile ) } }
                        changePostIsFollowing ={(username) => {this.props.changePostIsFollowingStateAction(username)}}
                        loadPostClapsNext={(next_page) => this.props.loadPostClapsNext(next_page)}
                    />

                    <ReactionsModalComponent
                        onClick={() => {}} 
                        isOpen={ this.props.state.post.modals?.reactions_is_open } 
                        onClose={() => { this.props.changePostReactionsModalStateAction( false ) }}
                        reactions={ this.props.state.post.reactions }
                        page={this.props.state.profile.page_active}
                        user_me={this.props.state.user.me}
                        reactions_next_page = { this.props.state.post.reactions_next_page}
                        followProfile = { ( profile ) => { this.props.followProfile( profile ) } }
                        unfollowProfile = { ( profile ) => { this.props.unfollowProfile( profile ) } }
                        changePostIsFollowing ={(username) => {this.props.changePostIsFollowingStateAction(username)}}
                        loadPostReactionsNext={(next_page, reaction_type) => this.props.loadPostReactionsNext(next_page, reaction_type)}
                        loadPostReactionsByType = { ( reaction_type ) => {this.props.loadPostReactionsByReactionType( this.props.state.post.post.id, reaction_type ) } }
                        reactions_by_type = { this.props.state.post.reactions_by_type }
                        changePostIsFollowingFromReactionType={(username, reaction_type) => {this.props.changePostIsFollowingStateFromReactionTypeAction(username, reaction_type)}}
                    />

                    <ReportModalComponent
                        onClick={() => {}} 
                        isOpen={ this.props.state.post.modals?.report_is_open } 
                        onClose={() => { this.props.changeReportPostModalStateAction( false ) }}
                        post_id={this.state.post_id}
                        reportPostById={ (post_id, denunciation) => {this.props.reportPostById(post_id, denunciation)}}
                        openAcceptReport={ () => this.props.changeAcceptReportPostModalStateAction( true ) }
                    />

                    <BlockModalComponent 
                        onClick={() => {}} 
                        isOpen={ this.props.state.post.modals?.block_is_open } 
                        onClose={() => { this.props.changeBlockPostModalStateAction( false ) }}
                        profile_username={this.state.profile_username}
                        blockProfile={ (username) => {this.props.blockProfile(username)}}
                        page_pagename={this.state.page_pagename}
                        blockPage={ (pagename) => {this.props.blockPage(pagename)}}

                    />

                    <AcceptReportModalComponent
                        onClick={() => {}} 
                        isOpen={ this.props.state.post.modals?.accept_report_is_open } 
                        onClose={() => { this.props.changeAcceptReportPostModalStateAction( false ) }}
                    />

                    <ProfileSuggestionsModalComponent
                        onClick={() => {}} 
                        isOpen={ this.props.state.home.modals.profile_suggestions_is_open } 
                        onClose={() => { this.props.changeProfileSuggestionsModalStateAction( false ) }}
                        title={this.props.t('follow.suggestions.title')}
                        profiles = { this.props.state.home.profile_suggestions }
                        //className = "home__right-container__profile-suggestions sm"
                        handleFollow = { ( profile ) => { this.props.followProfile( profile ) } }
                    />
                    <PageSuggestionsModalComponent
                        onClick={() => {}} 
                        isOpen={ this.props.state.home.modals.page_suggestions_is_open } 
                        onClose={() => { this.props.changePageSuggestionsModalStateAction( false ) }}
                        title={this.props.t('follow.suggestions.subtitle')}
                        pages = { this.props.state.home.page_suggestions }
                        handleFollow = { ( page ) => { this.props.followPage( page ) } }
                    />
                    {/* Profile Nationality Modal */}
                    <ProfileNationalityModalComponent 
                        onClick={() => {}} 
                        isOpen={ this.props.state.profile.modals?.nationality_is_open } 
                        onClose={() => { this.props.changeProfileNationalityModalStateAction( false ) }}
                        handleSearchNationality={(new_nationality_search) => { this.props.searchProfileNationality( new_nationality_search) }}
                        handleSearchLocation={( nationality, new_location_search ) => { this.props.searchProfileLocations( nationality, new_location_search ) }}
                        model = { this.props.state.profile.loggedin }
                        nationalities = { this.props.state.profile.nationalities_search_results }
                        locations = { this.props.state.profile.locations_search_results }
                        onNewData = { ( new_user_data ) => { this.handleUpdateUserInfo(new_user_data) }}
                        user_birthday = {this.props.state.user?.me.birthday}
                    />
                    <HomeCreateProfilePaymentAccountModal
                        isOpen={ this.state.create_payment_account_is_open } 
                        onClose={() => { this.setState( { create_payment_account_is_open: false }) }}
                        onHandleAccept = {() => { this.handleOnCreateProfilePaymentAccount(); }}
                    />
                    <HomeUpdateLocationModalComponent     
                        isOpen={ this.state.update_location_is_open } 
                        onClose={() => { this.setState( { update_location_is_open: false }) }}
                        onHandleAccept = {() => { this.props.changeProfileNationalityModalStateAction( true ); this.setState( { update_location_is_open: false }); }}
                    />                    
                    {/* Repost Modal */}
                    <QuoteModalComponent
                        isOpen={ this.props.state.post.modals?.quote_modal_is_open } 
                        onClose={() => { this.props.changeQuotePostModalStateAction( false ) } }
                        post={this.props.state.post.post_to_quote}
                        profile = { this.props.state.profile }
                        user = { this.props.state.user }
                        page = { this.props.state.page }
                        onNewData = {(data)=>{ this.props.uploadPost(data) }}

                        followPostProfile= { ( username, post_id )=> { this.props.followPostProfile( username, post_id ) } }                            
                        unfollowPostProfile= { ( username, post_id )=> { this.props.unfollowPostProfile( username, post_id ) } }                            
                        followPostPage= { ( pagename, post_id )=> { this.props.followPostPage( pagename, post_id ) } }                            
                        unfollowPostPage= { ( pagename, post_id )=> { this.props.unfollowPostPage( pagename, post_id ) } }  
                    />
                    {/* Update User Info Modal */}
                    <HomeUpdateUserInfoModalComponent
                        isOpen={ this.state.update_user_info_is_open } 
                        onClose={() => { this.setState( { update_user_info_is_open: false }) }}
                        onHandleAccept = {() => { this.props.changeProfileNationalityModalStateAction( true ); this.setState( { update_user_info_is_open: false }); }} 
                    />

                    {/* Team Detail Modal */}
                    <PageTeamDetailModalComponent
                        isOpen={this.props.state.page.modals?.team_member_detail_is_open}
                        onClose={() => { this.handleOnClosePageTeamModal() }}
                        team= { this.props.state.teams.team }
                        tournaments = { this.props.state.teams.team.tournaments }
                        followProfile={(profile) => { this.props.followProfile(profile) }}
                        unfollowProfile={(profile) => { this.props.unfollowProfile(profile) }}
                    />

                    {/* Validation Admin Resposive Modal */}
                    <TournamentValidationResponsiveModalComponent 
                        isOpen={ this.props.state.page.modals?.admin_responsive_is_open }
                        onClose={() => { this.handleValidationResponsiveModalState( false ) } }
                        handleBlockModal={(state)=> this.handleBlockValidationModalState( state )}
                        shortcut_type='team'                        
                    />

                    {/** Featured Video Clips Modal */}
                    <HomeFeaturedClipsModalComponent
                        isOpen={ this.props.state.home.modals.home_featured_clips_is_open }
                        onClose={()=>{ this.handleCloseFeaturedClipsModal() }}
                        //post={this.props.state.home.home_featured_clips}
                        post={ this.state.featured_clip_selected }
                        menu_is_open = {true}
                        me_photo={this.props.state.user.me?.photo}
                        featured_clip_selected_index={this.state.featured_clip_selected_index}

                        //Actions
                        handleOnMessageIconClick = { ( post ) => this.handleOnMessageIconClick( post ) }
                        handleOnCommentsClick = { ( post ) => this.handleOnCommentsClick( post ) }
                        handleOnComment = { ( post, comment ) => { this.props.commentPostById( post.id, comment, null, null, 'featured' ) }}
                        openReportPostModal={() => {this.props.changeReportPostModalStateAction( true )}}
                        handleReportPost={(post_id) => {this.handleReportPost(post_id)}}
                        openBlockPostModal={() => {this.props.changeBlockPostModalStateAction( true )}}
                        handleBlockProfile={(username) => {this.handleBlockProfile(username)}}
                        handleOnDeletePostClick = { ( post ) => {this.props.deletePost( post ); this.props.changeProfilePostVideoClipModalStateAction(false);} }
                        handleOnCopyLinkClick = {(post) => {this.handleOnCopyLinkClick(post)}}
                        handleOnAuthorClick = { ( author ) => this.handleOnAuthorClickV2( author ) }
                        //changeHomeEditPostModal = { ( state, post)=>{ this.props.changeHomeEditPostModal( state, post) } } //TODO: review functionality
                        sendReaction= { (post_id, reaction)=> { this.props.sendFeaturedClipReaction(post_id, reaction) }} 
                        overrideReaction= { (post_id, reaction, reaction_id)=> { this.props.overrideFeaturedClipReaction(post_id, reaction, reaction_id ) }}
                        handleReactionsDetails = { (new_state) => { this.props.changePostReactionsModalStateAction(new_state) } }
                        loadPostReactionsById = { (post_id) => this.props.loadPostReactionsById(post_id) }
                        deleteReaction = { ( post_id, reaction_id )=> { this.props.deleteFeaturedClipReaction( post_id, reaction_id ) } }
                        handleOnClip={( index )=>{ this.handleOnSelectFeaturedClip( index ) }}
                        post_comments={ this.props.state.post.comments }
                    />
                    <ProfileAddVideoModalComponent
                        isOpen={ this.props.state.home.modals?.profile_video_modal_is_open } 
                        onClose={() => {
                            this.props.changeFeaturedProfileAddVideoModalStateAction( false );
                            this.setState({featured_selected_video: null,  type_action_modal_video_clip: '', clip_match: undefined, share_clip_on_match: undefined});
                        }}
                        selected_video_profile={this.state.featured_selected_video}
                        uploadVideoProfile={(formData, configUpload, loadPost)=>this.handleOnPostVideo(formData, configUpload, loadPost, this.props.state.profile.loggedin.user.username)}
                        type_modal_video_clip={this.state.type_modal_video_clip}
                        //post={this.props.state.profile?.profile_selected_post}
                        type_action_modal_video_clip={''}
                        //handleEditTitleVideoClip = {(postId, titleVideo)=> this.handleEditVideoClip(postId, titleVideo, null, this.props.state.profile.profile.user.username )}
                        profile_tournament_paid={true}//Se deja en true para evitar mensajes de pago de torneos y deshabilitar esta funcionalidad
                        //handleModalUnlockData={() => { this.handleModalUnlockData() } }
                        /* handleLoadMatchClipModal={ () => { 
                            this.props.changeProfileChooseMatchClipModalStateAction(true);
                            this.setState({share_clip_on_match: true })
                        }} */
                        //shareClipOnMatch={this.state.share_clip_on_match}
                        //cleanClipMatch={()=>{ this.setState({clip_match: undefined, share_clip_on_match: undefined }) } }
                        //clipMatch={this.props.state.profile.profile_matches.find(match => match.id === this.state.clip_match)}
                        //handleEditFeatureMatchVideoClip={(postId, params)=> this.handleEditVideoClip(postId, null, params, this.props.state.profile.profile.user.username )}
                        disableAddToMatch={true}//Se agrega parametro para desahabilitar la opciion de asociar el video a un partido
                    />

                </React.Fragment>
            </div>
        )

    }
}

// Redux mapping
const mapStateToProps = state => {
    return {
        state
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadHomePosts: ( loading = false, next_page ) => {
            return dispatch( loadHomePosts( loading, next_page ) )
        },
        loadHomeProfileSuggestions: () => {
            return dispatch( loadHomeProfileSuggestions() )
        },
        loadHomePageSuggestions: () => {
            return dispatch( loadHomePageSuggestions() )
        },
        loadGroupsSuggestions: () => {
            return dispatch( loadGroupsSuggestions() )
        },
        loadLeguesSuggestions: () => {
            return dispatch( loadLeguesSuggestions() )
        },
        followProfile: ( profile ) => {
            return dispatch( followProfile( profile ) )
        },
        followPage: ( page ) => {
            return dispatch( followPage( page ) )
        },
        clapPost: ( post ) => {
            return dispatch( clapPost( post ) )
        },
        changePageNewPageTypeModalStateAction: ( new_modal_state ) => {
            return dispatch( changePageNewPageTypeModalStateAction( new_modal_state ) )
        },
        changePageNewPageCategoryModalStateAction: ( new_modal_state ) => {
            return dispatch( changePageNewPageCategoryModalStateAction( new_modal_state ) )
        },
        changePageNewPageInformationModalStateAction: ( new_modal_state ) => {
            return dispatch( changePageNewPageInformationModalStateAction( new_modal_state ) )
        },
        changePageNewTeamModalStateAction: (new_modal_team) =>{
            return dispatch( changePageNewTeamModalStateAction( new_modal_team))
        },
        commentPostById: ( post_id, comment, pagename, page_id, type ) => {
            return dispatch( commentPostById( post_id, comment, pagename, page_id, type ))
        },
        deletePost: ( post ) => {
            return dispatch( deletePost( post ) )
        },
        loadPostClapsById: ( post_id ) => {
            dispatch( loadPostClapsById( post_id ))
        },
        changePostClapsModalStateAction: ( new_modal_state ) => {
            dispatch( changePostClapsModalStateAction( new_modal_state ) )
        },
        unfollowProfile: ( profile ) => {
            dispatch( unfollowProfile( profile ) )
        },
        showCopyPostMessage: (copymessage) => {
            return dispatch( changeErrorMessageSnackBar( {
                message: copymessage,
                error_message_is_open: true
            } ) );
        },
        reportPostById: ( post_id, denunciation ) => {
            dispatch( reportPostById( post_id, denunciation ))
        },
        blockProfile:( username ) => {
            dispatch( blockProfile( username ))
        },
        changeReportPostModalStateAction: ( new_modal_state ) => {
            dispatch( changeReportPostModalStateAction( new_modal_state ))
        },
        changeBlockPostModalStateAction: (new_modal_state) => {
            dispatch( changeBlockPostModalStateAction(new_modal_state))
        },
        changeAcceptReportPostModalStateAction: (new_modal_state) => {
            dispatch( changeAcceptReportPostModalStateAction(new_modal_state))
        },
        changePostIsFollowingStateAction: (username) => {
            dispatch( changePostIsFollowingStateAction(username))
        },
        loadPostClapsNext: (next_page) => {
            dispatch( loadPostClapsNext(next_page))
        },
        changePageNewPagePrivacyModalStateAction: ( new_modal_state ) => {
            dispatch( changePageNewPagePrivacyModalStateAction( new_modal_state ))
        },
        changeNewGroupPage: ( new_state ) => {
            dispatch( changeNewGroupPage( new_state ))
        },
        changeNewLeaguePage: ( new_state ) => {
            dispatch( changeNewLeaguePage( new_state ))
        },
        loadMemberGroupPages:( username ) => {
            dispatch( loadMemberGroupPages( username ))
        },
        cleanPagesStateAction: () => {
            dispatch( cleanPagesStateAction( ))
        },
        blockPage:( pagename ) => {
            dispatch( blockPage( pagename ))
        },
        switchProfileToPage: ( page ) => {
            return dispatch( switchProfileToPage( page ) )
        },
        sendReaction:( post_id, reaction ) => {
            dispatch( sendReaction( post_id, reaction ))
        },
        overrideReaction:( post_id, reaction, reaction_id ) => {
            dispatch( overrideReaction( post_id, reaction, reaction_id ))
        },
        changePostReactionsModalStateAction: (new_modal_state) => {
            dispatch( changePostReactionsModalStateAction(new_modal_state))
        },
        loadPostReactionsById: ( post_id ) => {
            dispatch( loadPostReactionsById( post_id ))
        },
        loadPostReactionsNext: (next_page, reaction_type) => {
            dispatch( loadPostReactionsNext(next_page, reaction_type))
        },
        loadPostReactionsByReactionType:( post_id, reaction_type ) => {
            dispatch( loadPostReactionsByReactionType( post_id, reaction_type ))
        },
        deleteReaction:( post_id, reaction_id ) => {
            dispatch( deleteReaction( post_id, reaction_id ))
        },
        followPostProfile:( username, post_id ) => {
            dispatch( followPostProfile( username, post_id ))
        }, 
        unfollowPostProfile:( username, post_id ) => {
            dispatch( unfollowPostProfile( username, post_id ))
        }, 
        followPostPage:( pagename, post_id ) => {
            dispatch( followPostPage( pagename, post_id ))
        }, 
        unfollowPostPage:( pagename, post_id ) => {
            dispatch( unfollowPostPage( pagename, post_id ))
        },
        changePageSuggestionsModalStateAction: (new_modal_state) => {
            dispatch( changePageSuggestionsModalStateAction(new_modal_state))
        },
        changeProfileSuggestionsModalStateAction: (new_modal_state) => {
            dispatch( changeProfileSuggestionsModalStateAction(new_modal_state))
        },
        changeProfileNationalityModalStateAction: ( new_modal_state ) => {
            dispatch( changeProfileNationalityModalStateAction( new_modal_state ) )
        },
        searchProfileNationality: (new_nationality_search) => {
            return dispatch( searchProfileNationality( new_nationality_search ) )
        },
        searchProfileLocations: ( nationality, new_location_search ) => {
            return dispatch( searchProfileLocations( nationality, new_location_search ) )
        },
        addProfileNationality: ( profile, new_nationality_data ) => {
            return dispatch( addProfileNationality( profile, new_nationality_data ) )
        },
        changeHomeNewPostModal: ( new_modal_state ) => {
            return dispatch( changeHomeNewPostModal( new_modal_state ) );
        },
        changeNewHomePostAction: ( new_state ) => {
            dispatch( changeNewHomePostAction( new_state ) );
        },
        answerQuiz: ( post_id, answer_id ) => {
            dispatch( answerQuiz( post_id, answer_id ) );
        },
        answerPoll: ( post_id, answer_id ) => {
            dispatch( answerPoll( post_id, answer_id ) );
        },
        changeQuotePostModalStateAction: ( new_modal_state ) => {
            dispatch( changeQuotePostModalStateAction( new_modal_state ) )
        },
        postToQuoteAction: ( post ) => {
            dispatch( postToQuoteAction( post ) )
        },
        uploadPost: ( post_data ) => {
            dispatch( uploadPost( post_data,null,true,null) )
        },
        changePostIsFollowingStateFromReactionTypeAction: (username, reaction_type) => {
            dispatch( changePostIsFollowingStateFromReactionTypeAction(username, reaction_type))
        },
        updateUserData: (new_user_data) => {
            dispatch( updateUserData(new_user_data) );
        },
        loadHomePagesShortcuts: () => {
            return dispatch( loadHomePagesShortcuts() );
        },
        changePageJoinToTeamStateAction: (new_modal_team) =>{
            return dispatch( changePageJoinToTeamStateAction( new_modal_team))
        },
        loadProfile: (username) => {
            dispatch(loadProfile(username))
        },
        changeMobileMenuStateAction: ( new_state ) => {
            dispatch( changeMobileMenuStateAction( new_state ) );
        },
        changeTeamMemberDetailModalStateAction: (new_modal_state) => {
            dispatch(changeTeamMemberDetailModalStateAction(new_modal_state))
        },
        loadTeamData: ( teamname ) => {
            dispatch( loadTeamData( teamname ) );
        },
        getTeamPlayers: ( teamname ) => {
            dispatch( getTeamPlayers( teamname ) );
        },
        changeAdminResponsiveModalStateAction: (new_modal_state) =>{
            dispatch (changeAdminResponsiveModalStateAction(new_modal_state));
        },
        changeAdminResponsiveLockModalStateAction: (new_modal_state) =>{
            dispatch (changeAdminResponsiveLockModalStateAction(new_modal_state));
        },
        changeResponsiveRestrictionModalStateAction: ( new_modal_state ) => {
            dispatch( changeResponsiveRestrictionModalStateAction( new_modal_state ) );
        },
        loadFollowedPages: ( username, next_page ) => {
            dispatch( loadFollowedPages( username, next_page ));
        },
        unfollowHomePages: ( page, username ) => {
            return dispatch( unfollowHomePages( page, username ) )
        },
        explorePagesFilter: (pagename, page_type, categories, next_page) => {
            dispatch( explorePagesFilter( pagename, page_type, categories, next_page ));
        },
        resetExplorePagesAction: (  ) => {
            dispatch( resetExplorePagesAction(  ));
        },
        followExplorePage: ( page, api_type ) => {
            return dispatch( followExplorePage( page, api_type ) )
        },
        unfollowExplorePage: ( page, api_type ) => {
            return dispatch( unfollowExplorePage( page, api_type ) )
        },
        loadHomePlayers: ( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange,  next_page ) => {
            dispatch ( loadHomePlayers( player, video, nationality, location, age, principalPos, secondaryPos, laterality, goals, goalsRange, assistances, assistancesRange, playedMatches, playedMatchesRange, starterMatches, starterMatchesRange, playedMinutes, playedMinutesRange, next_page ) )
        },
        searchProfileWorldCities:( city_name )=>{
            dispatch(searchProfileWorldCities( city_name ));
        },
        loadPlayerAttributes: () => {
            dispatch ( loadPlayerAttributes() )
        },
        loadHomeFeaturedClips: async( next_page ) => {
            await dispatch ( loadHomeFeaturedClips( next_page ) )
        },
        sendFeaturedClipReaction:( post_id, reaction ) => {
            dispatch( sendFeaturedClipReaction( post_id, reaction ))
        },
        overrideFeaturedClipReaction:( post_id, reaction, reaction_id ) => {
            dispatch( overrideFeaturedClipReaction( post_id, reaction, reaction_id ))
        },
        deleteFeaturedClipReaction:( post_id, reaction_id ) => {
            dispatch( deleteFeaturedClipReaction( post_id, reaction_id ))
        },
        changeHomeFeaturedClipsModalStateAction: (new_modal_state) =>{
            dispatch (changeHomeFeaturedClipsModalStateAction(new_modal_state));
        },
        changeFeaturedProfileAddVideoModalStateAction: (new_modal_state) =>{
            dispatch (changeFeaturedProfileAddVideoModalStateAction(new_modal_state));
        },
        uploadVideoProfile: (formData, configUpload, loadPost, username) => {
            return dispatch(uploadVideoProfile(formData, configUpload, loadPost, username));
        },
        loadPostCommentsById: ( post_id ) =>{
            dispatch (loadPostCommentsById( post_id ));
        },
    }
}

export default compose(  
    withTranslation("home",'page','profile'),        
    connect(mapStateToProps, mapDispatchToProps)
  )(HomeView);

//export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
