// React
import React, { Component } from 'react'
import { connect } from 'react-redux';
// Redux
import { compose } from 'redux';

//Actions
import {
    loadUnauthStripePrices,
} from 'app/baloa-pro/actions/BaloaProActions';

//Actions Creators
import {
    changeBaloaProUnauthSubscriptionModalStateAction,
} from 'app/baloa-pro/actions/BaloaProActionsCreators';

// Styles
import './BaloaProView.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import BaloaProHeaderComponent from '../components/baloa-pro-header/BaloaProHeaderComponent';
import BaloaProAdvantagesComponent from '../components/baloa-pro-advantages/BaloaProAdvantagesComponent';
import BaloaProFunctionalitiesComponent from '../components/baloa-pro-functionalities/BaloaProFunctionalitiesComponent';
import BaloaProSubscriptionComponent from '../components/baloa-pro-subscription/BaloaProSubscriptionComponent';

//Modals
import BaloaProUnauthSubscriptionModalComponent from '../components/baloa-pro-unauth-subscription-modal/BaloaProUnauthSubscriptionModalComponent';

class BaloaProUnatheticatedView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    async componentDidMount (){
        if(this.props.state.baloapro.stripe_prices?.length <= 0 && !this.props.state.baloapro.loading_uanuth_stripe_prices){
            await this.props.loadUnauthStripePrices();
        }
    }

    async componentDidUpdate (prevProps) {
        if(this.props.state.baloapro.stripe_prices?.length <= 0 && !this.props.state.baloapro.loading_uanuth_stripe_prices){
            await this.props.loadUnauthStripePrices();
        }
    }

    handleLogin(){
        this.props.history.push('/cuenta/ingresar?BaloaPro')
    }

    render() {
        return (
            <div className='baloa-pro__container'>
                <BaloaProHeaderComponent
                    handleOnClickYearly={( price )=>{ this.props.changeBaloaProUnauthSubscriptionModalStateAction(true) }}
                    handleOnClickMonthly={( price )=>{ this.props.changeBaloaProUnauthSubscriptionModalStateAction(true) }}
                    is_baloa_pro={ false }
                    prices={ this.props.state.baloapro.stripe_prices }
                    is_new_baloa_pro_user={true}
                    handleOnClickSubscription={()=>{ this.props.changeBaloaProUnauthSubscriptionModalStateAction(true) }}
                />                
                <div className='baloa-pro__content'>
                    <BaloaProAdvantagesComponent/>
                    <BaloaProFunctionalitiesComponent/>

                    <BaloaProSubscriptionComponent
                        handleOnClickYearly={( price )=>{ this.props.changeBaloaProUnauthSubscriptionModalStateAction(true) }}
                        handleOnClickMonthly={( price )=>{ this.props.changeBaloaProUnauthSubscriptionModalStateAction(true) }}
                        prices={ this.props.state.baloapro.stripe_prices }
                        is_new_baloa_pro_user={true}
                        handleOnClickSubscription={()=>{ this.props.changeBaloaProUnauthSubscriptionModalStateAction(true) }}
                    />  
                    
                                      
                </div>

                {/** Modals */}
                {/** Unauth Subscription */}
                <BaloaProUnauthSubscriptionModalComponent
                    isOpen={ this.props.state.baloapro.modals?.unauth_subscription_modal }
                    onClose={ () => { this.props.changeBaloaProUnauthSubscriptionModalStateAction(false) } }
                    handleLogin={()=>{ this.handleLogin() }}
                />                
            </div>
        )
    }
  
}
  
const mapStateToProps = state => {
    return {
      state
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        loadUnauthStripePrices: () =>{
            dispatch (loadUnauthStripePrices());
        },
        changeBaloaProUnauthSubscriptionModalStateAction: (new_modal_state) =>{
            dispatch (changeBaloaProUnauthSubscriptionModalStateAction(new_modal_state));
        },
    }
}

export default compose(
    withTranslation("baloapro"),
    connect(mapStateToProps, mapDispatchToProps)
    )(BaloaProUnatheticatedView);