// React
import React from 'react';

// Styles
import './TournamentStatisticsTeamsComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components
import TournamentStatisticsSkeletonComponent from 'app/leagues/components/tournament-statistics-skeleton/TournamentStatisticsSkeletonComponent';
import TournamentTeamInfoComponent from 'app/leagues/components/tournament-team-info/TournamentTeamInfoComponent';

function TournamentStatisticsTeamsComponent(props){ 
    return(
        <div className='tournament-statistics-teams__container'>
            <div className='tournament-statistics-teams__title'>
                <div className='baloa-subtitle-2'>{ props.title }</div>
                {props.column_title &&
                    <div className='baloa-subtitle-2'>{ props.column_title }</div> 
                }
            </div>
            {props.loading_teams && 
                [...Array(Number(5))].map((e, i) => 
                    <TournamentStatisticsSkeletonComponent/>
                )
            }
            { (!props.loading_teams && props.tournament_teams?.length > 0)
                ?
                    props.tournament_teams?.slice(0, 5).map( (team, teamIndex) => 
                        <div className='tournament-statistics-teams__row' key={teamIndex}>
                            <div className='tournament-statistics-teams__row__counter baloa-normal-medium'>{teamIndex + 1}</div>
                            <div><TournamentTeamInfoComponent name={`${team.name}`} photo={team.team_photo} teamname={team.teamname} /></div>
                            <div className='tournament-statistics-teams__row__goals baloa-normal-medium'>{team.goals}</div>
                        </div>
                    )                        
                :
                    <div className='tournament-statistics-teams__no-data'>
                        <div className='baloa-normal'>{ props.empty_teams_lbl }</div>
                    </div>
            }
            {(props.next_tournament_teams || props.tournament_teams?.length > 5) &&
                <div className='tournament-statistics-teams__next-button baloa-names' onClick={()=>{ props.handleOnViewMoreStatistics( props.stat_type ) } }>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.viewMore')}</div>
            }
        </div>
    )
}
    
export default withTranslation('league')(TournamentStatisticsTeamsComponent);