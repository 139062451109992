// React
import React from 'react'

//Components
import ExternalStatComponent from '../external-stat/ExternalStatComponent';

// Styles
import './ProfileExternalStatsComponent.style.css'

// i18n
import { withTranslation } from 'react-i18next';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

function ProfileExternalStatsComponent( props ) {
   

    return (

        <div className={ "profile-external-stats__container " + ( props.className ? props.className : '' ) }>

                <div className='profile-external-stats__title'>
                    <div className='baloa-subtitle-2'>
                        {props.t('externalStats.externalStatsTitle')}&nbsp;
                        {props.is_owner && !props.is_baloa_pro &&
                            <span className='hubicon-award_star'/>
                        }
                    </div>
                    {props.is_owner &&
                        <div className='baloa-card-1__button'>
                            <span className='hubicon-add_option' onClick={props.is_baloa_pro? () => {props.handleOnAddExternalStats()} : () => {props.handleOnNoProAddExternalStats()}} />
                        </div>
                    }
                </div>
                {props.externalStats?.length > 0?
                    <div className='profile-external-stats__gallery'>
                        {props.externalStats?.slice(0,3).map(stat =>(                            
                            <ExternalStatComponent
                                stat={stat}
                                is_owner={props.is_owner}
                                handleOnEditExternalStats = { props.handleOnEditExternalStats }
                                is_baloa_pro={ props.is_baloa_pro }
                                handleOnVerifiedExternalStat={ props.handleOnVerifiedExternalStat }
                            />
                        ))

                        }
                        {!props.is_baloa_pro && props.is_owner &&
                            <div className='profile-external-stats__info'>
                                <div>
                                    <div className='profile-external-stats__info-title'>
                                        <span className='hubicon-award_star' />
                                        <div className='baloa-normal-medium'>{props.t('baloapro:baloaProHeader.title')}</div>
                                    </div>
                                    <div className='baloa-names'>{props.t('externalStats.notBaloaProOwnerMsg')}</div>
                                </div>
                                <PrimaryButtonComponent
                                    input_type={'button'}
                                    onClick={ ()=>{ props.handleOnBaloaProSubscription()} }
                                >
                                    <span>{props.t('baloapro:baloaProSubscriptionBtnLbl')}</span>
                                </PrimaryButtonComponent>
                            </div>
                        }
                        {!props.is_baloa_pro && !props.is_owner &&
                            <div className='profile-external-stats__info'>
                                <span className='hubicon-info'/>
                                <div className='baloa-normal-medium'>{props.t('externalStats.notBaloaProVisitorMsg',{_username: props.username})}</div>
                            </div>
                        }
                    </div>
                    :
                    <div className='profile-external-stats__empty-message baloa-normal-text'>{props.is_owner? props.t('externalStats.emptyText') : props.t('externalStats.visitorEmptyText')}</div>
                }
                {props.externalStats?.length > 3 &&
                    <div className='profile-external-stats__view-more baloa-normal-medium' onClick={()=>{ props.handleOnViewMore() }}>{props.t(`tournamentSummary.labelViewAll`)}</div>
                }
        </div>
        
    )

}



export default (withTranslation(["profile", "league", "baloapro"]))(ProfileExternalStatsComponent);