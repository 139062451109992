
export const changeProfileLoadingAction = ( data ) => {
    return { type: "PROFILE:CHANGE_LOADING_PROFILE", data }
}

export const loadProfileAction = ( data ) => {
    return { type: "PROFILE:LOAD_PROFILE", data }
}

export const loadLogguedinProfileAction = ( data ) => {
    return { type: "PROFILE:LOAD_LOGGUEDIN_PROFILE", data }
}

export const followProfileAction = ( data ) => {
    return { type: "PROFILE:FOLLOW_PROFILE", data }
}

export const changeProfilePostsLoadingAction = ( data ) => {
    return { type: "PROFILE:CHANGE_LOADING_PROFILE_POSTS", data }
}

export const loadProfilePostsAction = ( data ) => {
    return { type: "PROFILE:LOAD_PROFILE_POSTS", data }
}

export const changeProfileUploadPhotoModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_UPLOAD_PHOTO_MODAL_STATE", new_state }
}

export const changeProfileBasicInfoModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_BASIC_INFO_MODAL_STATE", new_state }
}

export const changeProfileNationalityModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_NATIONALITY_MODAL_STATE", new_state }
}

export const changeProfileDescriptionModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_DESCRIPTION_MODAL_STATE", new_state }
}

export const changeProfileDataSheetModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_DATA_SHEET_MODAL_STATE", new_state }
}

export const changeProfileTechnicsPrimaryPositionModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_TECHNICS_PRIMARY_POSITION_MODAL_STATE", new_state }
}

export const changeProfileTechnicsSecondaryPositionModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_TECHNICS_SECONDARY_POSITION_MODAL_STATE", new_state }
}

export const changeProfileTechnicsMeassuresModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_TECHNICS_MEASSURES_MODAL_STATE", new_state }
}

export const changeProfileTechnicsLateralityModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_TECHNICS_LATERALITY_MODAL_STATE", new_state }
}

export const changeProfileExperiencesModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_EXPERIENCES_MODAL_STATE", new_state }
}

export const changeProfileSpecialtiesModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_SPECIALTIES_MODAL_STATE", new_state }
}

export const changeProfileAptitudesModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_APTITUDES_MODAL_STATE", new_state }
}

export const changeProfileAptitudesAdminModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_APTITUDES_ADMIN_MODAL_STATE", new_state }
}

export const loadProfileErrorsAction = ( errors, section ) => {
    return { type: "PROFILE:LOAD_PROFILE_ERRORS", errors, section }
}

export const loadProfileFollowersAction = ( data ) => {
    return { type: "PROFILE:LOAD_PROFILE_FOLLOWERS", data }
}

export const changeProfileFollowersModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_FOLLOWERS_MODAL_STATE", new_state }
}

export const loadProfileFollowingAction = ( data ) => {
    return { type: "PROFILE:LOAD_PROFILE_FOLLOWING", data }
}

export const editProfileExperiencesAction = ( experience ) => {
    let new_state = true;    
    return { type: "PROFILE:EDIT_EXPERIENCES_MODAL_STATE", new_state, experience }
}

export const deleteProfileExperiencesAction = ( attribute_id ) => {         
    let new_state = true;    
    return { type: "PROFILE:DELETE_EXPERIENCES_MODAL_STATE", new_state, attribute_id }
}

export const changeInviteToValidateAptitudeModalStateAction = ( new_state, aptitude ) => {
    return { type: "PROFILE:CHANGE_INVITE_TO_VALIDATE_APTITUDE_MODAL_STATE", new_state, aptitude }
}

export const searchProfileNationalitiesAction = ( data ) => {         
    return { type: "PROFILE:SEARCH_PROFILE_NATIONALITIES", data }
}

export const searchProfileLocationsAction = ( data ) => {         
    return { type: "PROFILE:SEARCH_PROFILE_LOCATIONS", data }
}

export const addProfileNationalityAction = ( data ) => {         
    return { type: "PROFILE:ADD_PROFILE_NATIONALITY", data }
}

export const switchProfileToPageAction = ( page ) => {
    return { type: "PROFILE:SWITCH_PROFILE_TO_PAGE", page }
}

export const updateProfilePostAction= ( data ) => {
    return { type: "PROFILE:UPDATE_PROFILE_POST", data }
}

export const updateProfilePostsFeedAction= ( data ) => {
    return { type: "PROFILE:UPDATE_PROFILE_POSTS_FEED", data }
}

export const overwriteInterceptorAction= ( new_state ) => {
    return { type: "PROFILE:OVERWRITE_INTERCEPTOR", new_state }
}

export const updateProfilePostsAction = ( data ) => {
    return { type: "PROFILE:UPDATE_PROFILE_POSTS", data }
}

export const changeProfileTeamsModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_TEAMS_MODAL_STATE", new_state }
}

export const changeProfileBasicInformationModalStateAction = ( new_state ) => {
    return { type: "PROFILE:BASIC_INFO_MODAL_STATE", new_state }
}

export const changeProfileRoleModalStateAction = ( new_state ) => {
    return { type: "PROFILE:EDIT_PROFILE_ROLE_MODAL_STATE", new_state }
}

export const changeProfileTournamentsTeamsSummaryModalStateAction = ( new_state ) => {
    return { type: "PROFILE:TOURNAMENTS_TEAMS_SUMMARY_MODAL_STATE", new_state }
}

export const loadProfileStatsAction = ( data ) => {
    return { type: "PROFILE:LOAD_STATS", data }
}

export const clearProfileStatsAction = ( ) => {
    return { type: "PROFILE:CLEAR_STATS" }
}

export const changeProfileStatisticsPaymentModalStateAction = ( new_state ) => {
    return { type: "PROFILE:STATISTICS_PAYMENT_MODAL_STATE", new_state }
}

export const changeProfileStatisticsPaymentResumeModalStateAction = ( new_state ) => {
    return { type: "PROFILE:STATISTICS_RESUME_PAYMENT_MODAL_STATE", new_state }
}

export const loadProfileTournamentsAction = ( data ) => {
    return { type: "PROFILE:LOAD_TOURNAMENTS", data }
}

export const loadProfilePaymentCheckoutAction = ( data ) => {
    return { type: "PROFILE:LOAD_PAYMENT_CHECKOUT", data }
}

export const clearProfileTournamentsAction = () => {
    return { type: "PROFILE:CLEAR_TOURNAMENTS" }
}

export const resetProfilePostAction = ( data ) => {
    return { type: "PROFILE:RESET_PROFILE_POSTS", data }
}

export const setProfileSelectedPostAction = ( data ) => {
    return { type: "PROFILE:SET_PROFILE_SELECTED_POST", data }
}

export const changeProfilePostFormationModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_PROFILE_POST_FORMATION_MODAL_STATE", new_state }
}

export const changeProfilePollQuizPostModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_PROFILE_POST_QUIZ_POLL_MODAL_STATE", new_state }
}

export const changeProfilePostCommentModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_PROFILE_POST_COMMENT_MODAL_STATE", new_state }
}

export const changeProfilePostMediaModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_PROFILE_POST_MEDIA_MODAL_STATE", new_state }
}

export const changeProfileTournamentsMatchesModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_PROFILE_TOURNAMENTS_MATCHES_MODAL_STATE", new_state }
}

export const addProfileVideoClipAction =(new_state)=>{
    return {type:  "PROFILE:ADD_VIDEO_CLIP", new_state}
}

export const addProfilePresentationVideo= ( data ) => {
    return { type: "PROFILE:PRESENTATION_VIDEO", data }
}

export const addProfileFeaturedVideo= ( data ) => {
    return { type: "PROFILE:FEATURED_VIDEO", data }
}

export const changeProfilePostVideoClipModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_PROFILE_POST_VIDEO_CLIP_MODAL_STATE", new_state }
}

export const updateProfileOneVideo= ( data ) => {
    return { type: "PROFILE:UPDATE_PROFILE_VIDEO", data }
}

export const changeProfileChooseMatchClipModalStateAction= ( new_state ) => {
    return { type: "PROFILE:CHANGE_PROFILE_CHOOSE_MATCH_CLIP_MODAL_STATE", new_state }
}

export const loadProfileMatchesAction = ( data ) => {
    return { type: "PROFILE:LOAD_PROFILE_MATCHES", data }
}

export const loadProfileTournamentMatchesAction = ( data ) => {
    return { type: "PROFILE:LOAD_PROFILE_TOURNAMENT_MATCHES", data }
}

export const loadProfileAchievementsAction = ( data ) => {
    return { type: "PROFILE:LOAD_PROFILE_ACHIEVEMENTS", data }
}

export const addProfileAchievementAction = ( data ) => {
    return { type: "PROFILE:ADD_PROFILE_ACHIEVEMENT", data }
}

export const updateProfileAchievementAction = ( data ) => {
    return { type: "PROFILE:UPDATE_PROFILE_ACHIEVEMENT", data }
}

export const loadProfileTrajectoriesAction = ( data ) => {
    return { type: "PROFILE:LOAD_PROFILE_TRAJECTORIES", data }
}

export const addProfileTrajectoryAction = ( data ) => {
    return { type: "PROFILE:ADD_PROFILE_TRAJECTORY", data }
}

export const updateProfileTrajectoryAction = ( data ) => {
    return { type: "PROFILE:UPDATE_PROFILE_TRAJECTORY", data }
}

export const changeShareProfileLinkModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_SHARE_PROFILE_LINK_MODAL_STATE", new_state }
}

export const searchProfileWorldCitiesAction = ( data ) => {
    return { type: "PROFILE:SEARCH_PROFILE_WORLD_CITIES", data }
}

export const changeProfileExternalStatsModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_PROFILE_EXTERNAL_STATS_MODAL_STATE", new_state }
}

export const loadProfileExternalStatsAction = ( data ) => {
    return { type: "PROFILE:LOAD_PROFILE_EXTERNAL_STATS", data }
}

export const changeProfileMoreExternalStatsModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_PROFILE_MORE_EXTERNAL_STATS_MODAL_STATE", new_state }
}

export const changeEditExternalStatsInfoModalAction =( new_state ) => {
    return { type: "PROFILE:CHANGE_EDIT_EXTERNAL_STATS_INFO_MODAL_STATE", new_state }
}

export const changeDeleteExternalStatsInfoModalAction =( new_state ) => {
    return { type: "PROFILE:CHANGE_DELETE_EXTERNAL_STATS_INFO_MODAL_STATE", new_state }
}

export const loadingProfilesFeaturedVideosAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_LOADING_PROFILE_FEATURED_VIDEOS_STATE", new_state }
}

export const loadingProfilesFeaturedVideosNextAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_LOADING_PROFILE_FEATURED_VIDEOS_NEXT_STATE", new_state }
}

export const changeProfileFeaturedClipsViewMoreModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_VIEW_MORE_FEATURED_CLIPS_MODAL_STATE", new_state }
}

export const changeNoProUploadMainVideoModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_NO_PRO_UPLOAD_MAIN_VIDEO_MODAL_STATE", new_state }
}

export const changeNoProUploadFeaturedVideosModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_NO_PRO_UPLOAD_FEATURED_VIDEOS_MODAL_STATE", new_state }
}

export const changeNoProAddExternalStatsModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_NO_PRO_ADD_EXTERNAL_STATS_MODAL_STATE", new_state }
}

export const changeVerifiedExternalStatsInsigniaModalStateAction = ( new_state ) => {
    return { type: "PROFILE:CHANGE_VERIFIED_EXTERNAL_STATS_INSIGNIA_MODAL_STATE", new_state }
}