// React
import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import './TournamentStatisticsCardsComponent.styles.css';

//i18
import { withTranslation } from 'react-i18next';

//Components
import TournamentPlayerInfoComponent from 'app/leagues/components/tournament-player-info/TournamentPlayerInfoComponent';
import TournamentStatisticsSkeletonComponent from 'app/leagues/components/tournament-statistics-skeleton/TournamentStatisticsSkeletonComponent';

//isMobile
import { isMobile } from 'react-device-detect';


function TournamentStatisticsCardsComponent(props){ 
    return(
        <div className='tournament-statistics-cards__container'>
            <div className='baloa-subtitle-2'>{props.title}</div>
            {props.loading_cards && 
                [...Array(Number(5))].map((e, i) => 
                    <TournamentStatisticsSkeletonComponent/>
                )
            }
            {(!props.loading_cards && props.tournament_cards?.length > 0)
                ?
                    props.tournament_cards?.slice(0, 5).map( (card, cardIndex) => 
                        <div className='tournament-statistics-cards__row' key={cardIndex}>
                            <div className='tournament-statistics-cards__row__counter baloa-normal-medium'>{cardIndex + 1}</div>
                            <div><Link to={`/profile/${card.name}/`}><TournamentPlayerInfoComponent full_name={`${card.first_name} ${card.last_name}`} photo={card.photo} team_name={card.team} /></Link></div>
                            <div className='tournament-statistics-cards__row__cards baloa-normal-medium'>{card.cards}</div>
                            <div className={`${props.card_color}-card`}/>
                        </div>
                    )                                                                  
                :
                    <div className='tournament-statistics-cards__no-data'>
                        <div className='baloa-normal'>{ props.empty_cards_lbl }</div>
                    </div>
            }
            {(props.next_tournament_cards || props.tournament_cards?.length > 5) &&
                <div className='tournament-statistics-cards__next-button baloa-names' onClick={()=>{ props.handleOnViewMoreStatistics(`${props.card_color}-card`) } }>{props.t('options.tournaments.pages.tournamentInfo.tabs.tabTables.tables.viewMore')}</div>
            }
        </div>
    )
}
    
export default withTranslation('league')(TournamentStatisticsCardsComponent);
