// React
import React, { useMemo, useState, useEffect, useRef } from "react";

// Material UI
import Skeleton from '@mui/material/Skeleton';

// Styles
import './ProfileFeaturedClips.styles.css'
// i18n
import { withTranslation } from 'react-i18next';

// React device detect
import { isMobile } from 'react-device-detect';

function ProfileFeaturedClips(props) {

    const [fileInputRef, setFileInputRef] = useState(null);
    const [fileInputClipsRef, setFileInputClipsRef] = useState(null);
    const [listVideoClips, setListVideoClipsRef] = useState(null);
    const [videoPresentation, setVideoPresentation] = useState(null);

    /**
     * Usage Memo used to check if the presentation or highlight video exists
     */
    useMemo(() => {
        if (props.profile_presentation_video) {
            setVideoPresentation(props.profile_presentation_video);
        }else{
            setVideoPresentation(null);
        }
    }, [props.profile_presentation_video]);

    /**
     * Usage Memo used to check if the presentation or highlight video exists
     */
    useMemo(() => {
        if (props.profile_featured_clip?.length > 0) {
            setListVideoClipsRef(props.profile_featured_clip);
        }else setListVideoClipsRef([]);
    }, [props.profile_featured_clip]);

    const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
    const scrl = useRef();
    //This will check scroll event and checks for scroll end
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    useEffect(() => {
        //Check width of the scollings
        if (
            scrl.current &&
            scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
        return () => {};
    }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift); // Updates the latest scrolled postion
    
        //For checking if the scroll has ended
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    /**
     * Takes the file type input file for the video presentation and sends it to the view so 
     * that the other components know what type of video it is.
     * @param {*} event 
     */
    const onVideoChange = event => {
        try {
            if (event) {
                props.handleOnVideoChange({
                    videoData: event.target.files[0],
                    videoURL: URL.createObjectURL(event.target.files[0])
                }, 'presentationVideoClip');
            }
        } catch (error) {
            console.error(error)//cambiar por mensaje
        }
    };

    /**
     * Takes the file type input file for the video featured and sends it to the view so 
     * that the other components know what type of video it is.
     * @param {*} event 
     */
    const onVideoClipChange = event => {
        try {
            if (event) {
                props.handleOnVideoChange({
                    videoData: event.target.files[0],
                    videoURL: URL.createObjectURL(event.target.files[0])
                }, 'featuredVideoClip');
            }
        } catch (error) {
            console.error(error)//cambiar por mensaje
        }
    };

    return (
        <div className="profile-featured-clips__container">
            <div className="profile-featured-clips__title baloa-normal-medium">
                <div className="baloa-normal-medium">{props.t('addVideoClip.titlefeaturedClips')}&nbsp;{props.show_baloa_pro_insignia && <span className="hubicon-award_star"/>}</div>
                {listVideoClips && listVideoClips?.length > 0 &&
                    <div className="baloa-names green-link" onClick={ ()=>{ props.view_more_featured_clips() }}>{props.t('common:viewAll')}</div>
                }
            </div>
            <div className="profile-featured-clips__clips-container">
                {props.loading_profile_featured_videos &&
                    isMobile &&
                        <React.Fragment>
                            <Skeleton animation="wave" variant="rounded" component="div" width={111} height={191} /* style={{ marginLeft: 14 }} *//>
                            <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} style={{ marginLeft: 14 }}/>
                        </React.Fragment>
                    ||
                    props.loading_profile_featured_videos && !isMobile &&
                        <React.Fragment>
                            <Skeleton animation="wave" variant="rounded" component="div" width={176} height={288} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={176}height={288} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={176}height={288} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={176}height={288} style={{ marginLeft: 14 }}/>
                            <Skeleton animation="wave" variant="rounded" component="div" width={176}height={288} style={{ marginLeft: 14 }}/>
                        </React.Fragment>
                }
                {(videoPresentation && !props.loading_profile_featured_videos && videoPresentation.post_stream?.thumbnail)
                    ? <div className={`profile-featured-clips__reel presentation ${!props.is_baloa_pro? 'lock' : ''}`}
                        onClick={() => {
                            props.handleProfilePostModal(videoPresentation, 'presentationVideoClip');
                        }}>
                        {/* videoPresentation.post_stream?.thumbnail
                            ? <img className="profile-featured-clip_reel_media_video"
                                src={videoPresentation.post_stream?.thumbnail}
                                alt={'videoPresentation'}
                            />
                            : <div className="profile-featured-clip_reel_media_video_container">
                                <video className="profile-featured-clip_reel_media_video_fit"
                                    src={videoPresentation?.post_video}
                                    preload="auto"
                                    muted
                                    autoPlay={false}
                                    controls={false}
                                />
                            </div> */
                        }
                        <img className="profile-featured-clip_reel_media_video"
                            src={videoPresentation.post_stream?.thumbnail}
                            alt={'videoPresentation'}
                        />

                        {/* <span className={"profile_featured_post_video-control__icon"} /> */}
                        <div className="profile-featured-clips__reel-video__title baloa-table-column">
                            {videoPresentation.caption?.length > 0 ? videoPresentation.caption : ''}
                        </div>
                        {!props.is_baloa_pro &&
                            <span className='hubicon-lock_fill'/>
                        }
                    </div>
                    :(videoPresentation && !props.loading_profile_featured_videos && !videoPresentation.post_stream?.thumbnail)
                    ?
                    <div>
                        {isMobile &&
                            <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} style={{ marginRight: 14 }}/>
                        }
                        {!isMobile &&
                            <Skeleton animation="wave" variant="rounded" component="div" width={176}height={288} style={{ marginRight: 14 }}/>
                        }
                    </div>
                    : props.isProfileOwner && !props.loading_profile_featured_videos &&
                        <div className="profile-featured-clips__reel presentation">
                            <div className="profile-featured-clips__reel-video"
                                onClick={props.is_baloa_pro? () => { fileInputRef.click() } : () => { props.handleOnUploadMainVideo() }}>
                                <span className="hubicon-video_camera_front" />
                            </div>
                            <input
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(event) => onVideoChange(event)}
                                ref={video_input => setFileInputRef(video_input)}
                                accept="video/*"
                                value=""
                            />
                            <div className="profile-featured-clips__reel-video__empty-title baloa-table-column">
                                {props.t('addVideoClip.labelVideoPresentation')}
                            </div>
                        </div>
                    }
                {/* <div className="profile-featured-clips__vertical-line" /> */}
                {!props.loading_profile_featured_videos &&
                    <div className='profile-featured-horizontal-clips_scroll_arrows' ref={scrl} onScroll={scrollCheck}>
                        {!isMobile && scrollX !== 0 &&
                            <div class="profile-featured-horizontal-clips_arrow prev" onClick={() => slide(-150)}>
                                <span class="hubicon-arrow_circle_left">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </span>
                            </div>
                        }
                        {props.isProfileOwner &&
                            <div className="profile-featured-clips__reel">
                                <div className="profile-featured-clips__reel-video"
                                    onClick={props.is_baloa_pro? () => { fileInputClipsRef.click() } : () => { props.handleOnUploadFeaturedVideos() } }>
                                    <span className="hubicon-video_library" />
                                </div>
                                <input
                                    style={{ display: 'none' }}
                                    type="file"
                                    onChange={(event) => onVideoClipChange(event)}
                                    ref={video_input => setFileInputClipsRef(video_input)}
                                    accept="video/*"
                                    value=""
                                />
                                <div className="profile-featured-clips__reel-video__empty-title baloa-table-column">
                                    {props.t('addVideoClip.labelNewFeatureClip')}
                                </div>
                            </div>
                        }
                        <div className="profile-featured-horizontal-clips-container">
                            {listVideoClips && listVideoClips?.length > 0 &&
                                listVideoClips?.slice(0,5).map((clip, index) => (
                                    clip.post_stream?.thumbnail &&
                                        <div key={index} className={`profile-featured-clips__reel ${!props.is_baloa_pro? 'lock' : ''}`}
                                            onClick={() => {
                                                props.handleProfilePostModal(clip, 'featuredVideoClip');
                                            }}>
                                            {/* clip.post_stream?.thumbnail
                                                ? <img className="profile-featured-clip_reel_media_video"
                                                    src={clip.post_stream?.thumbnail}
                                                    alt={'FeaturedClip'}
                                                />
                                                : <div className="profile-featured-clip_reel_media_video_container">
                                                    <video className="profile-featured-clip_reel_media_video_fit"
                                                        src={clip?.post_video}
                                                        Spreload="auto"
                                                        muted
                                                        autoPlay={false}
                                                        controls={false}
                                                    />
                                                </div> */
                                            }
                                            <img className="profile-featured-clip_reel_media_video"
                                                src={clip.post_stream?.thumbnail}
                                                alt={'FeaturedClip'}
                                            />
                                            {/* <span className={"profile_featured_post_video-control__icon"} /> */}
                                            <div className="profile-featured-clips__reel-video__title baloa-table-column">{clip.caption? clip.caption : props.t('addVideoClip.labelfeaturedClip')}</div>
                                            {!props.is_baloa_pro &&
                                                <span className='hubicon-lock_fill'/>
                                            }
                                        </div>
                                    ||
                                    !clip.post_stream?.thumbnail && 
                                        <div>
                                            {isMobile &&
                                                <Skeleton animation="wave" variant="rounded" component="div" width={111}height={191} style={{ marginLeft: 14 }}/>
                                            }
                                            {!isMobile &&
                                                <Skeleton animation="wave" variant="rounded" component="div" width={176}height={288} style={{ marginLeft: 14 }}/>
                                            }
                                        </div>
                                ))
                            }
                        </div>
                        {!isMobile && !scrolEnd &&
                            <div class="profile-featured-horizontal-clips_arrow next" onClick={() => slide(+150)}>
                                <span class="hubicon-arrow_circle_right">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </span>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default withTranslation('profile')(ProfileFeaturedClips);