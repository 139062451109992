/*eslint eqeqeq: "off"*/
// Profile state
const profile_state = {
    profile: {
        following_count: undefined,
        followers_count: undefined,
        attributes: {
            description: { atype: 'description', value: "" },
            primary_position: { atype: 'primary-position', value: "" },
            secondary_position: { atype: 'secondary-position', value: [] },
            primary_team: { atype: 'primary-team', value: {
                name: ""
            } },
            height: { atype: 'height', value: "", units: "cm" },
            weight: { atype: 'weight', value: "", units: "kg" },
            laterality: { atype: 'laterality', value: "" },
            experiences: [],
            specialty: "",
            aptitudes: [],
        },
        loading: false,
        nationality: {id: undefined, name: undefined, indicative: undefined, flag_icon: undefined },
        location: {id: undefined, name: undefined},
        achievements: [],
        trajectories: [],
        external_stats: [],
    },
    posts: [],
    modals: {
        description_is_open: false,
        basic_info_is_open: false,
        nationality_is_open: false,
        upload_photo_is_open: false,
        technics: {
            primary_team_is_open: false,
            primary_position_is_open: false,
            secondary_position_is_open: false,
            meassures_is_open: false,
            laterality_is_open: false,
        },
        experiences_is_open: false,
        specialties_is_open: false,
        aptitudes_is_open: false,
        aptitudes_admin_is_open: false,
        followers_is_open: false,
        invite_to_validate_aptitude: {
            is_open: false,
            aptitude: undefined,
        },
        profile_role_is_open: false,
        profile_basic_info: false,
        profile_tournaments_teams_summary: false,
        profile_statistics_payment: false,
        profile_statistics_resume_payment: false,
        profile_add_video_profile: false,
        profile_post_formation: false,
        profile_post_quiz_poll: false,
        profile_post_comment: false,
        profile_post_media: false,
        profile_tournaments_matches: false,
        profile_post_video_clips: false,
        profile_choose_match_clip: false,
        share_profile_link: false,
        profile_external_stats: false,
        profile_more_external_stats: false,
        edit_external_stats_info: false,
        delete_external_stats_info: false,
        view_more_featured_clips: false,
        no_pro_upload_main_video: false,
        no_pro_upload_featured_videos: false,
        no_pro_add_external_stats: false,
        verified_external_stats_insignia: false,
    },
    errors: {
        general:{
            description: "",
        },
        technics: {
            primary_team: "",
            primary_position: "",
            secondary_position: "",
            height_value: "",
            height_units: "",
            weight_value: "",
            weight_units: "",
            laterality: "",
        },
        experiences: {
            achievement_name: "",
            institution: "",
            year: "",
            description: "",
            date: "",
            team_name: "",
            currently_playing: "",
            start_year: "",
            end_year: "",
        },
        external_stats: {
            tournament_name: "",
            tournament_logo: "",
            tournament_gender: "",
            tournament_category: "",
            football_type: "",
            start_date: "",
            end_date: "",
        }
    },
    followers: [],
    following: [],
    experience: null,    
    loggedin: {},
    nationalities_search_results: [],
    locations_search_results: [],
    world_cities_search_result: [],
    page_active: undefined,
    ovewrite_interceptor: false,
    profile_stats: [],
    profile_stats_next_page: null,
    profile_tournaments: [],
    profile_tournaments_next_page: null,
    profile_payment_checkout: undefined,
    profile_selected_post: null,
    profile_presentation_video: undefined,
    profile_featured_clip:[],
    profile_featured_clip_next_page: null,
    profile_matches: [],
    profile_matches_next_page: null,
    profile_tournaments_matches_data: [],
    external_stats_next_page: null,
    loading_profile_featured_videos: false,
    loading_profile_featured_videos_next: false,
}

export default function ProfileReducer( state = profile_state, action ) {
    
    switch ( action.type ) {

        case "PROFILE:LOAD_PROFILE": {

            let description = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'description' 
            )
            let primary_position = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'primary-position' 
            )
            let secondary_position = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'secondary-position' 
            )
            let primary_team = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'primary-team' 
            )
            let height = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'height' 
            )
            let weight = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'weight' 
            )
            let laterality = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'laterality' 
            )
            let experiences = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'experience' 
            )
            let specialty = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'specialty' 
            )
            let aptitudes = action.data.data.attributes.filter( attribute =>
                attribute['extra']['atype'] == 'aptitude' 
            )
                 
            let new_profile_attributes = {
                description: description.length > 0 ? description[0]['extra'] : profile_state['profile']['attributes']['description'],
                primary_position: primary_position.length > 0 ? primary_position[0]['extra'] : profile_state['profile']['attributes']['primary_position'],
                secondary_position: secondary_position.length > 0 ? secondary_position[0]['extra'] : profile_state['profile']['attributes']['secondary_position'],
                primary_team: primary_team.length > 0 ? primary_team[0]['extra'] : profile_state['profile']['attributes']['primary_team'],
                height: height.length > 0 ? height[0]['extra'] : profile_state['profile']['attributes']['height'],
                weight: weight.length > 0 ? weight[0]['extra'] : profile_state['profile']['attributes']['weight'],
                laterality: laterality.length > 0 ? laterality[0]['extra'] : profile_state['profile']['attributes']['laterality'],
                experiences: experiences.map( experience => experience['extra'] ),
                specialty:  specialty.length > 0 ? specialty[0]['extra'] : profile_state['profile']['attributes']['specialty'],
                aptitudes: aptitudes.map( aptitude => aptitude['extra'] ),
            }
            let new_profile = { ...action.data.data, attributes: new_profile_attributes, loading: false  };
      
            return { ...state, profile: new_profile }

        }
        
        case "PROFILE:CHANGE_LOADING_PROFILE": {
            let new_loading = {...state['profile'], loading: action.data }
            return { ...state, profile: new_loading }
        }

        case "PROFILE:LOAD_LOGGUEDIN_PROFILE": {

            return { ...state, loggedin: action.data.data }

        }

        case "PROFILE:LOAD_PROFILE_POSTS": {
            
            let new_posts = null;
            if(state.posts_next_page !== action.data.next || state.posts.length === 0){
                new_posts = state.posts.concat( action.data.results );
            }else{
                new_posts = state.posts;
            }
            return { ...state, posts: new_posts, posts_next_page: action.data.next, posts_previuos_page: action.data.previous }

        }

        case "PROFILE:CHANGE_LOADING_PROFILE_POSTS": {

            return { ...state, loading_posts: action.data }

        }

        case "PROFILE:RESET_PROFILE_POSTS": {

            return { ...state, posts: [], posts_next_page: null, posts_previuos_page: null }

        }

        case "PROFILE:CHANGE_UPLOAD_PHOTO_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], upload_photo_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PROFILE:CHANGE_BASIC_INFO_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], basic_info_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }
        
        case "PROFILE:CHANGE_NATIONALITY_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], nationality_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PROFILE:CHANGE_DESCRIPTION_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], description_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PROFILE:CHANGE_DATA_SHEET_MODAL_STATE": {
            let new_technics_modal_state = { ...state['modals']['technics'], primary_team_is_open: action.new_state }
            let new_modals_state = { ...state['modals'], technics: new_technics_modal_state }
            return { ...state, modals: new_modals_state }

        }

        case "PROFILE:CHANGE_TECHNICS_PRIMARY_POSITION_MODAL_STATE": {

            let new_technics_modal_state = { ...state['modals']['technics'], primary_position_is_open: action.new_state }
            let new_modals_state = { ...state['modals'], technics: new_technics_modal_state }
            return { ...state, modals: new_modals_state }

        }
        
        case "PROFILE:CHANGE_TECHNICS_SECONDARY_POSITION_MODAL_STATE": {

            let new_technics_modal_state = { ...state['modals']['technics'], secondary_position_is_open: action.new_state }
            let new_modals_state = { ...state['modals'], technics: new_technics_modal_state }
            return { ...state, modals: new_modals_state }

        }
        
        case "PROFILE:CHANGE_TECHNICS_MEASSURES_MODAL_STATE": {

            let new_technics_modal_state = { ...state['modals']['technics'], meassures_is_open: action.new_state }
            let new_modals_state = { ...state['modals'], technics: new_technics_modal_state }
            return { ...state, modals: new_modals_state }

        }
        
        case "PROFILE:CHANGE_TECHNICS_LATERALITY_MODAL_STATE": {

            let new_technics_modal_state = { ...state['modals']['technics'], laterality_is_open: action.new_state }
            let new_modals_state = { ...state['modals'], technics: new_technics_modal_state }
            return { ...state, modals: new_modals_state }

        }

        case "PROFILE:CHANGE_EXPERIENCES_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], experiences_is_open: action.new_state }
            let editing_experience = false;
            return { ...state, modals: new_modals_state, experience: null, edit_experience: editing_experience }            

        }

        case "PROFILE:CHANGE_SPECIALTIES_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], specialties_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }        

        case "PROFILE:CHANGE_APTITUDES_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], aptitudes_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PROFILE:CHANGE_APTITUDES_ADMIN_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], aptitudes_admin_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PROFILE:LOAD_PROFILE_ERRORS": {
            let new_errors_state = { ...state['errors'], [action.section]: action.errors }
            return { ...state, errors: new_errors_state }

        }

        case "PROFILE:LOAD_PROFILE_FOLLOWERS": {
            return {...state, followers: action.data.results}
        }

        case "PROFILE:CHANGE_FOLLOWERS_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], followers_is_open: action.new_state }
            return { ...state, modals: new_modals_state }

        }

        case "PROFILE:LOAD_PROFILE_FOLLOWING": {
            return {...state, following: action.data.results}
        }
        
        case "PROFILE:EDIT_EXPERIENCES_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], experiences_is_open: action.new_state }
            let editing_experience = true;
            return { ...state, modals: new_modals_state, experience: action.experience, edit_experience: editing_experience }

        }

        case "PROFILE:CHANGE_INVITE_TO_VALIDATE_APTITUDE_MODAL_STATE": {

            let invite_to_validate_aptitude_new_state = { is_open: action.new_state, aptitude: action.aptitude }
            let new_modals_state = { ...state['modals'], invite_to_validate_aptitude: invite_to_validate_aptitude_new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:SEARCH_PROFILE_NATIONALITIES": {
            return {...state, nationalities_search_results: action.data.results}
        }

        case "PROFILE:SEARCH_PROFILE_LOCATIONS": {
            return {...state, locations_search_results: action.data.cities}
        }

        case "PROFILE:ADD_PROFILE_NATIONALITY": {
            let new_nationality = { ...state['profile']['profile'], nationality: action.data.nationality }
            let new_location = { ...state['profile']['profile'], location: action.data.location }
            return {...state, nationality: new_nationality, location: new_location}
        }

        case "PROFILE:SWITCH_PROFILE_TO_PAGE": {
            return { ...state, page_active: action.page }
        }

        case "PROFILE:OVERWRITE_INTERCEPTOR": {
            return { ...state, ovewrite_interceptor: action.new_state }
        }

        case "PROFILE:UPDATE_PROFILE_POST": {
            let updated_posts = state.posts.map(postss =>
                postss.id == action.data.data.id ? action.data.data : postss
            )
            return { ...state, posts: updated_posts, profile_selected_post: action.data.data }
        }

        case "PROFILE:UPDATE_PROFILE_POSTS_FEED": {
            let new_feed_posts = { ...state.posts, posts: [action.data, ...state.posts ]}
            return { ...state, posts: new_feed_posts.posts }
        }

        case "PROFILE:UPDATE_PROFILE_POSTS": {
            return { ...state, posts: action.data.results }
        }

        case "PROFILE:CHANGE_TEAMS_MODAL_STATE": {
            
            const prevState = state["modals"].teams_is_open

            let new_modals_state = { ...state['modals'], teams_is_open: !prevState }
            return { ...state, modals: new_modals_state }
            
        }
        case "PROFILE:EDIT_TEAMS_MODAL_STATE": {

            let new_modals_state = { ...state['modals'], teams_is_open: action.new_state }
            let editing_team = true;
            return { ...state, modals: new_modals_state, team: action.team, edit_team: editing_team }

        }

        case "PROFILE:PROFILE:ADD_TEAM": {

            let new_modals_state = { ...state['modals'], teams_is_open: action.new_state }
            let editing_team = true;
            return { ...state, modals: new_modals_state, team: action.team, edit_team: editing_team }

        }

        case "PROFILE:EDIT_PROFILE_ROLE_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_role_is_open: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:BASIC_INFO_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_basic_info: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:TOURNAMENTS_TEAMS_SUMMARY_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_tournaments_teams_summary: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:LOAD_STATS": {
            let new_profile_stats = null;
            if(state.profile_stats_next_page !== action.data.data.next || state.profile_stats.length === 0){
                new_profile_stats = state.profile_stats.concat( action.data.data.results );
            }else{
                new_profile_stats = state.profile_stats;
            }           
            return { ...state, profile_stats: new_profile_stats, profile_stats_next_page: action.data.data.next }
        }

        case "PROFILE:CLEAR_STATS": {
            return { ...state, profile_stats: [], profile_stats_next_page: null }
        }

        case "PROFILE:STATISTICS_PAYMENT_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_statistics_payment: action.new_state }
            return { ...state, modals: new_modals_state }
        }  
        
        case "PROFILE:STATISTICS_RESUME_PAYMENT_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_statistics_resume_payment: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:LOAD_TOURNAMENTS": {
            let new_profile_tournaments = null;
            if(state.profile_tournaments_next_page !== action.data.data.next || state.profile_tournaments.length === 0){
                new_profile_tournaments = state.profile_tournaments.concat( action.data.data.results );
            }else{
                new_profile_tournaments = state.profile_tournaments;
            }           
            return { ...state, profile_tournaments: new_profile_tournaments, profile_tournaments_next_page: action.data.data.next }
        }

        case "PROFILE:LOAD_PAYMENT_CHECKOUT": {
            return { ...state, profile_payment_checkout: action.data }
        }

        case "PROFILE:CLEAR_TOURNAMENTS": {
            return { ...state, profile_tournaments: [], profile_tournaments_next_page: null }
        }

        case "PROFILE:SET_PROFILE_SELECTED_POST": {
            return { ...state, profile_selected_post: action.data }
        }

        case "PROFILE:CHANGE_PROFILE_POST_FORMATION_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_post_formation: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_PROFILE_POST_QUIZ_POLL_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_post_quiz_poll: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_PROFILE_POST_COMMENT_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_post_comment: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_PROFILE_POST_MEDIA_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_post_media: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_PROFILE_TOURNAMENTS_MATCHES_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_tournaments_matches: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:ADD_VIDEO_CLIP":{
            let new_modals_state = { ...state['modals'], profile_add_video_profile: action.new_state}
            return {...state, modals: new_modals_state}
        }

        case "PROFILE:PRESENTATION_VIDEO": {
            const presentationVideoClip = action.data.data.results.find(video => video.is_profile_presentation_clip === true);
            return {...state, profile_presentation_video: presentationVideoClip}
        }

        case "PROFILE:FEATURED_VIDEO":{
            const featuredVideoClips = action.data.data.results.filter(video => video.is_profile_clip === true);

            let new_profile_featured_clip = null;
            if(state.profile_featured_clip_next_page !== action.data.data.next && action.data.data.previous){
                new_profile_featured_clip = state.profile_featured_clip.concat( featuredVideoClips );
            }else if(!action.data.data.previous){
                new_profile_featured_clip = featuredVideoClips;
            }else{
                new_profile_featured_clip = state.profile_featured_clip;
            }

            return {...state, profile_featured_clip: new_profile_featured_clip, profile_featured_clip_next_page: action.data.data.next}
        }

        case "PROFILE:CHANGE_PROFILE_POST_VIDEO_CLIP_MODAL_STATE": {
            let new_modals_state = { ...state['modals'], profile_post_video_clips: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:UPDATE_PROFILE_VIDEO":{
            return { ...state, profile_selected_post: action.data.data } 
        }

        case "PROFILE:CHANGE_PROFILE_CHOOSE_MATCH_CLIP_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], profile_choose_match_clip: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        
        case "PROFILE:LOAD_PROFILE_MATCHES":{
            let new_profile_matches = null;
            if(action.data.data.previous && state.profile_matches_next_page !== action.data.data.next ){
                new_profile_matches = state.profile_matches.concat( action.data.data.results );
            }else{
                new_profile_matches = action.data.data.results;
            }           
            return { ...state, profile_matches: new_profile_matches, profile_matches_next_page: action.data.data.next }
        }

        case "PROFILE:LOAD_PROFILE_TOURNAMENT_MATCHES":{
            return { ...state, profile_tournaments_matches_data: action.data.data }
        }

        case "PROFILE:LOAD_PROFILE_ACHIEVEMENTS":{
            let new_achievements_state = { ...state['profile'], achievements: action.data.data }
            return { ...state, profile: new_achievements_state }
        }

        case "PROFILE:ADD_PROFILE_ACHIEVEMENT":{
            let new_achievements_state = { ...state['profile'], achievements: [action.data.data].concat(state.profile.achievements)}
            return { ...state, profile: new_achievements_state }
        }

        case "PROFILE:UPDATE_PROFILE_ACHIEVEMENT":{
            let new_achievements_state = { ...state['profile'], achievements: state.profile.achievements.map(
                achievement => achievement.id === action.data.data.id? action.data.data : achievement) }
            return { ...state, profile: new_achievements_state }
        }

        case "PROFILE:LOAD_PROFILE_TRAJECTORIES":{
            let new_trajectory_state = { ...state['profile'], trajectories: action.data.data }
            return { ...state, profile: new_trajectory_state }
        }

        case "PROFILE:ADD_PROFILE_TRAJECTORY":{
            let new_trajectory_state = { ...state['profile'], trajectories: [action.data.data].concat(state.profile.trajectories)}
            return { ...state, profile: new_trajectory_state }
        }

        case "PROFILE:UPDATE_PROFILE_TRAJECTORY":{
            let new_trajectory_state = { ...state['profile'], trajectories: state.profile.trajectories.map(
                trajectory => trajectory.id === action.data.data.id? action.data.data : trajectory) }
            return { ...state, profile: new_trajectory_state }
        }

        case "PROFILE:CHANGE_SHARE_PROFILE_LINK_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], share_profile_link: action.new_state }
            return { ...state, modals: new_modals_state }
        }
        
        case "PROFILE:SEARCH_PROFILE_WORLD_CITIES": {
            return {...state, world_cities_search_result: action.data.data.results}
        }

        case "PROFILE:CHANGE_PROFILE_EXTERNAL_STATS_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], profile_external_stats: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:LOAD_PROFILE_EXTERNAL_STATS":{
            let new_external_stats = null;
            if(action.data.data.previous && state.external_stats_next_page !== action.data.data.next ){
                new_external_stats = state.profile.external_stats.concat( action.data.data.results );
            }else{
                new_external_stats = action.data.data.results;
            }
            let new_external_stats_state = { ...state['profile'], external_stats: new_external_stats }           
            return { ...state, profile: new_external_stats_state, external_stats_next_page: action.data.data.next }
        }

        case "PROFILE:CHANGE_PROFILE_MORE_EXTERNAL_STATS_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], profile_more_external_stats: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_EDIT_EXTERNAL_STATS_INFO_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], edit_external_stats_info: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_DELETE_EXTERNAL_STATS_INFO_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], delete_external_stats_info: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_LOADING_PROFILE_FEATURED_VIDEOS_STATE":{
            return { ...state, loading_profile_featured_videos: action.new_state }
        }

        case "PROFILE:CHANGE_LOADING_PROFILE_FEATURED_VIDEOS_NEXT_STATE":{
            return { ...state, loading_profile_featured_videos_next: action.new_state }
        }

        case "PROFILE:CHANGE_VIEW_MORE_FEATURED_CLIPS_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], view_more_featured_clips: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_NO_PRO_UPLOAD_MAIN_VIDEO_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], no_pro_upload_main_video: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_NO_PRO_UPLOAD_FEATURED_VIDEOS_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], no_pro_upload_featured_videos: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_NO_PRO_ADD_EXTERNAL_STATS_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], no_pro_add_external_stats: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        case "PROFILE:CHANGE_VERIFIED_EXTERNAL_STATS_INSIGNIA_MODAL_STATE":{
            let new_modals_state = { ...state['modals'], verified_external_stats_insignia: action.new_state }
            return { ...state, modals: new_modals_state }
        }

        // es-lint warning, switch without defalut case. from here, always at the bottom of docuemnt
        // no default
            
    }

    return state;
}