// React
import React from 'react'

// Styles
import './ExternalStatComponent.style.css'

// Moment
import * as moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';


function ExternalStatComponent( props ) {
   
    return (
        <div className='external-stat__stat'>
            <div className='external-stat__stat-header'>
                <div className='external-stat__stat-header__image'>
                    {props.stat.tournament_logo?
                        <img src={props.stat.tournament_logo} alt={props.stat.tournament_name}/>
                        :
                        <span className={"icon-trophy "}></span>
                    }
                </div>
                <div className='external-stat__stat-header__title'>
                    <div className='external-stat__stat-header__title-name baloa-subtitle-2'>{props.stat.tournament_name} {(props.stat.is_validated)? <span className='hubicon-verified' onClick={ ()=>{ props.handleOnVerifiedExternalStat() } } /> : ''}</div>
                    <div className='external-stat__stat-header__tags'>
                        <div className='baloa-table-column'>{props.t(`tournamentSummary.footballType.${props.stat.football_type}`)}</div>
                        <div className='baloa-table-column'>{props.t(`tournamentSummary.gender.${props.stat.tournament_gender}`)}</div>
                        <div className='baloa-table-column'>{props.t(`league:options.tournaments.pages.newTournament.tabs.tabTeams.${props.stat.tournament_category}`)}</div>
                        <div className='baloa-table'> &bull; {moment(props.stat.end_date).format('YYYY')}</div>
                    </div>
                </div>
                {props.is_owner &&
                    <div className='external-stat__stat-header__edit-button'>
                        <span className='hubicon-edit' onClick={() => { props.handleOnEditExternalStats( props.stat )}} />
                    </div>
                }
            </div>
            <div className='external-stat__stat-content'>
                <div className='external-stat__stat-content__icons'>
                    <div className='baloa-normal-medium played_games'>{props.t('tournamentSummary.labelPlayedGames')}<span class="external-stat__tooltip">{props.t('tournamentSummary.playedGamesTooltip')}</span></div>
                    <span class="hubicon-titular"><span class="path1"></span><span class="path2"></span><span class="external-stat__tooltip">{props.t('tournamentSummary.titularTooltip')}</span></span>
                    <span className='icon-ball'><span class="external-stat__tooltip">{props.t('tournamentSummary.goalsTooltip')}</span></span>
                    <span class="hubicon-assists">
                        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>
                        <span class="external-stat__tooltip">{props.t('tournamentSummary.assistancesTooltip')}</span>
                    </span>
                    <span className='hubicon-goalkeeper'><span class="external-stat__tooltip">{props.t('tournamentSummary.undefeatedFencesTooltip')}</span></span>                        
                    <div className='external-stat__stat-content__icons__yellow-card'><span class="external-stat__tooltip">{props.t('tournamentSummary.yellowCardTooltip')}</span></div>
                    <div className='external-stat__stat-content__icons__red-card'><span class="external-stat__tooltip">{props.t('tournamentSummary.redCardTooltip')}</span></div>
                </div>
                <div className='external-stat__stat-content__data'>
                    <div className={`baloa-normal-medium ${!props.is_baloa_pro? 'locked_values' : ''}`}>{props.stat.matches_played}</div>
                    <div className={`baloa-normal-medium ${!props.is_baloa_pro? 'locked_values' : ''}`}>{props.stat.matches_as_starter}</div>
                    <div className={`baloa-normal-medium ${!props.is_baloa_pro? 'locked_values' : ''}`}>{props.stat.goals}</div>
                    <div className={`baloa-normal-medium ${!props.is_baloa_pro? 'locked_values' : ''}`}>{props.stat.goal_assists}</div>
                    <div className={`baloa-normal-medium ${!props.is_baloa_pro? 'locked_values' : ''}`}>{props.stat.go_undefeated}</div>
                    <div className={`baloa-normal-medium ${!props.is_baloa_pro? 'locked_values' : ''}`}>{props.stat.yellow_cards}</div>
                    <div className={`baloa-normal-medium ${!props.is_baloa_pro? 'locked_values' : ''}`}>{props.stat.red_card}</div>
                </div>                
            </div>
        </div>
    )
}

export default (withTranslation(["profile", "league"]))(ExternalStatComponent);